import React, { useState } from 'react'
import { Input, Text } from '../../../atoms'

export const Address = ({ address, modifyAddress }) => {
    const [addressValue, setAddressValue] = useState(address || '')

    return (
        <div className="flex flex-col space-y-4 py-6 px-4">
            <Text.Normal className="font-semibold text-v2_purple">Votre adresse</Text.Normal>
            <Input.ModifyAddress
                value={addressValue}
                onClick={() => modifyAddress()}
                onBlur={() => modifyAddress()}
                onChange={(value) => setAddressValue(value)}
            />
        </div>
    )
}
