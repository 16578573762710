import React, { useEffect, useState, useRef } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import { Loader, Message } from '../atoms'
import { Navbar } from '../molecules'
import { Unlogged } from '../organisms'
import { Profesionnal } from '../../pages'

import { Auth, Address, Request, ProContext, NavbarShow, NotificationContext } from '../../../contexts'
import { socket } from '../../../api/api'

const UnLogged = () => {
    const { message } = Request.useRequest()
    const { isLogged, type, firstVisit } = Auth.useAuth()
    const { step } = Address.useAddress()

    const navigate = useNavigate()
    const location = useLocation()
    const redirectablePath = ['/', '/login', '/signup']

    useEffect(() => {
        if (isLogged() && redirectablePath.indexOf(location.pathname) !== -1) {
            if (type === 'coiffeur') {
                navigate('/pro')
            } else if (type === 'client') {
                navigate('/client')
            }
        }
    }, [isLogged, type])

    return (
        <div>
            {firstVisit ? (
                <Unlogged.OnBoarding />
            ) : (
                <div className="container relative text-black">
                    <Navbar.UnLogged />
                    <div
                        className={`px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto ${
                            location.pathname === '/'
                                ? step === 'home'
                                    ? 'bg-background_light'
                                    : 'bg-white'
                                : 'bg-white'
                        }`}
                        id="scrollable-zone"
                    >
                        {message?.message && (
                            <>
                                {message.type === 'info' && <Message.Info message={message.message} />}
                                {message.type === 'warning' && <Message.Warning message={message.message} />}
                                {message.type === 'error' && <Message.Error message={message.message} />}
                                {message.type === 'success' && <Message.Success message={message.message} />}
                            </>
                        )}
                        <Outlet />
                    </div>
                </div>
            )}
        </div>
    )
}

const UnLoggedBooking = () => {
    const { message } = Request.useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        const location = window.location.pathname
        if (!location.endsWith('details')) {
            navigate(`${location}/details`)
        }
    }, [])

    return (
        <div className="container relative text-black">
            <Navbar.UnLogged />
            <div className="px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto" id="scrollable-zone">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && <Message.Success message={message.message} />}
                    </>
                )}
                <Outlet />
            </div>
        </div>
    )
}

const Client = () => {
    const { message } = Request.useRequest()
    const { restored, type, isLogged } = Auth.useAuth()

    const navigate = useNavigate()

    const [isOverlayVisible, setIsOverlayVisible] = useState(true)

    useEffect(() => {
        if (restored) {
            if (isLogged()) {
                if (type === 'coiffeur') navigate('/pro')
            } else {
                navigate('/')
            }
        }
    }, [restored])

    return (
        <div className="container relative text-black">
            <Navbar.LoggedTop />
            {isOverlayVisible && message.message && (
                <div
                    className="fixed inset-0 z-50 bg-black opacity-25 animate-fadeInNotification"
                    onClick={() => setIsOverlayVisible(false)}
                ></div>
            )}
            <div className="px-2 py-4 overflow-x-hidden overflow-y-auto md:px-6 h-logged" id="scrollable-zone">
                <Outlet />
            </div>
            <div className="fixed h-fit w-fit top-0 left-0 z-[99]">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && (
                            <Message.Success
                                message={message.message}
                                setOverlayVisible={setIsOverlayVisible}
                                isOverlayVisible={isOverlayVisible}
                            />
                        )}
                    </>
                )}
            </div>
            <Navbar.Client />
        </div>
    )
}

const Pro = () => {
    const { id, restored, type, isLogged, redirectClientFromWrongSide } = Auth.useAuth()
    const { message, setMessage } = Request.useRequest()
    const { step, onBoardingLoaded } = ProContext.OnBoarding.useProOnBoarding()
    const { getWaitings } = ProContext.Bookings.useBooking()
    const { refreshWeek } = ProContext.Agenda.useWeek()
    const { getNotifications } = NotificationContext.useNotifications()
    const { navbarLoggedTop, navbarBottom } = NavbarShow.useNavbarShow()

    const navigate = useNavigate()

    const [isOverlayVisible, setIsOverlayVisible] = useState(true)
    const [isExiting, setIsExiting] = useState(false)

    const overlayTimeoutRef = useRef(null)
    const exitTimeoutRef = useRef(null)
    const messageTimeoutRef = useRef(null)

    useEffect(() => {
        if (restored)
            if (isLogged()) {
                if (type === 'coiffeur') {
                    getWaitings()
                    socket.emit('register-pro-device', id)
                    socket.on('new_booking', (data) => {
                        const year = dayjs(data.date).year()
                        const week = dayjs(data.date).isoWeek()

                        getWaitings()
                        getNotifications()
                        refreshWeek(year, week)
                    })

                    socket.on('cancel_booking', (data) => {
                        const year = dayjs(data.date).year();
                        const week = dayjs(data.date).isoWeek();
    
                        getWaitings();
                        getNotifications();
                        refreshWeek(year, week);
                    });
                } else {
                    redirectClientFromWrongSide()
                }
            } else {
                navigate('/')
            }
    }, [restored])

    const handleOverlayClick = () => {
        resetTimeouts()

        setIsOverlayVisible(false)
        overlayTimeoutRef.current = setTimeout(() => {
            setIsExiting(true)
        }, 5000)

        messageTimeoutRef.current = setTimeout(() => {
            setMessage({ type: 'info', message: null })
            setIsExiting(false)
        }, 5300)
    }

    useEffect(() => {
        if (isOverlayVisible) {
            resetTimeouts()

            overlayTimeoutRef.current = setTimeout(() => {
                setIsOverlayVisible(false)
            }, 2000)

            exitTimeoutRef.current = setTimeout(() => {
                setIsExiting(true)
            }, 7000)

            messageTimeoutRef.current = setTimeout(() => {
                setMessage({ type: 'info', message: null })
                setIsExiting(false)
            }, 7300)
        }
    }, [isOverlayVisible])

    const resetTimeouts = () => {
        clearTimeout(overlayTimeoutRef.current)
        clearTimeout(exitTimeoutRef.current)
        clearTimeout(messageTimeoutRef.current)
    }

    return (
        <ProContext.Place.PlaceContextProvider>
            <ProContext.OnBoarding.ProOnBoardingContextProvider>
                {!onBoardingLoaded && (
                    <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-white z-99">
                        <Loader.Primary />
                        <p className="text-[16px] font-medium text-center underline text-v2_purple">
                            Chargement du profil ...
                        </p>
                    </div>
                )}
                <div className="z-0 w-full h-screen overflow-hidden text-black">
                    <div className={`fixed h-fit w-fit top-0 left-0 z-99 ${isExiting ? 'animate-slideOutUp' : ''} `}>
                        {isOverlayVisible && message.message && (
                            <div
                                className="fixed top-0 left-0 z-0 w-full h-full bg-black animate-fadeInNotification"
                                onClick={handleOverlayClick}
                            ></div>
                        )}
                        {message.message && (
                            <>
                                {message.type === 'info' && <Message.Info message={message.message} />}
                                {message.type === 'warning' && <Message.Warning message={message.message} />}
                                {message.type === 'error' && <Message.Error message={message.message} />}
                                {message.type === 'success' && (
                                    <Message.Success
                                        message={message.message}
                                        setOverlayVisible={setIsOverlayVisible}
                                        isOverlayVisible={isOverlayVisible}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    {navbarLoggedTop && <Navbar.LoggedTop />}
                    <div className="overflow-x-hidden overflow-y-auto h-logged" id="scrollable-zone">
                        {step < 4 ? <Profesionnal.Onboarding /> : <Outlet />}
                    </div>
                    {navbarBottom && <Navbar.Coiffeur />}
                </div>
            </ProContext.OnBoarding.ProOnBoardingContextProvider>
        </ProContext.Place.PlaceContextProvider>
    )
}

export { UnLogged, UnLoggedBooking, Client, Pro }
