import React from 'react'
import { Text } from '../../../atoms'
import { LuCalendarDays, LuWallet, LuClock } from 'react-icons/lu'
import { IoLocationOutline } from 'react-icons/io5'
import { formatSecondsToHHhmm, formatMinutesToHHhmm } from '../../../../../utils'
import { HandCoins } from 'lucide-react'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

export default function List({
    type,
    selectedReservation,
    totalPrice,
    totalTime,
    selectedAddress,
    priceVariable,
    paymentMode,
}) {
    return (
        <div className="flex flex-col py-4 space-y-1">
            {type === 'date' && (
                <>
                    <div className="flex items-center">
                        <LuCalendarDays className="w-6 h-6 mr-2 text-v2_purple" />
                        <Text.Normal className="text-[18px] text-v2_purple font-semibold">Date et heure</Text.Normal>
                    </div>
                    <div>
                        <Text.Normal className="font-normal capitalize">
                            {dayjs(selectedReservation.date).locale('fr').format('dddd D MMMM')} -{' '}
                            {formatSecondsToHHhmm(selectedReservation.horaire.start)}
                        </Text.Normal>
                    </div>
                </>
            )}
            {type === 'price' && (
                <>
                    <div className="flex items-center">
                        <LuWallet className="w-6 h-6 mr-2 text-v2_purple" />
                        <Text.Normal className="text-[18px] text-v2_purple font-semibold">Coût total</Text.Normal>
                    </div>
                    <div>
                        <Text.Normal className="font-normal">
                            {priceVariable ? 'À partir de ' : ''}
                            {totalPrice} €
                        </Text.Normal>
                    </div>
                </>
            )}
            {type === 'time' && (
                <>
                    <div className="flex items-center">
                        <LuClock className="w-6 h-6 mr-2 text-v2_purple" />
                        <Text.Normal className="text-[18px] text-v2_purple font-semibold">Durée totale</Text.Normal>
                    </div>
                    <div>
                        <Text.Normal className="font-normal">{formatMinutesToHHhmm(totalTime / 60)}</Text.Normal>
                    </div>
                </>
            )}
            {type === 'address' && (
                <>
                    <div className="flex items-center">
                        <IoLocationOutline className="w-6 h-6 mr-2 text-v2_purple" />
                        <Text.Normal className="text-[18px] text-v2_purple font-semibold">Adresse</Text.Normal>
                    </div>
                    <div>
                        <Text.Normal className="font-normal">{selectedAddress}</Text.Normal>
                    </div>
                </>
            )}
            {type === 'paymentMode' && (
                <>
                    <div className="flex items-center">
                        <HandCoins className="w-6 h-6 mr-2 text-v2_purple" />
                        <Text.Normal className="text-[18px] text-v2_purple font-semibold">Mode de paiement</Text.Normal>
                    </div>
                    <div>
                        {paymentMode.map((i) => {
                            return (
                                <Text.Normal key={i.name} className="font-normal">
                                    {i.name}
                                </Text.Normal>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}
