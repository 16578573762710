import React, { createContext, useContext, useState, useEffect } from 'react'

import * as AgendaDB from './AgendaDB'
import { Auth, Request } from '../..'
import dayjs from 'dayjs'

/* eslint no-unused-vars: 0 */
const MonthContext = createContext({
    database: Object || null,
    nextMonth: Object || null,
    currentMonth: Object || null,
    previousMonth: Object || null,
    needSynchronisation: Boolean,
    getMonth: (key) => {
        key = 'YYYY-MM'
    },
    synchroniseMonth: (key) => {
        key = 'YYYY-MM'
    },
    refreshMonth: (key) => {
        key = 'YYYY-MM'
    },
})

export function MonthContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token, id, type, isLogged } = Auth.useAuth()

    const [agendaDatabase, setAgendaDatabase] = useState(null)

    useEffect(() => {
        AgendaDB.openIndexedDB().then((database) => setAgendaDatabase(database))
    }, [])

    const [previousMonth, setPreviousMonth] = useState()
    const [currentMonth, setCurrentMonth] = useState()
    const [nextMonth, setNextMonth] = useState()

    const getMonthFromCache = async (key) => {
        if (!('indexedDB' in window)) return null
        return await AgendaDB.getOneMonth(agendaDatabase, key)
    }

    const setMonth = (variableToChange, value) => {
        switch (variableToChange) {
            case 'current':
                setCurrentMonth(value)
                break
            case 'previous':
                setPreviousMonth(value)
                break
            case 'next':
                setNextMonth(value)
                break
        }
    }

    const getMonthPrivate = async (key, variableToChange) => {
        let data = await getMonthFromCache(key)

        if (!data) {
            const response = await handleRequest('get', `coiffeur/days/${id}/${key}-01/month`, null, token)

            data = response.data.days.reduce((days, value) => {
                const { date, ...data } = value
                days[date] = data
                return days
            }, {})

            setMonth(variableToChange, data)
            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: data,
                refreshAt: dayjs().toISOString(),
            })
        } else {
            setMonth(variableToChange, data.days)
        }

        return
    }

    const getMonth = async (currentMonthKey) => {
        const currentMonthFirstDay = dayjs(`${currentMonthKey}-01`)
        await getMonthPrivate(currentMonthKey, 'current')

        const nextMonthKey = currentMonthFirstDay.add(1, 'month').format('YYYY-MM')
        const previousMonthKey = currentMonthFirstDay.add(-1, 'month').format('YYYY-MM')
        await Promise.all([getMonthPrivate(nextMonthKey, 'next'), getMonthPrivate(previousMonthKey, 'previous')])

        return
    }

    const [needSynchronisation, setNeedSynchronisation] = useState(false)

    const getMonthBackground = async (key) => {
        const response = await handleRequest('get', `coiffeur/days/${id}/${key}-01/month`, null, token)

        if (response) {
            let data = response.data.days.reduce((days, value) => {
                const { date, ...data } = value
                days[date] = data
                return days
            }, {})

            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: data,
                refreshAt: dayjs().toISOString(),
            })

            setNeedSynchronisation(true)
        }

        return
    }

    const refreshMonthLoaded = async () => {
        if (isLogged() && agendaDatabase) {
            const months = await AgendaDB.getAllMonth(agendaDatabase)
            const actualTime = dayjs()

            for (let i = 0; i < months.length; i++) {
                const deltaTime = Math.abs(dayjs(months[i].refreshAt).diff(actualTime))

                if (deltaTime > 1800000) {
                    getMonthBackground(months[i].monthKey)
                }
            }
        }
    }

    const getDatasInBackground = async () => {
        const today = dayjs()

        let key = today.format('YYYY-MM')
        await getMonthBackground(key)

        for (let i = 1; i < 12; i++) {
            key = today.add(i, 'month').format('YYYY-MM')
            await getMonthBackground(key)

            key = today.add(-i, 'month').format('YYYY-MM')
            await getMonthBackground(key)
        }

        setNeedSynchronisation(true)
        return
    }

    const updateDays = async (days) => {
        const months = await AgendaDB.getAllMonth(agendaDatabase)

        for (const day of days) {
            const key = dayjs(day?.date).format('YYYY-MM')
            months[key].days[day?.date] = day

            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: months[key].days,
                refreshAt: dayjs().toISOString(),
            })
        }

        setNeedSynchronisation(true)
        return
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur' && agendaDatabase) {
            getDatasInBackground()

            const interval = setInterval(refreshMonthLoaded, 300000)

            return () => clearInterval(interval)
        }
    }, [id, token, agendaDatabase])

    const synchroniseMonth = async (currentMonthKey) => {
        await getMonth(currentMonthKey)
        setNeedSynchronisation(false)
    }

    return (
        <MonthContext.Provider
            value={{
                database: agendaDatabase,
                previousMonth: previousMonth,
                currentMonth: currentMonth,
                nextMonth: nextMonth,
                needSynchronisation: needSynchronisation,
                getMonth: getMonth,
                synchroniseMonth: synchroniseMonth,
                refreshMonth: getMonthBackground,
                updateDays: updateDays,
                setNeedSynchronisation: setNeedSynchronisation,
            }}
        >
            {children}
        </MonthContext.Provider>
    )
}

export const useMonth = () => useContext(MonthContext)
