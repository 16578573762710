import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Map } from '../../../atoms'
import { Schedule } from '../../../molecules'

import { ProContext } from '../../../../../contexts'

const Month = () => {
    const { currentMonth, needSynchronisation, synchroniseMonth, getMonth, database } = ProContext.Agenda.useMonth()
    const { currentDay, getDay } = ProContext.Agenda.useDay()
    const day = ProContext.Agenda.useDay()
    const { selectBooking } = ProContext.Bookings.useBooking()

    const { viewSelected, monthSelected, daySelected } = ProContext.Agenda.useView()

    const [dataForMonthView, setDataForMonthView] = useState([])
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        if (database) {
            if (currentDay) {
                getDay(currentDay.date)
            }
            synchroniseMonth(monthSelected)
        }
    }, [needSynchronisation])

    useEffect(() => {
        if (database) getMonth(monthSelected)
    }, [monthSelected])

    useEffect(() => {
        if (currentMonth) {
            if (viewSelected === 'month') {
                let data = Object.keys(currentMonth).map((key) => {
                    return { ...currentMonth[key], date: key }
                })

                setDataForMonthView(prepareDatasForMonthCalendar(data, monthSelected))
            } else {
                setScrolled(false)
            }
        }
    }, [needSynchronisation, currentMonth, viewSelected])

    useEffect(() => {
        if (currentMonth) {
            if (viewSelected === 'month') {
                if (daySelected !== currentDay?.date) {
                    if (day.database) {
                        getDay(daySelected)
                    }
                }
            }
        }
    }, [currentMonth, daySelected])

    useEffect(() => {
        if (dataForMonthView && viewSelected === 'month' && !scrolled) {
            const container = document.getElementById('scrollable-zone')
            container.scrollTo(0, window.innerHeight / 4)
            setScrolled(true)
        }
    }, [dataForMonthView])

    return (
        <div className="grid gap-[39px] px-[8px]">
            {currentMonth ? (
                <Schedule.MonthlyCalendar days={dataForMonthView} selectedDay={daySelected} />
            ) : (
                <Schedule.MonthlyCalendarLoad currentDate={dayjs(`${monthSelected}-01`)} />
            )}
            {!currentDay ? (
                <>Loading</>
            ) : (
                <div className="grid gap-[30px]">
                    <Schedule.MonthlyDate day={currentDay} />
                    <div className=" grid gap-[16px]">
                        {currentDay?.bookings
                            ?.filter((booking) => booking.state !== 'cancel' && booking?._id)
                            .sort((a, b) => a.from - b.from).length > 0 ? (
                            currentDay.bookings
                                .filter((booking) => booking.state !== 'cancel')
                                .sort((a, b) => a.from - b.from)
                                .map((booking /*index*/) => {
                                    return (
                                        <>
                                            <Schedule.NextRDV booking={booking} />
                                            {/* {index !==
                                                currentDay.bookings.filter((booking) => booking.state !== 'cancel')
                                                    .length -
                                                    1 &&
                                                !booking.isPrivate &&
                                                booking.address?.lon &&
                                                currentDay.bookings[index + 1].address?.lon && (
                                                    <Schedule.DurationRdv
                                                        source={booking.address}
                                                        target={currentDay.bookings[index + 1].address}
                                                    />
                                                )} */}
                                        </>
                                    )
                                })
                        ) : (
                            <>Aucun rendez-vous</>
                        )}
                    </div>

                    <div className="mt-[48px]">
                        <Map.Tour
                            bookings={currentDay.bookings
                                ?.filter(
                                    (booking) =>
                                        (booking.state === 'booked' ||
                                            booking.state === 'waiting' ||
                                            booking.state === 'done') &&
                                        !booking.isPrivate &&
                                        parseInt(booking.address?.lat).toString() !== 'NaN' &&
                                        booking._id
                                )
                                .sort((a, b) => {
                                    if (a.from > b.from) return 1
                                    else return -1
                                })}
                            address={{ lon: 2.147899, lat: 43.9277552 }}
                            selectBooking={(value) => selectBooking(value)}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Month

const prepareDatasForMonthCalendar = (data, monthSelected) => {
    const dayInMonth = dayjs(`${monthSelected}-01`).daysInMonth()
    const nullLength = dayjs(`${monthSelected}-01`).day() === 0 ? 6 : dayjs(`${monthSelected}-01`).day() - 1

    if (data.length === dayInMonth) {
        const monthStart = Array.from({
            length: nullLength,
        }).fill(null)

        return monthStart.concat(data)
    } else {
        if (data.length > 0) {
            let startIndex = dayjs(data[0].date).date()
            let endIndex = dayjs(data[data.length - 1].date).date()

            if (startIndex !== 1) {
                const monthStart = Array.from({
                    length: nullLength,
                })
                    .fill(null)
                    .concat(
                        Array.from({ length: startIndex - 1 }, (_, i) => {
                            return {
                                date: `${monthSelected}-${i + 1}`,
                                bookings: [],
                                isWorkingDay: false,
                                blocs: [],
                            }
                        })
                    )

                return monthStart.concat(data)
            } else if (endIndex !== dayInMonth) {
                const endMonth = Array.from({ length: endIndex - 1 }, (_, i) => {
                    return {
                        date: `${monthSelected}-${endIndex + i + 1}`,
                        bookings: [],
                        isWorkingDay: false,
                        blocs: [],
                    }
                })

                return data.concat(endMonth)
            }
        } else {
            return Array.from({
                length: nullLength,
            })
                .fill(null)
                .concat(
                    Array.from({ length: dayInMonth }, (_, i) => {
                        return {
                            date: `${monthSelected}-${i + 1}`,
                            bookings: [],
                            isWorkingDay: false,
                            blocs: [],
                        }
                    })
                )
        }
    }
}
