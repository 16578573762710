import { Pencil } from 'lucide-react'
import React, { useState } from 'react'

const Profil = ({ title, children, noEdit, toNavigate }) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={`grid gap-[0px] w-[92vw] px-[20px] py-[19px] rounded-[30px] shadow-basic z-[90]`}>
            {!noEdit && (
                <div
                    className={`transition-all flex w-full justify-end ${
                        open ? 'h-full opacity-100' : 'h-0 opacity-0'
                    }`}
                >
                    <div className="flex space-x-1" onClick={() => toNavigate()}>
                        <span>Modifier</span>
                        <Pencil size={20} />
                    </div>
                </div>
            )}
            <div className={`grid ${open ? 'gap-[20px]' : ''}`}>
                <div className="flex space-x-[16px] w-fit items-center" onClick={() => setOpen(!open)}>
                    <p className="font-regular text-[18px] leading-[27px] text-[#000]">{title}</p>
                </div>
                <div className={`transition-all grid ${open ? 'h-full p-0 w-full opacity-100' : 'h-0 opacity-0'}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export { Profil }
