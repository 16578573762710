import React, { useState, useEffect } from 'react'

import { Text, Input, Map, Modal, Horaires, Button } from '../../atoms'
import { Images } from '../../organisms'
import { Pro } from '../../templates'
import { ProContext, Address } from '../../../../contexts'
import { useProfilPro } from '../../../../contexts/Pro/profil'
import { useImages } from '../../../../contexts/images'

import { ImageUp, GripVertical, Trash2, ChevronRight, Info } from 'lucide-react'

import AvatarDefault from '../../../../assets/avatar/Girl.svg'

const CategoriesForm = () => {
    const { handleSaveAttribute, handleSetAttribute, category, yearsExperience, description } =
        ProContext.Profil.useProfilPro()

    const [welcomeModalOpen, setWelcomeModalOpen] = useState(() => {
        const hasSeenWelcomeModal = localStorage.getItem('hasSeenWelcomeModal')
        if (hasSeenWelcomeModal === null) {
            localStorage.setItem('hasSeenWelcomeModal', 'false')
            return true
        }
        return hasSeenWelcomeModal === 'false'
    })

    const handleModalClose = () => {
        setWelcomeModalOpen(false)
        localStorage.setItem('hasSeenWelcomeModal', 'true')
    }

    return (
        <div>
            <Modal.Favorite title={'Bienvenue'} isOpen={welcomeModalOpen} setIsOpen={handleModalClose}>
                <div className="flex flex-row justify-center w-full my-6" onClick={handleModalClose}>
                    <Text.Normal align={'center'}>
                        Maintenant, remplissez bien votre profil pour l'activation. La conception correcte du profil
                        contribuera à attirer l'attention des clients, alors essayer de remplir tous les champs suggérés
                    </Text.Normal>
                </div>
            </Modal.Favorite>
            <div className="mb-4">
                <Text.H5 color={'v2_purple'} align={'center'} className={'font-medium'}>
                    Complétez votre profil
                </Text.H5>
            </div>
            <div className="mb-4">
                <Input.Dropdown
                    multiple={true}
                    value={category ? category : []}
                    setValue={async (value) => {
                        if (!value) handleSaveAttribute('category', [])
                        else {
                            if (category?.indexOf(value) === -1) {
                                let categories = [...category]
                                categories.push(value)
                                handleSaveAttribute('category', categories)
                            } else {
                                let categories = category ? [...category] : []
                                categories.splice(category.indexOf(value), 1)
                                handleSaveAttribute('category', categories)
                            }
                        }
                    }}
                    label={'Choisissez votre ou vos catégories'}
                    options={[
                        { value: 'coiffure', label: 'Coiffeur' },
                        { value: 'esthetique', label: 'Esthéticienne' },
                        { value: 'barbier', label: 'Barbier' },
                        { value: 'ongulaire', label: 'Prothésiste ongulaire' },
                    ]}
                />
            </div>
            <div className="mb-4">
                <Input.Text
                    label={"Années d'expérience"}
                    placeholder={'10'}
                    value={yearsExperience}
                    onChange={(value) => {
                        let convertedValue = value ? parseInt(value) : 0

                        handleSetAttribute('yearsExperience', parseInt(convertedValue))
                    }}
                    onBlur={() => {
                        if (yearsExperience) {
                            handleSaveAttribute('yearsExperience', yearsExperience)
                        }
                    }}
                />
            </div>
            <div className="mb-4">
                <Input.TextArea
                    label={'Biographie'}
                    placeholder={'Présentez-vous de la manière que vous le souhaitez....'}
                    value={description}
                    onChange={(value) => handleSetAttribute('description', value)}
                    onBlur={() => handleSaveAttribute('description', description)}
                />
            </div>
        </div>
    )
}

const SpecialitiesForm = () => {
    const { handleSaveAttribute, globalSpecialities, createSpeciality, specialities } = ProContext.Profil.useProfilPro()

    const [allSpecialities, setAllSpecialities] = useState(globalSpecialities)

    const [createSpecialities, setCreateSpecialities] = useState(false)
    const [newSpecialities, setNewSpecialities] = useState('')

    return (
        <div className="my-4">
            <Input.Specialities
                data={allSpecialities}
                label={'Ajouter vos spécialitées'}
                placeholder={'Vos spécialitées'}
                values={specialities}
                onChange={(newValue) => {
                    let indexToRemove
                    let newSpecialities = [...specialities]
                    specialities.map((speciality, index) => {
                        if (speciality._id === newValue._id) indexToRemove = index
                    })

                    if (typeof indexToRemove === 'number') {
                        newSpecialities.splice(indexToRemove, 1)
                        handleSaveAttribute('specialities', newSpecialities)
                    } else {
                        newSpecialities.push(newValue)
                        handleSaveAttribute('specialities', newSpecialities)
                    }
                    setAllSpecialities(allSpecialities.filter((item) => item._id !== newValue._id))
                }}
                reOrder={(value) => {
                    handleSaveAttribute('specialities', value)
                }}
            />
            {createSpecialities ? (
                <Input.Text
                    placeholder={'Créer une spécialité personnalisé'}
                    value={newSpecialities}
                    onChange={(value) => setNewSpecialities(value)}
                    onBlur={() => {
                        setCreateSpecialities(!createSpecialities)
                        createSpeciality(newSpecialities)
                        setNewSpecialities('')
                    }}
                    maxLength={30}
                />
            ) : (
                <div onClick={() => setCreateSpecialities(!createSpecialities)}>
                    <Text.Small color={'v2_purple'} className={'underline'}>
                        Créer une spécialité personnalisé
                    </Text.Small>
                </div>
            )}
        </div>
    )
}

const AddressForm = ({ onChange }) => {
    const { setStep } = Address.useAddress()

    const { handleSaveAttribute, handleSetAttribute, address, rayon } = ProContext.Profil.useProfilPro()

    const [rayonLocal, setRayonLocal] = useState(rayon)

    useEffect(() => {
        onChange(rayonLocal)
    }, [rayonLocal])

    const handleChangeRayon = (value) => {
        if (/^\d{0,3}$/.test(value)) {
            if (parseInt(value) < 100 || value === '') {
                handleSetAttribute('rayon', value)
                setRayonLocal(value)
            }
        }
    }

    return (
        <div>
            {address && address != null ? (
                <div className="my-4">
                    <Text.Normal className={'font-medium'} color={'v2_black'}>
                        Rayon de déplacement
                    </Text.Normal>
                    <Pro.Address.default>
                        {address.formatted ? (
                            <>
                                <div className="relative">
                                    <div
                                        onClick={() => setStep('address')}
                                        className="py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple text-nowrap truncate relative"
                                    >
                                        {address.formatted}
                                    </div>
                                    <span className="bg-white text-[12px] italic leading-[16.8px] tracking-[-0.012px] text-[#979797]">
                                        (non visible par les clients)
                                    </span>
                                </div>
                                <div className="flex flex-row items-center w-full my-4">
                                    <div className="w-1/5">
                                        <Input.Text
                                            placeholder={'Entrez votre rayon de déplacement'}
                                            value={rayonLocal}
                                            onChange={(value) => {
                                                const parsedValue = value ? parseInt(value) : 0
                                                handleChangeRayon(parsedValue)
                                            }}
                                            onBlur={() => {
                                                if (rayonLocal !== 0) {
                                                    handleSaveAttribute('rayon', rayonLocal)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <Text.Normal className={'font-medium'} color={'v2_black'}>
                                            km - Limité à 100km
                                        </Text.Normal>
                                    </div>
                                </div>
                                {typeof rayon === 'number' ? <Map.Rayon /> : <></>}
                            </>
                        ) : (
                            <div className="relative">
                                <div
                                    onClick={() => setStep('address')}
                                    className="py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple text-nowrap truncate relative text-[#979797]"
                                >
                                    Entrez votre adresse
                                </div>
                                <span className="bg-white text-[12px] italic leading-[16.8px] tracking-[-0.012px] text-[#979797]">
                                    (non visible par les clients)
                                </span>
                            </div>
                        )}
                    </Pro.Address.default>
                </div>
            ) : null}
        </div>
    )
}

const TimingForm = () => {
    const { defaultDays, setDefaultDays } = ProContext.Schedule.useSchedule()

    const [helpModalOpen, setHelpModalOpen] = useState(true)
    const [algoModalOpen, setAlgoModalOpen] = useState(false)

    const handleDayChange = (index, value) => {
        const daysCopy = [...defaultDays]
        daysCopy[index] = value
        daysCopy[index] = { ...value, notCompleted: false }
        setDefaultDays(daysCopy)
    }

    const [openDayIndex, setOpenDayIndex] = useState(0)

    useEffect(() => {
        const enrichedDays = defaultDays.map((day) =>
            day.notCompleted === undefined ? { ...day, notCompleted: true } : day
        )
        setDefaultDays(enrichedDays)
    }, [])

    const toggleDay = (index) => {
        setOpenDayIndex((prevIndex) => (prevIndex === index ? -1 : index))
    }

    return (
        <div>
            <Modal.Favorite title={''} isOpen={helpModalOpen} setIsOpen={() => setHelpModalOpen(!helpModalOpen)}>
                <div
                    className="flex flex-row justify-center w-full my-6"
                    onClick={() => setHelpModalOpen(!helpModalOpen)}
                >
                    <div>
                        <Text.Normal align={'center'}>
                            Remplir les informations sur les jours et horaires de travail.
                        </Text.Normal>
                        <Text.Normal align={'center'}>
                            Ces informations seront dans votre profil, elles pourront être modifiées à tout moment en
                            fonction de votre emploi du temps
                        </Text.Normal>
                    </div>
                </div>
            </Modal.Favorite>
            <Modal.Favorite title={''} isOpen={algoModalOpen} setIsOpen={() => setAlgoModalOpen(!algoModalOpen)}>
                <div
                    className="flex flex-row justify-center w-full my-6"
                    onClick={() => setAlgoModalOpen(!algoModalOpen)}
                >
                    <div>
                        <Text.Normal align={'center'}>Explication de l'algo</Text.Normal>
                    </div>
                </div>
            </Modal.Favorite>
            <Pro.Address.Places>
                {/* Affichage des horaires pour chaque jour */}
                <div className="flex justify-start items-center pb-4 space-x-1">
                    <p className="text-[20px] font-semibold px-2">Horaires</p>
                    <div onClick={() => setAlgoModalOpen(!algoModalOpen)}>
                        <Info size={24} color="black" />
                    </div>
                </div>
                {defaultDays &&
                    defaultDays.map((day, index) => (
                        <div key={index} className="shadow-basic">
                            {openDayIndex != index && (
                                <div
                                    className="flex justify-between items-center cursor-pointer mb-2 px-2 py-5"
                                    onClick={() => toggleDay(index)}
                                >
                                    <p className="text-[18px] uppercase font-semibold text-v2_purple">{day.name}</p>
                                    <ChevronRight size={24} color="#72517E" />
                                </div>
                            )}
                            {openDayIndex === index && (
                                <div className="mx-2">
                                    <div>
                                        <Horaires.TimingRDV
                                            day={day}
                                            onChange={(value) => handleDayChange(index, value)}
                                            createProfile={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
            </Pro.Address.Places>
        </div>
    )
}

const CompanyLogo = () => {
    const { avatar, getImages } = useImages()
    const { handleSaveAttribute } = useProfilPro()

    const [changeAvatar, setChangeAvatar] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
        <div>
            <div className="my-4">
                <Text.Normal className={'font-medium'} align={'center'} color={'v2_black'}>
                    Votre photo de profil ou le logo de votre entreprise
                </Text.Normal>
            </div>
            {changeAvatar && (
                <Images.Upload
                    title="Changer de photo de profil"
                    setIsOpen={() => setChangeAvatar(false)}
                    isOpen={changeAvatar}
                    afterUpload={async (value) => {
                        setLoading(true)

                        await handleSaveAttribute('avatar', value)
                        setChangeAvatar(false)
                        setLoading(false)
                        await getImages()
                    }}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            <div
                className="size-[100px] rounded-full mx-auto border border-black flex items-center justify-center relatvie overflow-hidden"
                onClick={() => setChangeAvatar(!changeAvatar)}
            >
                {avatar ? (
                    <div
                        style={{
                            backgroundImage: `url(${avatar.thumbnailUrl})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center top',
                        }}
                        className="size-[100px]"
                    ></div>
                ) : (
                    <ImageUp color="black" size={28} />
                )}
            </div>
        </div>
    )
}

const Galery = () => {
    const { images, getImages, handleDeleteImage } = useImages()
    const { handleSaveAttribute } = useProfilPro()

    const [photoModal, setPhotoModal] = useState(false)
    const [deleteImage, setDeleteImage] = useState(false)

    const [loading, setLoading] = useState(false)

    return (
        <div className="px-[21px] py-[27px]">
            {photoModal && (
                <Images.Upload
                    title="Ajouter une photo"
                    setIsOpen={() => setPhotoModal(false)}
                    isOpen={photoModal}
                    afterUpload={async (value) => {
                        setLoading(true)
                        const newPhotos = [...images.current]
                        newPhotos.push(value)

                        await handleSaveAttribute('photos', newPhotos)
                        await getImages()
                        setPhotoModal(false)
                        setLoading(false)
                    }}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            <div className="my-4">
                <Text.Normal className={'font-medium'} color={'v2_black'}>
                    Votre galerie photo
                </Text.Normal>
            </div>
            <div className="flex flex-row flex-wrap justify-between w-full my-4">
                {images.current.map((image) => {
                    return (
                        <>
                            <Modal.Choice isOpen={deleteImage === image._id}>
                                <p>Êtes-vous sur de vouloir surpprimer cette photo ?</p>
                                <div className="flex space-x-2">
                                    <Button.Secondary
                                        title="Oui"
                                        onClick={async () => {
                                            await handleDeleteImage(image._id)
                                            setDeleteImage(null)
                                        }}
                                    />
                                    <Button.Secondary title="Non" onClick={() => setDeleteImage(null)} />
                                </div>
                            </Modal.Choice>
                            <div className="size-[150px] bg-slate-200 m-1 relative overflow-hidden" key={image._id}>
                                <GripVertical
                                    className="absolute top-2 left-2 drop-shadow-drag"
                                    color="white"
                                    size={28}
                                />
                                <div
                                    style={{
                                        backgroundColor: '#e2e8f0',
                                        backgroundImage: `url(${image.thumbnailUrl})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center top',
                                        margin: 0,
                                    }}
                                    className="size-[150px]"
                                    onClick={() => setPhotoModal(!photoModal)}
                                ></div>
                                <div
                                    className="absolute p-2 bg-white rounded-full bottom-2 right-2"
                                    onClick={() => setDeleteImage(image._id)}
                                >
                                    <Trash2 color="black" size={28} />
                                </div>
                            </div>
                        </>
                    )
                })}
                <div className="w-full mt-4">
                    <Button.Secondary title={'Ajouter plus de photo'} onClick={() => setPhotoModal(!photoModal)} />
                </div>
            </div>
        </div>
    )
}

const PaymentMethod = () => {
    const { handleSaveAttributeV2, paymentMode } = ProContext.Profil.useProfilPro()

    return (
        <div className="my-4">
            <div className="mb-8">
                <Text.H5 color={'v2_purple'} className={'font-medium'}>
                    Choisir les modes de paiement acceptés
                </Text.H5>
            </div>
            <div className="grid gap-[20px] mb-12">
                {paymentMode &&
                    paymentMode.map((item, index) => {
                        return (
                            <Input.Checkbox
                                key={index}
                                label={item.name}
                                value={item.accepted}
                                setValue={(value) => {
                                    let newValue = [...paymentMode]
                                    newValue[index] = { name: item.name, accepted: value }

                                    handleSaveAttributeV2('paymentMode', newValue)
                                }}
                            />
                        )
                    })}
            </div>
        </div>
    )
}

const ActivationProfile = () => {
    const { handleSaveAttributeV2, companyName } = ProContext.Profil.useProfilPro()

    const [toggleValue, setToggleValue] = useState(false)

    return (
        <div className="my-4">
            <div className="my-8">
                <Text.H5 align={'center'} color={'v2_purple'} className={'font-medium'}>
                    Activez votre profil
                </Text.H5>
            </div>
            <div className="flex w-full justify-center items-center py-8">
                <div className="flex space-x-[24px]">
                    <div
                        style={{
                            backgroundImage: `url(${AvatarDefault})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                        }}
                        className="size-16"
                    ></div>
                    <div className="grid gap-[8px]">
                        <p className="text-[18px] font-bold leading-[32px] tracking-[-0.36px]">{companyName}</p>
                        <Input.Toggle
                            value={toggleValue}
                            setValue={() => {
                                let newValue = !toggleValue
                                setToggleValue(newValue)
                                handleSaveAttributeV2('isVisible', newValue)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-center items-center py-8 mb-8">
                <div className="flex flex-col w-4/5 space-y-4">
                    <Text.Normal align={'center'}>
                        Après activation, votre profil sera visible par les clients.
                    </Text.Normal>
                    <Text.Normal align={'center'}>
                        Vous pouvez modifier cela à tout moment dans les paramètres de votre profil.
                    </Text.Normal>
                </div>
            </div>
        </div>
    )
}

export {
    CategoriesForm,
    SpecialitiesForm,
    AddressForm,
    TimingForm,
    CompanyLogo,
    Galery,
    PaymentMethod,
    ActivationProfile,
}
