import React, { useEffect } from 'react'

import { Loader, Text } from '../../atoms'
import { Search } from '../../organisms'

import { Address, ProContext } from '../../../../contexts'

const Main = ({ children, setAddress, noAddressDefault }) => {
    const place = ProContext.Place.usePlace()
    const { handleSaveAttribute } = ProContext.Profil.useProfilPro()
    const { step, setStep, selectedAddress, coordinates, setSelectedAddress } = Address.useAddress()

    useEffect(() => {
        if (place.step === 'hidden') {
            if (coordinates.latitude) {
                if (setAddress) {
                    setAddress({
                        formatted: selectedAddress,
                        lat: coordinates.latitude,
                        lon: coordinates.longitude,
                    })
                } else {
                    handleSaveAttribute('address', {
                        formatted: selectedAddress,
                        lat: coordinates.latitude,
                        lon: coordinates.longitude,
                    })
                }
                setStep('home')
            }
        }
    }, [coordinates])

    useEffect(() => {
        if (noAddressDefault) setSelectedAddress('')
    }, [])

    return (
        <div className="h-full">
            {(step === 'home' || place.step !== 'hidden') && <div>{children}</div>}
            {step === 'loading' && place.step === 'hidden' && (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.H4 color={'v2_purple'} align={'center'}>
                        L'algorithme recherche les meilleures options pour vous
                    </Text.H4>
                </div>
            )}
            {step === 'address' && place.step === 'hidden' && (
                <Search.Search
                    noAddressDefault={noAddressDefault}
                    launchResearch={() => {}}
                    noHome={!children}
                    setRequestInProgress={() => {}}
                />
            )}
            {step === 'results' && place.step === 'hidden' && <Search.Results />}
        </div>
    )
}

export const Places = ({ children, from }) => {
    const place = ProContext.Place.usePlace()
    const { step, selectedAddress, coordinates, setStep } = Address.useAddress()

    useEffect(() => {
        if (step === 'address') {
            if (place.step === 'hidden') place.setStep('create')
        }
        if (from && from === 'profil') {
            setStep('home')
        }
    }, [])

    return (
        <div className="h-full">
            {step === 'home' && <div>{children}</div>}
            {step === 'loading' && place.step !== 'hidden' && (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.H5 color={'v2_purple'} align={'center'}>
                        L'algorithme vérifie que le professionnel soit dans votre région
                    </Text.H5>
                </div>
            )}
            {step === 'address' && place.step !== 'hidden' && (
                <Search.Searches.ResearchForPlaces
                    launchResearch={async () => {
                        if (place.step === 'create') {
                            const response = await place.createPlace({
                                formatted: selectedAddress,
                                lat: coordinates.latitude,
                                lon: coordinates.longitude,
                            })

                            if (response) {
                                place.setSelected(response.place)
                                place.setStep('hidden')
                                setStep('home')
                            }
                        } else if (place.step === 'edit') {
                            const response = await place.editPlace({
                                formatted: selectedAddress,
                                lat: coordinates.latitude,
                                lon: coordinates.longitude,
                            })

                            if (response) {
                                place.setSelected(response.place)
                                place.setStep('hidden')
                                setStep('home')
                            }
                        }
                    }}
                    noHome={!children}
                />
            )}
        </div>
    )
}

export const Personnal = ({ children }) => {
    const place = ProContext.Place.usePlace()
    const { handleSaveAttributeV2, personnalAddress } = ProContext.Profil.useProfilPro()
    const { step, setStep, selectedAddress, coordinates, setSelectedAddress, setCoordinates } = Address.useAddress()

    useEffect(() => {
        if (place.step === 'hidden') {
            if (coordinates.latitude) {
                handleSaveAttributeV2('address', {
                    formatted: selectedAddress,
                    lat: coordinates.latitude,
                    lon: coordinates.longitude,
                })
                setStep('home')
            }
        }
    }, [coordinates])

    useEffect(() => {
        setSelectedAddress(personnalAddress.formatted)
        setCoordinates({ latitude: personnalAddress.lat, longitude: personnalAddress.lon })
    }, [])

    return (
        <div className="h-full">
            {(step === 'home' || place.step !== 'hidden') && <div>{children}</div>}
            {step === 'loading' && place.step === 'hidden' && (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.H4 color={'v2_purple'} align={'center'}>
                        L'algorithme recherche les meilleures options pour vous
                    </Text.H4>
                </div>
            )}
            {step === 'address' && place.step === 'hidden' && (
                <Search.Search launchResearch={() => {}} noHome={!children} setRequestInProgress={() => {}} />
            )}
            {step === 'results' && place.step === 'hidden' && <Search.Results />}
        </div>
    )
}

export const Deplacement = ({ children }) => {
    const place = ProContext.Place.usePlace()
    const { handleSaveAttribute } = ProContext.Profil.useProfilPro()
    const { step, setStep } = Address.useAddress()

    const saveAddress = (value) => {
        handleSaveAttribute('address', {
            formatted: value.formatted,
            lat: value.lat,
            lon: value.lon,
        })
        setStep('home')
    }

    return (
        <div className="h-full">
            {(step === 'home' || place.step !== 'hidden') && <div>{children}</div>}
            {step === 'loading' && place.step === 'hidden' && (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.H4 color={'v2_purple'} align={'center'}>
                        L'algorithme recherche les meilleures options pour vous
                    </Text.H4>
                </div>
            )}
            {step === 'address' && place.step === 'hidden' && (
                <Search.Search
                    launchResearch={() => {}}
                    noHome={!children}
                    setRequestInProgress={() => {}}
                    fromProfil={true}
                    setAddressSearched={(value) => saveAddress(value)}
                    goBackToProfile={() => {}}
                />
            )}
            {(step === 'results' || place.step !== 'hidden') && <div>{children}</div>}
        </div>
    )
}

export default Main
