import React, { useEffect, useState, useRef } from 'react'
import { Button, Input } from '../../atoms'
import { Address, Auth, ProContext } from '../../../../contexts'

import { ArrowLeft, CirclePlus, MapPin, Search } from 'lucide-react'

const Research = ({
    launchResearch,
    noHome,
    setRequestInProgress,
    instantLaunch,
    fromProfil,
    goBackToProfile,
    setAddressSearched,
    noAddressDefault,
}) => {
    const { type } = Auth.useAuth()
    const {
        selectedAddress,
        searchAddress,
        addressSuggestions,
        getLatLong,
        step,
        setStep,
        categories,
        setCategories,
        CATEGORIES,
    } = Address.useAddress()

    const [loading, setLoading] = useState(false)

    const [research, setResearch] = useState(false)
    const [searchValue, setSearchValue] = useState(selectedAddress)
    const inputRef = useRef(null)
    const [searchTimeout, setSearchTimeout] = useState(null)

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        if (searchValue?.length > 0 && !noAddressDefault) {
            handleSearchAddress(searchValue)
        }
    }, [step])

    const handleInputChange = (value) => {
        if (value.length > 2) {
            setLoading(true)
            setSearchValue(value)

            if (searchTimeout) clearTimeout(searchTimeout)

            const timeout = setTimeout(() => {
                handleSearchAddress(value)
            }, 250)

            setSearchTimeout(timeout)
            setLoading(false)
        }
    }

    return (
        <>
            {!research ? (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-4 items-center">
                        <div
                            onClick={() => {
                                setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                                if (fromProfil) {
                                    goBackToProfile()
                                }
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            {!research ? (
                                <p className="text-[20px] font-semibold text-v2_purple">Lieu de la prestation </p>
                            ) : (
                                <p className="text-[20px] font-semibold text-v2_purple">Rechercher</p>
                            )}
                        </div>
                    </div>
                    <div className="py-[18px] px-[12px]">
                        <div className="flex flex-row items-center p-4 border border-v2_purple rounded-full w-full shadow-lg shadow-violet-100">
                            {!searchValue && (
                                <div onClick={() => setResearch(true)}>
                                    <Search color="#72517E" size={24} />
                                </div>
                            )}
                            <div className="w-full px-2">
                                <input
                                    ref={inputRef}
                                    value={searchValue}
                                    placeholder={'Votre adresse'}
                                    className="w-full"
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setSearchValue(value.toString())
                                        handleInputChange(value.toString())
                                    }}
                                />
                            </div>
                            {searchValue && (
                                <div onClick={() => setSearchValue('')} className="rotate-45">
                                    <CirclePlus color="#000" size={20} />
                                </div>
                            )}
                        </div>
                    </div>
                    {addressSuggestions && addressSuggestions.length > 0 && (
                        <div className="bg-white shadow-modal h-logged overflow-auto">
                            {addressSuggestions.map((i, index) => (
                                <div key={index}>
                                    {i && (
                                        <div>
                                            <div
                                                className="flex flex-row items-center w-full px-4 py-3 border-b-[0.5px]"
                                                onClick={async () => {
                                                    if (!loading) {
                                                        setLoading(true)
                                                        setSearchValue(i.full_address)
                                                        setRequestInProgress(true)
                                                        const res = await getLatLong(i.autocompleteId, i.sessionId, i)
                                                        if (fromProfil) {
                                                            setAddressSearched(res)
                                                            goBackToProfile()
                                                        }

                                                        if (instantLaunch)
                                                            launchResearch({
                                                                newCoords: {
                                                                    lat: res.lat,
                                                                    lon: res.lon,
                                                                },
                                                            })
                                                        else setResearch(true)
                                                        setLoading(false)
                                                    }
                                                }}
                                            >
                                                <div className="mr-4 self-start">
                                                    <MapPin color="#72517E" size={24} />
                                                </div>
                                                <div className="grid gap-1">
                                                    {i.address && (
                                                        <p className="font-medium text-[16px]">{i.address}</p>
                                                    )}
                                                    {i.context?.place?.name && (
                                                        <p className="font-normal text-[14px] text-[#6C6C6C]">
                                                            {i.context.place.name}{' '}
                                                            {i.context?.region?.name || i.context?.country?.name || ''}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    {type !== 'coiffeur' && (
                        <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                            <div
                                onClick={() => {
                                    setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                                }}
                            >
                                <ArrowLeft size={24} color="#72517E" />
                            </div>
                            <div className="mr-auto ml-auto text-[20px] font-[500]">
                                {!research ? (
                                    <p className="text-[20px] font-semibold text-v2_purple">Lieu de la prestation </p>
                                ) : (
                                    <p className="text-[20px] font-semibold text-v2_purple">Rechercher</p>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="shadow-modal p-[40px] rounded-[30px]">
                        <Input.Edit
                            placeholder={'Où (Adresse, ville...)'}
                            onSearch={() => setResearch(false)}
                            value={searchValue}
                        />
                        {type !== 'coiffeur' && (
                            <div className="mt-6">
                                <div className="bg-[#fff] p-4 rounded-3xl">
                                    <p className="text-[16px] font-semibold text-v2_purple mt-4">Vous recherchez</p>
                                    <div className="mt-[16px]">
                                        {CATEGORIES?.map((categorie, index) => (
                                            <div key={index} className="flex flex-row mt-2">
                                                <div className="mr-2">
                                                    <input
                                                        type="checkbox"
                                                        className="accent-[#72517E]"
                                                        onChange={() => {
                                                            setCategories((prevCategories) => {
                                                                if (prevCategories.includes(categorie.value)) {
                                                                    return prevCategories.filter(
                                                                        (cat) => cat !== categorie.value
                                                                    )
                                                                } else {
                                                                    return [...prevCategories, categorie.value]
                                                                }
                                                            })
                                                        }}
                                                        checked={categories.includes(categorie.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="text-[16px] font-medium">{categorie.label}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="absolute left-0 right-0 w-full px-4 top-topBottomBar">
                        <Button.Primary
                            title={'Continuer'}
                            onClick={() => {
                                categories.length != 0 && launchResearch ? launchResearch() : {}
                            }}
                            disabled={categories.length === 0}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export const ResearchForPlaces = ({ launchResearch, noHome }) => {
    const { searchAddress, addressSuggestions, getLatLong, step, setStep, coordinates } = Address.useAddress()
    const { selected, setSelected } = ProContext.Place.usePlace()

    const [loading, setLoading] = useState(false)

    const [research, setResearch] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    useEffect(() => {
        if (selected?.name?.length >= 2) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }
    }, [selected])

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    useEffect(() => {
        if (selected.name) {
            setSearchValue(selected.address.formatted)
            setResearch(true)
        }
    }, [])

    return (
        <>
            {!research ? (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            <p className="text-[20px] font-semibold text-v2_purple">Adresse du lieu</p>
                        </div>
                    </div>
                    <div className="py-[18px] px-[12px]">
                        <div className="flex flex-row items-center p-4 border border-v2_purple rounded-full w-full shadow-lg shadow-violet-100">
                            {!searchValue && <Search color="#72517E" size={24} />}
                            <div className="w-full px-2">
                                <input
                                    value={searchValue}
                                    placeholder={'Votre adresse'}
                                    className="w-full"
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setSearchValue(value.toString())
                                        if (value.length > 2) {
                                            handleSearchAddress(value.toString())
                                        }
                                    }}
                                />
                            </div>
                            {searchValue && (
                                <div onClick={() => setSearchValue('')} className="rotate-45">
                                    <CirclePlus color="#000" size={20} />
                                </div>
                            )}
                        </div>
                    </div>
                    {addressSuggestions && addressSuggestions.length > 0 && (
                        <div className="bg-white shadow-modal h-logged overflow-auto">
                            {addressSuggestions.map((i, index) => (
                                <div key={index}>
                                    {i && (
                                        <div>
                                            <div
                                                className="flex flex-row items-center w-full px-4 py-3 border-b-[0.5px]"
                                                onClick={async () => {
                                                    if (!loading) {
                                                        setLoading(true)
                                                        setSearchValue(i.full_address)
                                                        await getLatLong(i.autocompleteId, i.sessionId, i)
                                                        setSelected({
                                                            _id: selected._id,
                                                            address: {
                                                                formatted: searchValue,
                                                                lat: coordinates.latitude,
                                                                lon: coordinates.longitude,
                                                            },
                                                        })
                                                        setResearch(true)
                                                        setLoading(false)
                                                    }
                                                }}
                                            >
                                                <div className="mr-4 self-start">
                                                    <MapPin color="#72517E" size={24} />
                                                </div>
                                                <div className="grid gap-1">
                                                    {i.address && (
                                                        <p className="font-medium text-[16px]">{i.address}</p>
                                                    )}
                                                    {i.context?.place?.name && (
                                                        <p className="font-normal text-[14px] text-[#6C6C6C]">
                                                            {i.context.place.name}{' '}
                                                            {i.context?.region?.name || i.context?.country?.name || ''}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                if (!loading) {
                                    setLoading(true)
                                    setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                                    setSearchValue('')
                                    setSelected({
                                        _id: null,
                                        address: null,
                                        name: '',
                                    })
                                    setLoading(false)
                                }
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            <p>Nom du lieu</p>
                        </div>
                    </div>
                    <div className="shadow-modal space-y-4 px-[13px] py-[22px] rounded-[30px]">
                        <Input.Edit
                            placeholder={'Où (Adresse, ville...)'}
                            onSearch={() => setResearch(false)}
                            value={searchValue}
                        />
                        <Input.Text
                            placeholder={'Nom du lieu pour les clients'}
                            onChange={(value) => setSelected({ ...selected, name: value })}
                            value={selected.name}
                            className="h-14"
                        />
                    </div>

                    <div className="absolute left-0 right-0 w-full px-4 top-topBottomBar">
                        <Button.Primary
                            title={'Continuer'}
                            onClick={() => {
                                selected?.name?.length < 2 ? null : launchResearch()
                            }}
                            disabled={isButtonDisabled}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Research
