import React, { createContext, useContext, useState, useEffect } from 'react'

import { Request } from '..'
import { socket } from '../../api/api'

/* eslint no-unused-vars: 0 */
const AddressContext = createContext({
    addressSuggestions: Array,
    professionalFound: Array,
    professionalFiltered: Array,
    searchAddress: async (searchValue, filter) => {
        return Promise.resolve()
    },
    searchCoiffeur: async (searchValue, filter) => {
        return Promise.resolve()
    },
    getLatLong: async (searchValue, filter) => {
        return Promise.resolve()
    },
    selectedAddress: String,
    coordinates: Object,
    step: String,
    setStep: (value) => {},
    setSelectedAddress: (value) => {},
    setCoordinates: (value) => {},
    categories: Array,
    setCategories: (value) => {},
    CATEGORIES: Array,
    hasToResearch: Boolean,
    setHasToResearch: (value) => {},
    filtersChoose: Array,
    setfiltersChoose: (value) => {},
})

export function AddressContextProvider({ children }) {
    const { handleRequestV2 } = Request.useRequest([])

    const steps = ['home', 'address', 'loading', 'results']
    const [step, setStep] = useState(steps[0])

    const [selectedAddress, setSelectedAddress] = useState('')
    const [addressSuggestions, setAddressSuggestions] = useState([])
    const [coordinates, setCoordinates] = useState()
    const [categories, setCategories] = useState([])
    const CATEGORIES = [
        { label: 'Coiffeur', value: 'coiffure' },
        { label: 'Esthéticienne', value: 'esthetique' },
        { label: 'Barber', value: 'barbier' },
        { label: 'Prothésiste ongulaire', value: 'ongulaire' },
    ]

    const [professionalFound, setProfessionnalFound] = useState([])
    const [professionalFiltered, setProfessionalFiltered] = useState([])

    const [hasToResearch, setHasToResearch] = useState(false)

    const [filtersChoose, setfiltersChoose] = useState([])

    useEffect(() => {
        setAddressSuggestions([])

        const storedCoordinates = sessionStorage.getItem('coordinates')
        if (storedCoordinates) setCoordinates(JSON.parse(storedCoordinates))

        const storedSelectedAddress = sessionStorage.getItem('selectedAddress')
        if (storedSelectedAddress) setSelectedAddress(storedSelectedAddress)

        const storedProfessionnalFound = sessionStorage.getItem('professionnalFound')
        if (storedProfessionnalFound) setProfessionnalFound(JSON.parse(storedProfessionnalFound))
    }, [])

    const searchAddress = async (searchValue) => {
        try {
            const response = await handleRequestV2('get', `map/autocomplete?search=${searchValue}`, null, null, true)

            if (response?.data?.searches) {
                const address = response.data.searches

                const suggestions = []
                address.map((autocomplete) => {
                    const suggestion = autocomplete.suggestions
                        .map((i) => {
                            if (i.full_address) {
                                return {
                                    ...i,
                                    autocompleteId: autocomplete._id,
                                    sessionId: response.data.sessionId,
                                }
                            }
                        })
                        .filter((suggestion) => suggestion !== undefined)

                    if (suggestion.length > 0) suggestions.push(...suggestion)
                })

                let uniqueSugestions = new Set()

                let returnSuggestions = suggestions.filter((item) => {
                    if (uniqueSugestions.has(item.full_address)) {
                        return false
                    } else {
                        uniqueSugestions.add(item.full_address)
                        return true
                    }
                })
                setAddressSuggestions(returnSuggestions)
            }
        } catch (error) {
            console.log('Erreur searchAddress', error.message)
        }
    }

    const getLatLong = async (id, sessionId, selected, noSetter) => {
        try {
            const response = await handleRequestV2(
                'put',
                `map/autocomplete/${id}`,
                { selected: selected, sessionId: sessionId },
                null,
                null,
                true
            )
            if (response?.data?.selected?.coordinates) {
                const coordinates = response.data.selected.coordinates
                const selectedAddress = response.data.selected.fullAddress

                if (!noSetter) {
                    setCoordinates(coordinates)
                    sessionStorage.setItem('coordinates', JSON.stringify(coordinates))
                    setSelectedAddress(selectedAddress)
                    sessionStorage.setItem('selectedAddress', selectedAddress)
                }

                return { formatted: selectedAddress, lat: coordinates.latitude, lon: coordinates.longitude }
            }
        } catch (error) {
            console.log('Erreur getAddressId', error.message)
        }
    }

    const searchCoiffeur = async (value) => {
        const lat = value?.newCoords?.lat ? value?.newCoords?.lat : coordinates.latitude
        const lon = value?.newCoords?.lon ? value?.newCoords?.lon : coordinates.longitude

        try {
            socket.on('new-result', (data) => {
                setProfessionnalFound(data)
                setStep('result')
                sessionStorage.setItem('professionalFound', JSON.stringify(data))
            })
            await handleRequestV2(
                'get',
                `map/pro?lat=${lat}&lon=${lon}&category=${categories.join(',')}&room=${socket.id}`,
                null,
                null,
                true
            )

            socket.off('new-result')
        } catch (error) {
            console.log('Erreur searchCoiffeur', error.message)
        }
    }

    const filterPro = (filters) => {
        if (filters.length > 0) {
            setProfessionalFiltered(
                professionalFound.filter((pro) => {
                    const set1 = new Set(pro._profil?._specialities.map((f) => f._id))

                    return filters.some((element) => set1.has(element._id))
                })
            )
        } else {
            setProfessionalFiltered([])
        }
    }

    return (
        <AddressContext.Provider
            value={{
                addressSuggestions: addressSuggestions,
                professionalFound: professionalFound,
                setAddressSuggestions: setAddressSuggestions,
                setProfessionnalFound: setProfessionnalFound,
                searchAddress: searchAddress,
                searchCoiffeur: searchCoiffeur,
                getLatLong: getLatLong,
                coordinates: coordinates,
                selectedAddress: selectedAddress,
                step: step,
                setStep: setStep,
                setSelectedAddress: setSelectedAddress,
                setCoordinates: setCoordinates,
                categories: categories,
                setCategories: setCategories,
                CATEGORIES: CATEGORIES,
                filterPro: filterPro,
                professionalFiltered: professionalFiltered,
                hasToResearch: hasToResearch,
                setHasToResearch: setHasToResearch,
                filtersChoose,
                setfiltersChoose,
            }}
        >
            {children}
        </AddressContext.Provider>
    )
}

export const useAddress = () => useContext(AddressContext)
