import React, { createContext, useContext, useState, useEffect } from 'react'

import { Auth, Request } from '../'

import { socket } from '../../api/api'

/* eslint no-unused-vars: 0 */
const NotificationsContext = createContext({
    notifications: Array,
    getNotifications: async () => {},
    readNotification: async () => {},
})

export function NotificationsContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token } = Auth.useAuth()

    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        socket.on('sendNotificationToPro', (data) => {})
    }, [socket])

    const getNotifications = async () => {
        try {
            const response = await handleRequest('get', `notifications`, null, token)

            const filteredNotifications = response?.data?.notifications.filter((notif) => notif.type !== 'move')

            setNotifications(filteredNotifications)
        } catch (error) {
            console.log('erreur getNotifications from NotificationsContext', error)
        }
    }

    const readNotification = async (values) => {
        try {
            const response = await handleRequest(
                'put',
                `notifications/${values.id}`,
                {
                    read: values.read,
                },
                token
            )

            if (response?.data?.notification) {
                setNotifications((prevNotifications) =>
                    prevNotifications.map((notification) =>
                        notification._id === response.data.notification._id
                            ? { ...notification, read: response.data.notification.read }
                            : notification
                    )
                )
            }
        } catch (error) {
            console.log('Erreur dans readNotification:', error)
        }
    }

    return (
        <NotificationsContext.Provider
            value={{
                notifications: notifications,
                getNotifications: getNotifications,
                readNotification: readNotification,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}

export const useNotifications = () => useContext(NotificationsContext)
