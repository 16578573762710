import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft, MapPin, Search, CirclePlus } from 'lucide-react'

import { Text, Input, Button, Cards, Loader } from '../../components/atoms'
import { Auth, Address, Request } from '../../../contexts'
import { isValidEmail } from '../../../utils'

// const GoogleLogin = ({ email, nextStep }) => {
//     const { getGoogleAuthLink } = Auth.useAuth()
//     const [tokens, setTokens] = useState(null)

//     useEffect(() => {
//         setTokens(localStorage.getItem('tokens'))
//     }, [])

//     const handleGoogleClick = () => {
//         if (!tokens) {
//             getGoogleAuthLink()
//         } else {
//             nextStep()
//         }
//     }

//     return (
//         <div
//             className="border border-[#000] rounded-full w-full flex justify-center py-[13px] items-center"
//             onClick={handleGoogleClick}
//         >
//             <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path
//                     d="M17.3759 9.17493C17.3759 8.52743 17.3223 8.05493 17.2063 7.56494H9.66162V10.4874H14.0902C14.0009 11.2137 13.5188 12.3074 12.4473 13.0424L12.4323 13.1402L14.8178 14.9513L14.983 14.9674C16.5009 13.5937 17.3759 11.5724 17.3759 9.17493"
//                     fill="#4285F4"
//                 />
//                 <path
//                     d="M9.66099 16.8752C11.8306 16.8752 13.652 16.1751 14.9824 14.9677L12.4467 13.0426C11.7681 13.5064 10.8574 13.8301 9.66099 13.8301C7.53601 13.8301 5.73246 12.4564 5.08954 10.5576L4.9953 10.5655L2.51486 12.4467L2.48242 12.5351C3.80383 15.1076 6.51811 16.8752 9.66099 16.8752Z"
//                     fill="#34A853"
//                 />
//                 <path
//                     d="M5.08998 10.5576C4.92034 10.0676 4.82216 9.54257 4.82216 9.0001C4.82216 8.45756 4.92034 7.93258 5.08106 7.44259L5.07656 7.33823L2.56503 5.42676L2.48285 5.46506C1.93824 6.53258 1.62573 7.73135 1.62573 9.0001C1.62573 10.2688 1.93824 11.4676 2.48285 12.5351L5.08998 10.5576"
//                     fill="#FBBC05"
//                 />
//                 <path
//                     d="M9.66103 4.16998C11.1699 4.16998 12.1878 4.80873 12.7682 5.34251L15.036 3.1725C13.6432 1.90375 11.8306 1.125 9.66103 1.125C6.51814 1.125 3.80384 2.89249 2.48242 5.46497L5.08063 7.44249C5.73248 5.54375 7.53604 4.16998 9.66103 4.16998"
//                     fill="#EB4335"
//                 />
//             </svg>
//             <p className="ml-2">{tokens ? email : 'Continuer avec Google'}</p>
//         </div>
//     )
// }

import dayjs from 'dayjs'
import { Modal } from '.'

const Signup = () => {
    const { handleSignup, handleSignupPro, setRedirectUrl, getGoogleProfile, isEmailTaken, setBackArrow } =
        Auth.useAuth()
    const { setMessage } = Request.useRequest()
    const { searchAddress, addressSuggestions, getLatLong, coordinates } = Address.useAddress()
    const navigate = useNavigate()

    const [step, setStep] = useState(0)
    const [mainStep, setMainStep] = useState('main')
    const [companyName, setCompanyName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [gender, setGender] = useState('female')
    const [birthday, setBirthday] = useState(dayjs())
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [type, setType] = useState('')
    const [siret, setSiret] = useState('')
    const [acceptedCGU, setAcceptedCGU] = useState(false)
    const [showErrorCGU, setShowErrorCGU] = useState(false)
    const [loading, setLoading] = useState(false)

    const submitSignUp = async () => {
        if (!acceptedCGU) {
            setShowErrorCGU(true)
        } else if (!loading) {
            setLoading(true)
            if (type === 'client') {
                await handleSignup(
                    firstName,
                    lastName,
                    email,
                    { formatted: address, lat: coordinates.latitude, lon: coordinates.longitude },
                    phoneNumber,
                    gender,
                    birthday,
                    password,
                    confirmPassword,
                    type
                )
            } else {
                await handleSignupPro(
                    companyName,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    gender,
                    birthday,
                    password,
                    confirmPassword,
                    type,
                    siret
                )
            }
            setLoading(false)
        }
    }

    const completeGoogleProfile = async () => {
        const tokens = localStorage.getItem('tokens')
        if (!tokens) {
            const code = new URLSearchParams(window.location.search).get('code')
            if (code) {
                const profile = await getGoogleProfile(code)
                setType(localStorage.getItem('type'))
                setFirstName(profile.firstName)
                setLastName(profile.lastName)
                setEmail(profile.email)
                setStep(2)
            }
        } else {
            const profile = await getGoogleProfile()
            setType(localStorage.getItem('type'))
            setFirstName(profile.firstName)
            setLastName(profile.lastName)
            setEmail(profile.email)
            setStep(2)
        }
    }

    useEffect(() => {
        const source = location.state?.source ? `/client${location.state.source}` : '/client'
        setRedirectUrl(source)
        completeGoogleProfile()
    }, [])

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    const isEmailAvailable = async (email) => {
        const res = await isEmailTaken(email)
        return res
    }

    useEffect(() => {
        if (step <= 1) {
            setBackArrow(true)
        } else setBackArrow(false)
    }, [step])

    const [openCguModal, setOpenCguModal] = useState(false)

    return (
        <div className="grid h-full gap-20">
            {mainStep === 'main' ? (
                <div>
                    {step > 1 && (
                        <div
                            className="fixed left-0 z-50 ml-5 top-4"
                            onClick={() => {
                                if (step === 1) {
                                    localStorage.clear()
                                }
                                setStep(step - 1)
                            }}
                        >
                            <ArrowLeft color="#72517E" size={25} />
                        </div>
                    )}
                    <div className="grid h-full gap-8">
                        {step === 0 && (
                            <div className="bg-white rounded-basic px-[13px] py-[22px] drop-shadow-lg mt-[100px] my-[33px] grid gap-[34px] h-fit">
                                <p className="font-semibold text-[20px] text-v2_purple text-center h-fit">Je suis</p>
                                <div className="w-full grid gap-[14px] h-fit">
                                    <Button.Secondary
                                        title="Particulier"
                                        onClick={() => {
                                            setType('client')
                                            localStorage.setItem('type', 'client')
                                            setStep(step + 1)
                                        }}
                                    />
                                    <Button.Primary
                                        title="Professionnel"
                                        onClick={() => {
                                            setType('coiffeur')
                                            localStorage.setItem('type', 'coiffeur')
                                            setStep(1)
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {step === 1 && type === 'coiffeur' ? (
                            <div className="bg-white rounded-basic mx-[33px] px-[13px] py-[22px] mt-[50px] drop-shadow-lg grid h-fit gap-[34px]">
                                <div className="grid gap-[16px]">
                                    <p className="font-bold text-[24px] text-center">Inscription</p>
                                    <div className="grid gap-1 px-[6.5px]">
                                        <p
                                            className="text-[20px] leading-[30px] tracking-[-0.8px] font-regular"
                                            color="#000"
                                        >
                                            Vous avez déja un compte ?
                                        </p>
                                        <div onClick={() => navigate('/login')}>
                                            <p className="text-lg font-semibold text-center underline text-v2_purple">
                                                Connexion
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid gap-[24px]">
                                    <div className="grid gap-[14px]">
                                        <Input.Text
                                            value={companyName}
                                            onChange={(value) => setCompanyName(value)}
                                            placeholder="Nom de l'entreprise"
                                        />
                                        <div className="grid grid-cols-2 gap-[13px] items-center">
                                            <Input.Text
                                                value={firstName}
                                                onChange={(value) => setFirstName(value)}
                                                placeholder="Prénom"
                                            />
                                            <Input.Text
                                                value={lastName}
                                                onChange={(value) => setLastName(value)}
                                                placeholder="Nom"
                                            />
                                        </div>
                                        <Input.Email
                                            value={email}
                                            onChange={(value) => setEmail(value.toLowerCase())}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <Button.Primary
                                        title="Continuer"
                                        onClick={async () => {
                                            if (companyName.length < 2) {
                                                setMessage({
                                                    type: 'warning',
                                                    message: "Nom de l'entreprise invalide",
                                                })
                                            } else if (firstName.length < 2) {
                                                setMessage({ type: 'warning', message: 'Prénom invalide' })
                                            } else if (lastName.length < 2) {
                                                setMessage({ type: 'warning', message: 'Nom invalide' })
                                            } else if (!isValidEmail(email)) {
                                                setMessage({ type: 'warning', message: 'Email invalide' })
                                            } else if (!(await isEmailAvailable(email))) {
                                                setMessage({ type: 'warning', message: 'Email déjà utilisé' })
                                            } else {
                                                setStep(2)
                                            }
                                        }}
                                    />
                                    {/* <div className="flex flex-row items-center">
                                        <div className="h-[2px] w-full bg-[#EDF1F3]"></div>
                                        <div className="mx-4">
                                            <Text.Small align="center" color="#979797">
                                                Ou
                                            </Text.Small>
                                        </div>
                                        <div className="h-[2px] w-full bg-[#EDF1F3]"></div>
                                    </div>
                                    <GoogleLogin email={email} nextStep={() => setStep(2)} /> */}
                                </div>
                            </div>
                        ) : step === 1 && type === 'client' ? (
                            <div className="bg-white rounded-basic mx-[33px] px-[13px] py-[22px] mt-[50px] drop-shadow-lg grid h-fit gap-[34px]">
                                <div className="grid gap-[16px]">
                                    <p className="font-bold text-[24px] text-center">Inscription</p>
                                    <div className="grid gap-1 px-[6.5px]">
                                        <p
                                            className="text-[20px] leading-[30px] tracking-[-0.8px] font-regular"
                                            color="#000"
                                        >
                                            Vous avez déja un compte ?
                                        </p>
                                        <div onClick={() => navigate('/login')}>
                                            <p className="text-lg font-semibold text-center underline text-v2_purple">
                                                Connexion
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid gap-[24px]">
                                    <div className="grid gap-[14px]">
                                        <div className="grid grid-cols-2 gap-[13px] items-center">
                                            <Input.Text
                                                value={firstName}
                                                onChange={(value) => setFirstName(value)}
                                                placeholder="Prénom"
                                            />
                                            <Input.Text
                                                value={lastName}
                                                onChange={(value) => setLastName(value)}
                                                placeholder="Nom"
                                            />
                                        </div>
                                        <Input.Email
                                            value={email}
                                            onChange={(value) => setEmail(value.toLowerCase())}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <Button.Primary
                                        title="Continuer"
                                        onClick={async () => {
                                            if (firstName.length < 2) {
                                                setMessage({ type: 'warning', message: 'Prénom invalide' })
                                            } else if (lastName.length < 2) {
                                                setMessage({ type: 'warning', message: 'Nom invalide' })
                                            } else if (!isValidEmail(email)) {
                                                setMessage({ type: 'warning', message: 'Email invalide' })
                                            } else if (!(await isEmailAvailable(email))) {
                                                setMessage({ type: 'warning', message: 'Email déjà utilisé' })
                                            } else {
                                                setStep(2)
                                            }
                                        }}
                                    />
                                    {/* <div className="flex flex-row items-center">
                                        <div className="h-[2px] w-full bg-[#EDF1F3]"></div>
                                        <div className="mx-4">
                                            <Text.Small align="center" color="#979797">
                                                Ou
                                            </Text.Small>
                                        </div>
                                        <div className="h-[2px] w-full bg-[#EDF1F3]"></div>
                                    </div>
                                    <GoogleLogin email={email} nextStep={() => setStep(2)} /> */}
                                </div>
                            </div>
                        ) : null}
                        {step === 2 && type === 'coiffeur' ? (
                            <div className="px-4">
                                <div className="px-3 py-5 bg-white rounded-basic drop-shadow-lg">
                                    <div className="my-4">
                                        <Input.PhoneNumber
                                            value={phoneNumber}
                                            onChange={(value) => {
                                                const sanitizedValue = value.replace(/[a-zA-Z\s]|\+33/g, '')
                                                setPhoneNumber(sanitizedValue)
                                            }}
                                            placeholder="Numéro de téléphone"
                                        />
                                    </div>
                                    <Button.Primary
                                        title="Continuer"
                                        onClick={() => {
                                            if (phoneNumber.length !== 10) {
                                                setMessage({
                                                    type: 'warning',
                                                    message:
                                                        'Téléphone invalide, veuillez rentrer votre numéro de téléphone au format 0606060606',
                                                })
                                            } else setStep(3)
                                        }}
                                    />
                                </div>
                            </div>
                        ) : step === 2 && type === 'client' ? (
                            <div className="px-4">
                                <div className="px-3 py-5 bg-white rounded-basic drop-shadow-lg">
                                    <div onClick={() => setMainStep('address')}>
                                        <div className="p-4 border rounded-full bg-v2_white border-v2_purple">
                                            <input
                                                value={address}
                                                className="w-full"
                                                type="text"
                                                placeholder="Entrez votre adresse"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <Input.PhoneNumber
                                            value={phoneNumber}
                                            onChange={(value) => setPhoneNumber(value)}
                                            placeholder="Numéro de téléphone"
                                        />
                                    </div>
                                    <Button.Primary
                                        title="Continuer"
                                        onClick={() => {
                                            if (!coordinates.latitude || !coordinates.longitude) {
                                                setMessage({ type: 'warning', message: 'Adresse invalide' })
                                            } else if (phoneNumber.length !== 10) {
                                                setMessage({
                                                    type: 'warning',
                                                    message:
                                                        'Téléphone invalide, veuillez rentrer votre numéro de téléphone au format 0606060606',
                                                })
                                            } else setStep(3)
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {step === 3 && (
                            <div className="px-4">
                                <div className="px-3 py-5 bg-white rounded-basic drop-shadow-lg">
                                    <Cards.SearchFilter
                                        categories={[
                                            { label: 'Femme', value: 'female' },
                                            { label: 'Homme', value: 'male' },
                                            { label: 'Autres', value: 'other' },
                                        ]}
                                        label="Choisissez votre genre"
                                        onClick={(value) => setGender(value)}
                                        value={gender}
                                    />
                                    <Button.Primary title="Continuer" onClick={() => setStep(4)} />
                                </div>
                            </div>
                        )}
                        {step === 4 && (
                            <div className="px-4">
                                <div className="px-3 py-5 bg-white rounded-basic drop-shadow-lg">
                                    <p className="text-[20px] font-medium text-center mb-4">Votre date de naissance</p>
                                    <Input.Date setValue={(value) => setBirthday(value)} value={birthday} />
                                </div>
                                <div className="mt-6">
                                    <Button.Primary title="Continuer" disabled={!birthday} onClick={() => setStep(5)} />
                                </div>
                            </div>
                        )}
                        {step === 5 && (
                            <div className="px-4">
                                <div className="px-3 py-5 bg-white rounded-basic drop-shadow-lg">
                                    <Input.Password
                                        label="Choisissez un mot de passe"
                                        onChange={(value) => setPassword(value)}
                                        placeholder="Mot de passe"
                                    />
                                    <div className="my-2">
                                        <Input.Password
                                            label="Confirmez le mot de passe"
                                            placeholder="Mot de passe"
                                            onChange={(value) => setConfirmPassword(value)}
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-center my-4">
                                        <input
                                            type="checkbox"
                                            className="accent-v2_purple"
                                            onClick={() => setAcceptedCGU(!acceptedCGU)}
                                            checked={acceptedCGU}
                                        />
                                        <div className="mx-2">
                                            <Text.Small color="#000">Cochez pour accepter les</Text.Small>
                                        </div>
                                        <div onClick={() => setOpenCguModal(!openCguModal)}>
                                            <Text.Small className="font-medium underline">CGU/CGV</Text.Small>
                                        </div>
                                    </div>
                                    <Modal.CGU
                                        isOpen={openCguModal}
                                        type={type}
                                        setIsOpen={() => setOpenCguModal(!openCguModal)}
                                    />
                                    {showErrorCGU && (
                                        <div className="my-4">
                                            <Text.Small
                                                align="center"
                                                color="#FC4F4F"
                                                className="font-medium text-[14px]"
                                            >
                                                Merci d'accepter les CGU pour vous inscrire.
                                            </Text.Small>
                                        </div>
                                    )}
                                    <Button.Primary
                                        title="Continuer"
                                        onClick={() => {
                                            if (!acceptedCGU) {
                                                setShowErrorCGU(true)
                                            } else if (type === 'coiffeur') {
                                                if (password.length < 2) {
                                                    setMessage({
                                                        type: 'warning',
                                                        message: 'Veuillez renseigner un mot de passe',
                                                    })
                                                } else if (password !== confirmPassword) {
                                                    setMessage({
                                                        type: 'warning',
                                                        message: 'Les deux mots de passe ne sont pas identiques',
                                                    })
                                                } else setStep(6)
                                            } else {
                                                submitSignUp()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {step === 6 && (
                            <div className="px-4">
                                <div className={`px-4 py-6 ${loading ? 'bg-white rounded-basic drop-shadow-lg' : ''}`}>
                                    {!loading ? (
                                        <>
                                            <Input.Text
                                                value={siret}
                                                onChange={(value) => setSiret(value)}
                                                placeholder="SIRET"
                                            />
                                            <div className="my-4">
                                                <Button.Primary title="Continuer" onClick={submitSignUp} />
                                            </div>
                                            <Button.Secondary title="Ajouter plus tard" onClick={submitSignUp} />
                                        </>
                                    ) : (
                                        <>
                                            <Loader.Primary />
                                            <Text.H4>Création de votre compte</Text.H4>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="absolute top-0 z-[99] bg-white w-full left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                setMainStep('main')
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            <p className="text-[20px] font-semibold text-v2_purple">Rechercher</p>
                        </div>
                    </div>
                    <div className="py-[18px] px-[12px]">
                        <div className="flex flex-row items-center p-4 border border-v2_purple rounded-full w-full shadow-lg shadow-violet-100">
                            {!address && (
                                <div>
                                    <Search color="#72517E" size={24} />
                                </div>
                            )}
                            <div className="w-full px-2">
                                <input
                                    value={address}
                                    placeholder={'Votre adresse'}
                                    className="w-full"
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setAddress(value.toString())
                                        if (value.length > 2) {
                                            handleSearchAddress(value.toString())
                                        }
                                    }}
                                />
                            </div>
                            {address && (
                                <div onClick={() => setAddress('')} className="rotate-45">
                                    <CirclePlus color="#000" size={20} />
                                </div>
                            )}
                        </div>
                    </div>
                    {addressSuggestions && addressSuggestions.length > 0 && (
                        <div className="bg-white shadow-modal h-logged overflow-auto">
                            {addressSuggestions.map((i, index) => (
                                <div key={index}>
                                    {i && (
                                        <div>
                                            <div
                                                className="flex flex-row items-center w-full px-4 py-3 border-b-[0.5px]"
                                                onClick={async () => {
                                                    if (!loading) {
                                                        setLoading(true)
                                                        setAddress(i.full_address)
                                                        setMainStep('main')
                                                        await getLatLong(i.autocompleteId, i.sessionId, i)
                                                        setLoading(false)
                                                    }
                                                }}
                                            >
                                                <div className="mr-4 self-start">
                                                    <MapPin color="#72517E" size={24} />
                                                </div>
                                                <div className="grid gap-1">
                                                    {i.address && (
                                                        <p className="font-medium text-[16px]">{i.address}</p>
                                                    )}
                                                    {i.context?.place?.name && (
                                                        <p className="font-normal text-[14px] text-[#6C6C6C]">
                                                            {i.context.place.name}{' '}
                                                            {i.context?.region?.name || i.context?.country?.name || ''}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Signup
