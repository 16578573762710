import React from 'react'
import { Button, Input } from '.'
import { ProContext } from '../../../contexts'
import { ImportContactBtn } from './ImportContactButton'

import { SlidersHorizontal } from 'lucide-react'

const ClientDirectory = ({ showFilterStep }) => {
    const { searchClient, setClientSelected } = ProContext.Directory.useDirectory()

    return (
        <div className="mx-4">
            <div className="w-full flex flex-row justify-between items-center my-2">
                <div className="size-[24px]"></div>
                <p className="font-semibold text-[20px] my-5 text-center">Répertoire clients</p>
                <SlidersHorizontal size={24} color="#000" onClick={() => showFilterStep()} />
            </div>
            <div className="grid gap-3">
                <Input.Search
                    placeholder={'Rechercher'}
                    onSearch={(value) => {
                        searchClient(value, 1)
                    }}
                />
                <Button.Primary title={'Créer un client'} onClick={() => setClientSelected({})} />
                <ImportContactBtn />
            </div>
        </div>
    )
}

export { ClientDirectory }
