import React from 'react'

import { Button } from '@nextui-org/react'
import { Text } from '.'
import { Plus } from 'lucide-react'

export const Primary = ({ title, onClick, disabled, iconRight, loading }) => {
    return (
        <div
            className={`flex justify-center items-center bg-v2_lightpurple p-[12px] rounded-full w-full ${
                disabled ? 'cursor-not-allowed opacity-50 bg-disabled-100 text-disabled-600' : 'cursor-pointer'
            }`}
            onClick={!disabled ? onClick : undefined}
        >
            <p className="text-center text-v2_purple text-[16px] font-medium">{title}</p>
            {iconRight && <div className="ml-2">{iconRight}</div>}
            {loading && (
                <div className="w-4 h-4 ml-6 border-2 rounded-full animate-spin border-b-v2_purple border-l-v2_purple"></div>
            )}
        </div>
    )
}

export const PrimaryLoader = ({ title, onClick, disabled, iconRight, loading }) => {
    return (
        <div
            className={`flex items-center bg-v2_lightpurple p-[12px] rounded-full w-full ${
                disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'
            }`}
            onClick={!disabled && !loading ? onClick : undefined}
        >
            {loading ? (
                <div className="flex items-center justify-center w-full">
                    <div className="w-6 h-6 border-t-2 border-opacity-50 rounded-full animate-spin border-v2_purple"></div>
                </div>
            ) : (
                <>
                    <p className="text-center text-v2_purple text-[16px] font-medium flex-1">{title}</p>
                    {iconRight && <div className="ml-2">{iconRight}</div>}
                </>
            )}
        </div>
    )
}

export const Secondary = ({ title, onClick, iconRight, iconLeft }) => {
    return (
        <div
            className="flex justify-center items-center p-[8px] rounded-full border-1 bg-v2_white border-v2_purple border-[1px] w-full"
            onClick={() => onClick()}
        >
            {iconLeft && <div className="mr-2">{iconLeft}</div>}
            <p className="text-center text-v2_purple text-[16px] font-medium">{title}</p>
            {iconRight && <div className="ml-2">{iconRight}</div>}
        </div>
    )
}

export const Next = ({ title, onClick }) => {
    return (
        <div className="inline-block p-4 rounded-full bg-v2_purple" onClick={() => onClick()}>
            <p className="text-center text-v2_white text-[14px] font-medium">{title}</p>
        </div>
    )
}

export const Link = ({ title, onClick, iconRight, iconLeft, className = '' }) => {
    return (
        <div
            className={`${className} flex justify-center items-center cursor-pointer w-full`}
            onClick={() => onClick()}
        >
            {iconLeft && <div className="mr-4">{iconLeft}</div>}
            <p className="text-center text-v2 text-[16px] font-medium">{title}</p>
            {iconRight && <div className="ml-2">{iconRight}</div>}
        </div>
    )
}

export const Tag = ({ onClick, active, children }) => {
    return (
        <Button
            className={`w-full group flex items-center px-5 py-3 bg-light-gray rounded border-[0.5px] border-black hover:bg-white focus:px-8 disabled:opacity-60 disabled:bg-black disabled:text-white active:text-opacity-80 transition-all ${
                active ? 'border-2 bg-white' : ''
            }`}
            onClick={onClick ? onClick : () => {}}
        >
            <Text.Clickable className={`${children ? '' : 'hidden'}`}>{children}</Text.Clickable>
        </Button>
    )
}

export const AddToCalendar = ({ onClick }) => {
    return (
        <div
            onClick={() => onClick()}
            className="absolute bottom-[40px] right-5 size-20 bg-white rounded-full flex flex-row justify-center items-center shadow-lg shadow-v2_lightpurple"
        >
            <Plus size={24} color="#72517E" />
        </div>
    )
}

export const Red = ({ iconLeft, iconRight, onClick, disabled, children }) => {
    return (
        <Button
            className={`group flex items-center max-[350px]:px-3 px-5 py-3 bg-pink w-fit rounded-[50px] border-[1.5px] border-black hover:bg-black hover:text-white focus:px-8 disabled:opacity-60 disabled:bg-black disabled:text-white active:text-white active:text-opacity-80 transition-all ${
                children && (iconLeft || iconRight) ? 'gap-3' : 'px-4'
            }`}
            onClick={onClick ? onClick : () => {}}
            disabled={disabled}
        >
            {iconLeft && (
                <div className="w-5 text-black transition-all group-hover:text-white group-active:text-white group-active:opacity-80 group-disabled:text-white group-disabled:opacity-80">
                    {iconLeft}
                </div>
            )}
            <Text.Clickable
                className={`${children ? '' : 'hidden'} ${iconLeft ? 'mr-1' : ''} ${iconRight ? 'ml-1' : ''}`}
            >
                {children}
            </Text.Clickable>
            {iconRight && (
                <div className="w-5 text-black transition-all group-hover:text-white group-active:text-white group-active:opacity-80 group-disabled:text-white group-disabled:opacity-80">
                    {iconRight}
                </div>
            )}
        </Button>
    )
}

export const Gray = ({ title, onClick }) => {
    return (
        <div
            className={`flex justify-center items-center bg-[#fff] p-[12px] rounded-full w-full border border-[#979797]`}
            onClick={() => onClick && onClick()}
        >
            <p className="text-center text-[#979797] text-[16px] font-medium">{title}</p>
        </div>
    )
}
