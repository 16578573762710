exports.formatSecondsToHHhmm = (seconds) => {
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds % 3600) / 60)

    var formattedTime = hours.toString().padStart(2, '0') + 'h' + minutes.toString().padStart(2, '0')

    return formattedTime
}

exports.formatMinutesToHHhmm = (minutes) => {
    var hours = Math.floor(minutes / 60)
    var remainingMinutes = minutes % 60

    var formattedTime =
        (hours > 9 ? hours : hours.toString().padStart(1, '')) + 'h' + remainingMinutes.toString().padStart(2, '0')

    return formattedTime
}

exports.formatMillisecondsToMinutes = (milliseconds) => {
    var minutes = milliseconds / (1000 * 60)

    return minutes
}

exports.formatHHmmToMilliseconds = (hhmm) => {
    var hours = hhmm.split(':')[0]
    var minutes = hhmm.split(':')[1]

    return (hours * 3600 + minutes * 60) * 1000
}

exports.formatHHmmToSeconds = (hhmm) => {
    const [hours, minutes] = hhmm.split(':').map(Number)
    return hours * 3600 + minutes * 60
}

exports.formatSecondsToInput = (seconds) => {
    var hours = Math.floor(seconds / 3600)
    var minutes = Math.floor((seconds % 3600) / 60)

    var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0')

    return formattedTime
}

exports.prestationObjectsToArray = (prestationObjects) => {
    const prestations = []

    prestationObjects.forEach((prestationObject) => {
        if (prestationObject.quantity > 0) {
            for (let i = 0; i < prestationObject.quantity; i++) {
                prestations.push(prestationObject._id)
            }
        }
    })

    return prestations
}

exports.arraysEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false
    }
    return true
}

exports.isValidEmail = (email) => {
    // Define the regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Test the email against the regular expression
    return emailRegex.test(email)
}

exports.capitalizeFirstLetter = (sentence) => {
    if (!sentence) return sentence // Handle empty strings

    return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

exports.renderNamePro = (profil) => {
    if (profil?.companyName) return profil?.companyName
    else return `${profil?.firstName} ${profil?.lastName}`
}

exports.generateRoomUID = () => {
    return 'room-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9)
}

exports.formatPhoneNumberForLink = (phoneNumber) => {
    if (phoneNumber) {
        // Supprimer tous les espaces, tirets ou autres caractères non numériques sauf "+"
        let cleanedNumber = phoneNumber.replace(/[\s-]/g, '');

        // Vérifier si le numéro commence par "00" ou "+"
        if (cleanedNumber.startsWith('00')) {
            cleanedNumber = '+' + cleanedNumber.slice(2);
        } else if (!cleanedNumber.startsWith('+')) {
            // Si le numéro ne commence pas par "+", on ajoute le préfixe France "+33"
            if (cleanedNumber.startsWith('0')) {
                cleanedNumber = '+33' + cleanedNumber.slice(1);
            } else {
                // Si le numéro ne commence pas par 0, on suppose que c'est un numéro court français
                cleanedNumber = '+33' + cleanedNumber;
            }
        }

        // S'assurer que le numéro est au format attendu "+336xxxxxxx" pour les mobiles
        if (cleanedNumber.startsWith('+33') && cleanedNumber[3] === '0') {
            cleanedNumber = '+33' + cleanedNumber.slice(4);
        }

        return cleanedNumber;
    } else {
        return "";
    }
}
