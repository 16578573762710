import React, { useState } from 'react'

import { Button } from '../../atoms'
import { Pro as ProMolecules } from '../../molecules'
import { ProContext } from '../../../../contexts'

const ChooseCategories = ({ increaseStep }) => {
    const { category, yearsExperience, description } = ProContext.Profil.useProfilPro()

    const isButtonDisabled =
        !category?.length > 0 || !typeof yearsExperience === 'number' || !description[0]?.length > 0

    return (
        <div>
            <ProMolecules.CompleteProfil.CategoriesForm />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} disabled={isButtonDisabled} />
        </div>
    )
}

const ChooseSpecialities = ({ increaseStep }) => {
    const { address, specialities, rayon } = ProContext.Profil.useProfilPro()
    const [rayonLocal, setRayonLocal] = useState(rayon)

    const isButtonDisabled = !specialities.length > 0 || !address.formatted || rayonLocal <= 0

    return (
        <div>
            <ProMolecules.CompleteProfil.SpecialitiesForm />
            <ProMolecules.CompleteProfil.AddressForm onChange={(value) => setRayonLocal(value)} />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} disabled={isButtonDisabled} />
        </div>
    )
}

const ChooseTiming = ({ increaseStep }) => {
    return (
        <div>
            <ProMolecules.CompleteProfil.TimingForm />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} />
        </div>
    )
}

const ChoosePictures = ({ increaseStep }) => {
    return (
        <div>
            <ProMolecules.CompleteProfil.CompanyLogo />
            <ProMolecules.CompleteProfil.Galery />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} />
        </div>
    )
}

const PaymentMethode = ({ increaseStep }) => {
    return (
        <div className="mx-2">
            <ProMolecules.CompleteProfil.PaymentMethod />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} />
        </div>
    )
}

const ProfileActivation = ({ increaseStep }) => {
    return (
        <div className="mx-2">
            <ProMolecules.CompleteProfil.ActivationProfile />
            <Button.Primary title={'Continuer'} onClick={() => increaseStep()} />
        </div>
    )
}

export { ChooseCategories, ChooseSpecialities, ChooseTiming, ChoosePictures, PaymentMethode, ProfileActivation }
