import React, { useEffect, useState } from 'react'
import {
    CalendarCheck2,
    MapPin,
    CirclePlus,
    Phone,
    MessageCircle,
    Hourglass,
    ArrowLeft,
    X,
    ChevronDown,
    ChevronUp,
} from 'lucide-react'
import dayjs from 'dayjs'

import { Button, Text, Input } from '../../../atoms'
import { UserChoices, Pro } from '../../../molecules'
import { Profesionnal } from '../../../organisms'
import ppBoy from '../../../../../assets/avatar/Boy.svg'

import { ProContext } from '../../../../../contexts'
import {
    formatHHmmToSeconds,
    formatSecondsToHHhmm,
    formatSecondsToInput,
    formatPhoneNumberForLink,
} from '../../../../../utils'

const Screen = () => {
    const {
        bookingSelected,
        acceptBooking,
        selectBooking,
        modal,
        setModal,
        cancelBooking,
        moveBooking,
        refreshBookingSelected,
    } = ProContext.Bookings.useBooking()
    const { clients, clientSelected, setClientSelected, settingClientSelected } = ProContext.Directory.useDirectory()
    const { setCurrentDay } = ProContext.Agenda.useDay()
    const { setDaySelected } = ProContext.Agenda.useView()
    const { refreshWeek } = ProContext.Agenda.useWeek()

    const [loading, setLoading] = useState(false)

    const [reason, setReason] = useState('')

    const [newDate, setNewDate] = useState(bookingSelected.date)
    const [newFrom, setNewFrom] = useState(bookingSelected.from)

    const [clientNotes, setClientNotes] = useState([])
    const [expandedNotes, setExpandedNotes] = useState({})

    const stateIcons = {
        booked: <CalendarCheck2 color="#269B13" size={24} />,
        waiting: <CalendarCheck2 color="#F5E027" size={24} />,
        cancel: (
            <div className="rotate-45">
                <CirclePlus color="#FC4F4F" size={24} />
            </div>
        ),
        moved: <CalendarCheck2 color="#FB9639" size={24} />,
        done: <CalendarCheck2 color="#979797" size={24} />,
    }

    const stateTexts = {
        booked: 'Confirmé',
        waiting: 'En attente de confirmation',
        cancel: `RDV annulé par ${bookingSelected.canceledBy === 'pro' ? 'vous' : 'le client'}`,
        moved: 'RDV Décalé par vous',
        done: 'RDV passé',
    }

    const renderServices = () => {
        const textColor =
            bookingSelected.state === 'cancel' || bookingSelected.state === 'done' ? 'text-[#979797]' : 'text-[#000]'

        return bookingSelected._prestation.map((service, index) => (
            <div className="flex flex-row items-center w-full" key={index}>
                <div className="size-[8px] bg-[#000] mr-2 rounded-full"></div>
                <p className={`${textColor} text-[16px]`}>{service.name}</p>
            </div>
        ))
    }

    const handleAcceptBooking = async () => {
        await acceptBooking(bookingSelected._id, bookingSelected.date)
    }

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) container.scrollTo(0, 0)

        if (bookingSelected._id) {
            refreshBookingSelected(bookingSelected._id)
        }

        return () => {
            settingClientSelected()
        }
    }, [])

    const handleReturn = () => {
        if (!modal) selectBooking()
        else setModal()
    }

    const findClient = (client) => {
        const clientFound = clients?.filter((c) => c._user?._id === client?._id)

        if (clientFound?.length !== 0) {
            setClientSelected(clientFound[0])
        }
    }

    const toggleExpand = (id) => {
        setExpandedNotes((prev) => ({
            ...prev,
            [id]: !prev[id],
        }))
    }

    useEffect(() => {
        if (bookingSelected) {
            setNewDate(bookingSelected.date)
            setNewFrom(bookingSelected.from)
            findClient(bookingSelected._client)
            setClientNotes(bookingSelected.note?.filter((i) => i.from === 'client'))
        }
    }, [bookingSelected])

    const located = window.location.pathname
    console.log(bookingSelected)

    return (
        <div className="relative">
            <div
                onClick={() => {
                    handleReturn()
                }}
            >
                {located != '/pro/clients' ? (
                    <div>
                        {modal !== 'move' ? (
                            <Text.Normal className="flex items-center font-medium underline gap-x-2 text-v2_purple px-[4%]">
                                <ArrowLeft color="#72517E" size={24} />
                                Retour
                            </Text.Normal>
                        ) : (
                            <div className="flex justify-end w-full px-4">
                                <X size={30} color="#72517E" />
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
            {!bookingSelected.isPrivate ? (
                <>
                    {clientSelected && !modal && <Profesionnal.Directory.Notes />}
                    {!modal && (
                        <div className="px-[4%] pb-4">
                            <div className="py-[17px]">
                                <div className="flex flex-row items-center">
                                    {stateIcons[bookingSelected.state]}
                                    <p className="ml-2 text-[16px] font-semibold leading-[20px]">
                                        {stateTexts[bookingSelected.state]}
                                    </p>
                                </div>
                                {bookingSelected.state === 'moved' && (
                                    <p className="text-[14px] font-normal ml-[32px]">
                                        En attente de confirmation du client
                                    </p>
                                )}
                            </div>
                            <div className="grid rounded-[30px] bg-white shadow-basic overflow-hidden">
                                <div className="grid gap-[10px] bg-white shadow-basic pb-[28px] pt-[21px] rounded-t-[30px]">
                                    <div className="overflow-hidden rounded-full size-[55px] mx-auto">
                                        {bookingSelected?._client?._profil?.avatar ? (
                                            <img
                                                src={bookingSelected?._client?._profil?.avatar?.thumbnailUrl}
                                                alt="Photo de profil du Client"
                                            />
                                        ) : (
                                            <img src={ppBoy} alt="Photo de profil par défault du Client" />
                                        )}
                                    </div>
                                    <p className="text-[20px] leading-[32px] font-semibold text-center">
                                        {bookingSelected?._client?._profil?.firstName}{' '}
                                        {bookingSelected?._client?._profil?.lastName}
                                    </p>
                                    <div className="mx-auto flex space-x-[60px] w-fit">
                                        <a
                                            href={`tel:${formatPhoneNumberForLink(
                                                bookingSelected?._client?._profil?.phoneNumber?.phone
                                            )}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Phone size={26} color="#72517E" />
                                        </a>
                                        <a
                                            href={`sms:${formatPhoneNumberForLink(
                                                bookingSelected?._client?._profil?.phoneNumber?.phone
                                            )}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MessageCircle size={26} color="#72517E" />
                                        </a>
                                    </div>
                                </div>

                                <div className="py-[7px] ">
                                    {bookingSelected.state === 'waiting' ? (
                                        <UserChoices.Waiting
                                            bookingSelected={bookingSelected}
                                            handleConfirm={() => {
                                                handleAcceptBooking()
                                            }}
                                        />
                                    ) : bookingSelected.state === 'booked' ? (
                                        <UserChoices.Confirm bookingSelected={bookingSelected} />
                                    ) : null}
                                </div>
                                <div className="my-[20px]">
                                    <Pro.Booking.BookingTimingInformation booking={bookingSelected} />
                                </div>

                                <div className="flex space-x-2 pt-[24px] ml-[14px] bg-white items-center">
                                    <MapPin />
                                    <p className="text-[14px] leading-[20px]">
                                        {bookingSelected.place && (
                                            <span className={'font-medium'}>{bookingSelected.place.name} : </span>
                                        )}
                                        {bookingSelected.address?.formatted || 'Non défini'}
                                    </p>
                                </div>

                                <div className="grid gap-[16px] mt-[17px] ml-[18px]">
                                    <p className="text-[16px] font-semibold text-v2_purple uppercase">Prestations</p>
                                    <div className="flex flex-col items-center w-full space-y-[20px]">
                                        {renderServices()}
                                    </div>
                                </div>

                                <div className="flex space-x-[12px] mt-[28px] items-center ml-[14px]">
                                    <Hourglass size={24} />
                                    <div className="flex space-x-[9px]">
                                        <p className="uppercase text-v2_purple text-[16px] leading-[20px] font-semibold">
                                            Durée
                                        </p>
                                        <p className="text-[16px] leading-[20px]">
                                            {formatSecondsToHHhmm(bookingSelected.duration)}
                                        </p>
                                    </div>
                                </div>
                                <p
                                    className={`font-medium text-[18px] text-center mt-[28px] mb-[30px] ${
                                        bookingSelected.state === 'cancel' || bookingSelected.state === 'done'
                                            ? 'text-[#979797]'
                                            : 'text-[#000]'
                                    }`}
                                >
                                    Coût total : {bookingSelected.price} €
                                </p>

                                {clientNotes && clientNotes.length > 0 ? (
                                    <div className="grid gap-[16px] mt-[17px] ml-[18px]">
                                        <p className="text-[16px] font-semibold text-v2_purple uppercase">
                                            Note du client
                                        </p>
                                        {clientNotes.map((i) => {
                                            const isExpanded = expandedNotes[i._id]

                                            return (
                                                <div
                                                    key={i._id}
                                                    className="grid gap-[10px] bg-white shadow-basic pb-[28px] pt-[21px] rounded-t-[30px]"
                                                >
                                                    <div className="flex flex-row items-center">
                                                        {bookingSelected?._client?._profil?.avatar ? (
                                                            <img
                                                                className="mr-2 size-6"
                                                                src={
                                                                    bookingSelected?._client?._profil?.avatar
                                                                        ?.thumbnailUrl
                                                                }
                                                                alt="Photo de profil du Client"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="mr-2 size-6"
                                                                src={ppBoy}
                                                                alt="Photo de profil par défault du Client"
                                                            />
                                                        )}
                                                        <p className="text-[16px] leading-[20px] font-semibold">
                                                            Client : {bookingSelected?._client?._profil?.firstName}{' '}
                                                            {bookingSelected?._client?._profil?.lastName}
                                                        </p>
                                                    </div>
                                                    <p className="text-[14px] leading-[20px]">
                                                        {isExpanded ? i.value : `${i.value.slice(0, 50)}...`}
                                                    </p>
                                                    <div
                                                        className="flex flex-row items-center"
                                                        onClick={() => toggleExpand(i._id)}
                                                    >
                                                        <p className="text-[14px] leading-[20px] cursor-pointer text-v2_purple underline font-semibold mr-2">
                                                            {isExpanded ? 'Voir moins' : 'Voir plus'}
                                                        </p>
                                                        {isExpanded ? (
                                                            <ChevronUp size={20} color="#72517e" />
                                                        ) : (
                                                            <ChevronDown size={20} color="#72517e" />
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    )}
                    {modal === 'cancel' && (
                        <div className="grid gap-[34px] pt-[24px]">
                            <div className="grid gap-[10px]">
                                <p className="text-[20px] leading-[30px] font-medium text-center px-[10%]">
                                    Êtes-vous sûr(e) de vouloir annuler le rendez-vous ?
                                </p>
                                <a
                                    href={`tel:+33${parseInt(bookingSelected._client?._profil?.phoneNumber?.phone)}`}
                                    className="px-[9%]"
                                >
                                    <Button.Secondary
                                        title="Contacter votre client"
                                        onClick={() => {}}
                                        iconLeft={<Phone color="#72517E" />}
                                    />
                                </a>
                            </div>
                            <div className="grid gap-[24px] px-[9%]">
                                <Input.TextArea
                                    label="Motif d'annulation (optionnel)"
                                    value={reason}
                                    onChange={(value) => setReason(value)}
                                    placeholder="Vous pouvez dire pourquoi vous souhaitez annuler le rendez-vous ici..."
                                />
                                <Button.Primary
                                    title="Annuler le RDV"
                                    onClick={async () => {
                                        if (!loading) {
                                            setLoading(true)
                                            const day = await cancelBooking(bookingSelected._id, reason)

                                            setCurrentDay(day)
                                            setDaySelected(day.date)
                                            setLoading(false)
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    )}

                    {modal === 'move' && (
                        <div className="grid gap-[24px] pt-[24px]">
                            <div className="grid gap-[10px]">
                                <p className="text-[20px] leading-[30px] font-medium text-center px-[10%]">
                                    Décaler la réservation
                                </p>
                            </div>
                            <div className="grid gap-[34px] px-[9%]">
                                <div className="grid gap-[8px]">
                                    <p>Choix du jour</p>
                                    <Input.Date
                                        value={newDate}
                                        setValue={(value) => {
                                            setNewDate(value.format('YYYY-MM-DD'))
                                        }}
                                    />
                                </div>
                                <div className="grid gap-[8px]">
                                    <p>Choix de l'heure</p>
                                    <Input.Duration
                                        value={formatSecondsToInput(newFrom)}
                                        setValue={(value) => {
                                            setNewFrom(formatHHmmToSeconds(value))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="p-4">
                                <Input.TextArea
                                    label="Motif de modification"
                                    value={reason}
                                    onChange={(value) => setReason(value)}
                                    placeholder="Vous pouvez dire pourquoi vous décaler le rendez-vous ici..."
                                />
                            </div>
                            <div className="px-4">
                                <Button.Primary
                                    title="Décaler le RDV"
                                    onClick={async () => {
                                        if (!loading) {
                                            setLoading(true)
                                            const day = await moveBooking(
                                                bookingSelected._id,
                                                reason,
                                                bookingSelected.date,
                                                newDate,
                                                {
                                                    from: newFrom,
                                                    to: newFrom + bookingSelected.duration,
                                                }
                                            )

                                            const year = dayjs(bookingSelected.date).year()
                                            const week = dayjs(bookingSelected.date).isoWeek()

                                            refreshWeek(year, week)

                                            setCurrentDay(day)
                                            setDaySelected(day.date)
                                            setLoading(false)
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                            <div className="mb-[60px]"></div>
                        </div>
                    )}
                </>
            ) : (
                <div className="px-[4%] pb-4">
                    <div className="py-[17px]">
                        {bookingSelected.state === 'moved' && (
                            <p className="text-[14px] font-normal ml-[32px]">En attente de confirmation du client</p>
                        )}
                    </div>
                    <div className="grid rounded-[30px] bg-white shadow-basic overflow-hidden">
                        <div className="grid gap-[10px] bg-white shadow-basic pb-[28px] pt-[21px] rounded-t-[30px]">
                            <div className="overflow-hidden rounded-full size-[55px] mx-auto">
                                {bookingSelected?._client?._profil?.avatar ? (
                                    <img
                                        src={bookingSelected?._client?._profil?.avatar?.thumbnailUrl}
                                        alt="Photo de profil du Client"
                                    />
                                ) : (
                                    <img src={ppBoy} alt="Photo de profil par défault du Client" />
                                )}
                            </div>
                            <p className="text-[20px] leading-[32px] font-semibold text-center uppercase">
                                Indisponibilité
                            </p>
                        </div>

                        <div className="my-[20px]">
                            <Pro.Booking.BookingTimingInformation booking={bookingSelected} />
                        </div>

                        <div className="flex space-x-2 mt-[24px] ml-[14px]">
                            <MapPin />
                            <p className="text-[14px] leading-[20px]">
                                {bookingSelected.address?.formatted || 'Non défini'}
                            </p>
                        </div>

                        <div className="flex space-x-[12px] mt-[28px] items-center ml-[14px]">
                            <Hourglass size={24} />
                            <div className="flex space-x-[9px]">
                                <p className="uppercase text-v2_purple text-[16px] leading-[20px] font-semibold">
                                    Durée
                                </p>
                                <p className="text-[16px] leading-[20px]">
                                    {formatSecondsToHHhmm(bookingSelected.duration)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="my-10">
                        <Button.Primary
                            title={'Supprimer'}
                            onClick={() =>
                                cancelBooking(bookingSelected._id, `Suppression d'un créneaux d'indisponibilité`)
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Screen
