import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/* eslint no-unused-vars: 0 */
const ScrollContext = createContext({
    container: HTMLElement,
    scrollPosition: Number,
    previousPosition: Number,
    scrollToTop: () => {},
    scrollToPreviousPosition: () => {},
    scrollToPosition: (position) => {},
    setDisableScrollValueUpdate: (value) => {},
})

export function ScrollContextProvider({ children }) {
    const [container, setContainer] = useState()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [previousPosition, setPreviousPosition] = useState(0)
    const [disableScrollValueUpdate, setDisableScrollValueUpdate] = useState(false)

    const location = useLocation()

    const updateContainer = () => {
        setContainer(document.getElementById('scrollable-zone'))
    }

    useEffect(() => {
        updateContainer()
    }, [])

    useEffect(() => {
        updateContainer()
    }, [location])

    useEffect(() => {
        if (!disableScrollValueUpdate) {
            if (!container) return

            const handleScroll = () => {
                setPreviousPosition(scrollPosition)
                setScrollPosition(container.scrollTop)
            }

            container.addEventListener('scroll', handleScroll)

            return () => container.removeEventListener('scroll', handleScroll)
        }
    }, [container, scrollPosition, disableScrollValueUpdate])

    const scrollToTop = () => container.scrollTo(0, 0)
    const scrollToPreviousPosition = () => container.scrollTo(0, previousPosition)
    const scrollToPosition = (position) => container.scrollTo(0, position)

    return (
        <ScrollContext.Provider
            value={{
                container: container,
                scrollPosition: scrollPosition,
                previousPosition: previousPosition,
                scrollToTop: scrollToTop,
                scrollToPreviousPosition: scrollToPreviousPosition,
                scrollToPosition: scrollToPosition,
                setDisableScrollValueUpdate: setDisableScrollValueUpdate,
            }}
        >
            {children}
        </ScrollContext.Provider>
    )
}

export const useScroll = () => useContext(ScrollContext)
