import React, { createContext, useContext, useState, useEffect } from 'react'

import * as AgendaDB from './AgendaDB'
import { Auth, Request } from '../..'
import dayjs from 'dayjs'
import { useView } from './ViewContext'

/* eslint no-unused-vars: 0 */
const DayContext = createContext({
    database: Object || null,
    nextDay: Object || null,
    currentDay: Object || null,
    previousDay: Object || null,
    getDay: (key) => {
        key = 'YYYY-MM-DD'
    },
    setCurrentDay: (value) => {},
})

export function DayContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token, id } = Auth.useAuth()

    const [agendaDatabase, setAgendaDatabase] = useState(null)

    useEffect(() => {
        AgendaDB.openIndexedDB().then((database) => setAgendaDatabase(database))
    }, [])

    const [previousDay, setPreviousDay] = useState()
    const [currentDay, setCurrentDay] = useState()
    const [nextDay, setNextDay] = useState()

    const getMonthFromCache = async (key) => {
        if (!('indexedDB' in window)) return null
        return await AgendaDB.getOneMonth(agendaDatabase, key)
    }

    const setDay = (variableToChange, value) => {
        switch (variableToChange) {
            case 'current':
                setCurrentDay(value)
                break
            case 'previous':
                setPreviousDay(value)
                break
            case 'next':
                setNextDay(value)
                break
        }
    }

    const getDayPrivate = async (date, variableToChange) => {
        const key = dayjs(date).format('YYYY-MM')
        let data = await getMonthFromCache(key)

        if (!data) {
            const response = await handleRequest('get', `coiffeur/days/${id}/${key}-01/month`, null, token)

            let day = { ...response.data.days[date], date: date }

            setDay(variableToChange, day)

            data = response.data.days.reduce((days, value) => {
                const { date, ...data } = value
                days[date] = data
                return days
            }, {})

            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: data,
                refreshAt: dayjs().toISOString(),
            })
        } else {
            let day = { ...data.days[date], date: date }
            setDay(variableToChange, day)
        }

        return
    }

    const getDay = async (date) => {
        const currentDayDate = dayjs(date)

        if (previousDay && nextDay) {
            if (previousDay.date === date) {
                setCurrentDay(previousDay)
                setNextDay(currentDay)

                const previousDayDate = currentDayDate.add(-1, 'day').format('YYYY-MM-DD')
                await getDayPrivate(previousDayDate, 'previous')

                return
            }

            if (nextDay.date === date) {
                setCurrentDay(nextDay)
                setPreviousDay(currentDay)

                const nextDayDate = currentDayDate.add(1, 'day').format('YYYY-MM-DD')
                await getDayPrivate(nextDayDate, 'next')

                return
            }
        }

        await getDayPrivate(date, 'current')

        const nextDayDate = currentDayDate.add(1, 'day').format('YYYY-MM-DD')
        const previousDayDate = currentDayDate.add(-1, 'day').format('YYYY-MM-DD')
        await Promise.all([getDayPrivate(nextDayDate, 'next'), getDayPrivate(previousDayDate, 'previous')])

        return
    }

    return (
        <DayContext.Provider
            value={{
                database: agendaDatabase,
                previousDay: previousDay,
                currentDay: currentDay,
                nextDay: nextDay,
                getDay: getDay,
                setCurrentDay: setCurrentDay,
            }}
        >
            {children}
        </DayContext.Provider>
    )
}

export const useDay = () => useContext(DayContext)
