import React, { useEffect, useState } from 'react'
import { Plus, Minus, House, MapPin, ArrowLeft } from 'lucide-react'

import { Input, Icon, Header, Button, Text, Cards } from '../../../atoms'
import { Return, Prestations, Pro as ProMolecules } from '../../../molecules'
import { Search } from '../../../organisms'
import { Profesionnal } from '../../'
import { Directory } from '..'
import { ProContext } from '../../../../../contexts'
import { formatHHmmToMilliseconds, formatSecondsToInput, formatSecondsToHHhmm } from '../../../../../utils'

import dayjs from 'dayjs'

const ChooseClient = ({ onClientSelecteted, onStep }) => {
    const {
        clientSelected,
        settingClientSelected,
        isImportingContact,
        setIsImportingContact,
        importPending,
        setClientSelected,
    } = ProContext.Directory.useDirectory()
    const [step, setStep] = useState('home')

    useEffect(() => {
        onStep(step)
    }, [step])

    useEffect(() => {
        if (clientSelected?._id) {
            setStep('home')
            onClientSelecteted(clientSelected)
        }
    }, [clientSelected])

    useEffect(() => {
        setClientSelected()
    }, [])

    return (
        <>
            {step === 'home' && (
                <div className="grid gap-[8px]">
                    <label className={clientSelected?._id ? 'font-bold text-[18px]' : ''}>Client</label>
                    {!clientSelected?._id ? (
                        <Input.Search
                            onSearch={() => {}}
                            placeholder="Rechercher"
                            value=""
                            onBlur={() => setStep('choose')}
                        />
                    ) : (
                        <>
                            {clientSelected?._user?._profil?.firstName} {clientSelected?._user?._profil?.lastName}
                        </>
                    )}
                </div>
            )}
            {step === 'choose' && (
                <div className="absolute top-0 left-0 w-full h-screen bg-white">
                    {/* Afficher la progressbar si l'importation est en cours */}
                    {importPending && isImportingContact && <Profesionnal.ImportContacts.ImportContactPending />}

                    {/* Condition 1: Importing Contacts */}
                    {isImportingContact && !importPending && (
                        <>
                            <Return.Primary onClick={() => setIsImportingContact(false)} />
                            <Profesionnal.ImportContacts.ImportContactList />
                        </>
                    )}

                    {/* Condition 2: No client selected */}
                    {!clientSelected && !isImportingContact && !importPending && (
                        <>
                            <div className="h-[32px] flex items-center">
                                <div
                                    onClick={() => setStep('home')}
                                    className="flex space-x-2 text-v2_purple px-[19px] underline text-[16px] leadgin-[20px] items-center"
                                >
                                    <ArrowLeft size={24} color="#72517e" /> Retour
                                </div>
                            </div>
                            <Header.ClientDirectory />
                            <Directory.ClientDirectory />
                        </>
                    )}

                    {/* Condition 3: Client selected but no id */}
                    {clientSelected && !clientSelected._id && !isImportingContact && !importPending && (
                        <>
                            <Return.Primary onClick={settingClientSelected} />
                            <ProMolecules.Directory.ClientForm />
                        </>
                    )}
                </div>
            )}
        </>
    )
}

const ChooseAdresses = ({ onAddressSelected, onPlaceSelected, onStep, addressClient, placeSelected }) => {
    const place = ProContext.Place.usePlace()

    const [step, setStep] = useState('home')
    const [addressSelected, setAddressSelected] = useState()
    const [selectingPlace, setSelectingPlace] = useState(placeSelected ? true : false)
    const [openDropdown, setOpenDropdown] = useState(false)

    useEffect(() => {
        onStep(step)
    }, [step])

    useEffect(() => {
        if (addressClient) {
            setAddressSelected(addressClient)
        }
    }, [addressClient])

    return (
        <>
            {step === 'home' ? (
                <div className="grid gap-[8px]">
                    <label className={addressSelected ? 'font-bold text-[18px]' : ''}>Adresse</label>
                    <div className="flex flex-row items-center">
                        <Cards.IconsLucide
                            title={'Chez client'}
                            icon={<House color={!placeSelected ? '#72517E' : '#6c6c6c'} size={15} />}
                            onClick={() => {
                                onPlaceSelected()
                                setSelectingPlace(false)
                                if (addressClient) onAddressSelected(addressClient)
                            }}
                            selected={!selectingPlace}
                            notCompleted={false}
                        />
                        <div className="mx-1">
                            <Text.Small color={'black'} className={'font-medium'}>
                                ou
                            </Text.Small>
                        </div>
                        <Cards.IconsLucide
                            title={'Chez pro'}
                            icon={<MapPin color={selectingPlace ? '#72517E' : '#6c6c6c'} size={15} />}
                            onClick={() => {
                                onPlaceSelected()
                                onAddressSelected()
                                setSelectingPlace(true)
                            }}
                            selected={selectingPlace}
                            notCompleted={false}
                        />
                    </div>
                    {selectingPlace ? (
                        <>
                            <div
                                className="flex flex-row items-center justify-between w-[63%] px-2 py-1 ml-2 border border-v2_purple rounded-2xl"
                                onClick={() => {
                                    setOpenDropdown(!openDropdown)
                                }}
                            >
                                <div>
                                    <Text.Small color={'black'}>
                                        {placeSelected ? placeSelected.name : "Ajouter l'adresse"}
                                    </Text.Small>
                                    {openDropdown && (
                                        <div>
                                            {place.list.map((placeDetail) => {
                                                if (placeDetail?._id !== placeSelected?._id)
                                                    return (
                                                        <div
                                                            key={placeDetail._id}
                                                            className="mt-1"
                                                            onClick={() => onPlaceSelected(placeDetail)}
                                                        >
                                                            <Text.Small color={'black'}>{placeDetail.name}</Text.Small>
                                                        </div>
                                                    )
                                            })}
                                            <div
                                                className="mt-1"
                                                onClick={() => {
                                                    setStep('address')
                                                    place.setStep('create')
                                                }}
                                            >
                                                <Text.Small className={'opacity-50'}>Ajouter l'adresse</Text.Small>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {addressSelected && addressSelected?.formatted !== placeSelected?.address.formatted
                                ? ''
                                : addressSelected?.formatted}
                        </>
                    ) : !addressSelected ? (
                        <Input.Search
                            onSearch={() => {}}
                            placeholder="Rechercher"
                            value=""
                            onBlur={() => setStep('chooseAddress')}
                        />
                    ) : (
                        addressSelected.formatted
                    )}
                </div>
            ) : (
                <div className="absolute top-0 left-0 w-full h-screen bg-white ">
                    <div className="h-[32px] flex items-center">
                        <div
                            onClick={() => setStep('home')}
                            className="flex space-x-2 text-v2_purple px-[19px] underline text-[16px] leadgin-[20px] items-center"
                        >
                            <ArrowLeft size={24} color="#72517e" /> Retour
                        </div>
                    </div>
                    <Search.Search
                        noHome={true}
                        fromProfil={true}
                        goBackToProfile={() => setStep('home')}
                        setRequestInProgress={() => {}}
                        setAddressSearched={(value) => {
                            setAddressSelected(value)
                            onAddressSelected(value)
                            setStep('home')
                        }}
                    />
                </div>
            )}
        </>
    )
}

const ChoosePrestation = ({ onStep }) => {
    const { currentPage, setCurrentPage } = ProContext.Prestation.useProPrestation()

    const { prestationSelectedWithCounter, setPrestationSelectedWithCounter, addPrestation, removePrestation } =
        ProContext.Bookings.useBooking()

    const [step, setStep] = useState('home')

    useEffect(() => {
        onStep(step)
    }, [step])

    return (
        <>
            {step === 'home' ? (
                <div className="grid gap-[8px]">
                    <label className={prestationSelectedWithCounter.length > 0 ? 'font-bold text-[18px]' : ''}>
                        Prestation
                    </label>

                    {prestationSelectedWithCounter.length === 0 ? (
                        <Input.Search
                            onSearch={() => {}}
                            placeholder="Rechercher"
                            value=""
                            onBlur={() => setStep('choosePrestation')}
                        />
                    ) : (
                        <div className="flex flex-col w-full p-3 py-2 mt-4 mb-3 bg-white shadow-basic">
                            {prestationSelectedWithCounter.map((prestation) => (
                                <div key={prestation._id}>
                                    <div className="flex w-full h-full items-center justify-between min-h-[40px]">
                                        <p className="font-medium text-[16px]">{prestation.name}</p>
                                        <div className="flex items-center justify-between">
                                            {prestation.counter && (
                                                <div onClick={() => removePrestation(prestation)} className="px-2">
                                                    <Minus size={20} color="black" />
                                                </div>
                                            )}
                                            <p className="text-[16px] mx-2">{prestation.counter}</p>
                                            {prestation.counter && (
                                                <div onClick={() => addPrestation(prestation)} className="px-2">
                                                    <Plus size={20} color="black" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="absolute top-0 left-0 w-full h-screen bg-white ">
                    <div className="h-[32px] flex items-center">
                        <div
                            onClick={() => setStep('home')}
                            className="flex space-x-2 text-v2_purple px-[19px] underline text-[16px] leading-[20px] items-center"
                        >
                            <ArrowLeft size={24} color="#72517e" /> Retour
                        </div>
                    </div>
                    <Text.H4 className="my-4 font-semibold text-center">Prestations</Text.H4>
                    <Prestations.Header onChange={(value) => setCurrentPage(value)} value={currentPage} />
                    <ProMolecules.Prestations.AllPrestationsWithCounter
                        currentPageValue={currentPage}
                        fromPro={true}
                        onContinue={(value) => {
                            setPrestationSelectedWithCounter(value)
                            setStep('home')
                        }}
                    />
                </div>
            )}
            {prestationSelectedWithCounter.length > 0 ? (
                <div onClick={() => setStep('choosePrestation')}>
                    <p className="text-v2_purple text-[16px] underline text-center">Ajouter une prestation</p>
                </div>
            ) : null}
        </>
    )
}

const ChooseDate = ({ onDateSelected, value }) => {
    const [date, setDate] = useState(value || dayjs())

    return (
        <div className="grid gap-[8px] mt-4">
            <label className={date ? 'font-bold text-[18px]' : ''}>Choix du jour</label>
            <Input.Date
                value={value}
                setValue={(value) => {
                    setDate(dayjs(value).format('YYYY-MM-DD'))
                    onDateSelected(dayjs(value).format('YYYY-MM-DD'))
                }}
            />
        </div>
    )
}

const ChooseTime = ({ onTimeSelected, value }) => {
    const [time, setTime] = useState(value || 3600 * 9)

    return (
        <div className="grid gap-[8px] mt-4">
            <label className={time ? 'font-bold text-[18px]' : ''}>Choix de l'heure</label>
            <Input.Duration
                value={formatSecondsToInput(time)}
                setValue={(value) => {
                    setTime(formatHHmmToMilliseconds(value) / 1000)
                    onTimeSelected(formatHHmmToMilliseconds(value) / 1000)
                }}
            />
        </div>
    )
}

const TotalPrice = () => {
    const { totalPrice } = ProContext.Bookings.useBooking()

    return (
        <div className="grid gap-[8px] mt-4">
            <label className="font-bold text-[18px]">Coût total</label>
            {totalPrice ? <p>{totalPrice} €</p> : <p>Prix total indisponible</p>}
        </div>
    )
}

const TotalDuration = () => {
    const { totalDuration } = ProContext.Bookings.useBooking()

    return (
        <div className="grid gap-[8px] mt-4">
            <label className="font-bold text-[18px]">Durée totale</label>
            {totalDuration ? <p>{formatSecondsToHHhmm(totalDuration)}</p> : <p>Durée totale non disponible</p>}
        </div>
    )
}

const AddCommentary = ({ onChange }) => {
    const [commentary, setCommentary] = useState('')

    return (
        <div className="grid gap-[8px] mt-4">
            <label className={commentary ? 'font-bold text-[18px]' : ''}>Commentaire</label>
            <Input.TextArea
                placeholder={'Votre commentaire visible uniquement par vous'}
                value={commentary}
                onChange={(value) => {
                    setCommentary(value)
                    onChange(value)
                }}
            />
        </div>
    )
}

const Client = ({ onCreate }) => {
    const {
        addBookingManually,
        setBookingCreate,
        setStep,
        prestationSelectedWithCounter,
        setPrestationSelectedWithCounter,
        bookingCreate,
    } = ProContext.Bookings.useBooking()
    const { settingClientSelected } = ProContext.Directory.useDirectory()

    const [localStep, setLocalStep] = useState('home')
    const [clientSelected, setClientSelected] = useState()
    const [addressSelected, setAddressSelected] = useState()
    const [placeSelected, setPlaceSelected] = useState()
    const [dateSelected, setDateSelected] = useState(dayjs(bookingCreate.date) || null)
    const [timeSelected, setTimeSelected] = useState(bookingCreate.from || null)
    const [commentary, setCommentary] = useState('')

    useEffect(() => {
        if (bookingCreate) {
            setDateSelected(dayjs(bookingCreate.date))
            setTimeSelected(bookingCreate.from)
        }
    }, [bookingCreate])

    const handleBack = () => {
        setBookingCreate(null)
        setStep()
        setPrestationSelectedWithCounter([])
    }

    useEffect(() => {
        if (clientSelected) {
            setAddressSelected(clientSelected._user._profil.address)
        }
    }, [clientSelected])

    useEffect(() => {
        return () => {
            settingClientSelected()
            setClientSelected(null)
            setAddressSelected('')
            setPrestationSelectedWithCounter([])
            setBookingCreate(null)
            setStep()
        }
    }, [])

    useEffect(() => {
        if (!placeSelected) {
            if (clientSelected) {
                setAddressSelected(clientSelected._user._profil.address)
            } else {
                setAddressSelected('')
            }
        }
    }, [placeSelected])

    return (
        <div className="px-[13px] relative">
            <div className="flex justify-end" onClick={handleBack}>
                <Icon.Close className="w-[40px] text-v2_purple" />
            </div>
            <div className="grid gap-[24px]">
                <p className="text-[20px] font-semibold leading-[30px] text-center">Ajouter un RDV</p>
                <ChooseClient
                    onClientSelecteted={(value) => setClientSelected(value)}
                    onStep={(value) => setLocalStep(value)}
                />
                <ChooseAdresses
                    onAddressSelected={(value) => setAddressSelected(value)}
                    onPlaceSelected={(value) => {
                        setPlaceSelected(value)
                        if (value) {
                            setAddressSelected(value.address)
                        } else {
                            if (clientSelected) {
                                setAddressSelected(clientSelected._user._profil.address)
                            } else {
                                setAddressSelected('')
                            }
                        }
                    }}
                    onStep={(value) => setLocalStep(value)}
                    addressClient={addressSelected}
                    placeSelected={placeSelected}
                />
                <ChoosePrestation onStep={(value) => setLocalStep(value)} />

                {prestationSelectedWithCounter.length > 0 && localStep === 'home' ? <TotalPrice /> : null}
                {prestationSelectedWithCounter.length > 0 && localStep === 'home' ? <TotalDuration /> : null}
                {prestationSelectedWithCounter.length > 0 && localStep === 'home' ? (
                    <ChooseDate onDateSelected={(value) => setDateSelected(value)} value={dateSelected} />
                ) : null}
                {prestationSelectedWithCounter.length > 0 && dateSelected && localStep === 'home' ? (
                    <ChooseTime onTimeSelected={(value) => setTimeSelected(value)} value={timeSelected} />
                ) : null}

                <AddCommentary onChange={(value) => setCommentary(value)} />
            </div>
            {localStep === 'home' ? (
                <div className="pb-2 mt-8">
                    <Button.Primary
                        title={'Enregistrer le RDV'}
                        disabled={!clientSelected || !addressSelected || !dateSelected ? true : false}
                        onClick={async () => {
                            await addBookingManually(
                                clientSelected._user,
                                addressSelected,
                                dateSelected,
                                timeSelected,
                                commentary,
                                placeSelected
                            )

                            onCreate()
                            handleBack()
                        }}
                    />
                </div>
            ) : null}
        </div>
    )
}

const Indisponibility = ({ onCreate, horaireStart }) => {
    const { addIndisponibility, setStep, setBookingCreate } = ProContext.Bookings.useBooking()

    const [allDay, setAllDay] = useState(false)
    const [dateStart, setDateStart] = useState(dayjs())
    const [dateEnd, setDateEnd] = useState(dayjs())
    const [startTime, setStartTime] = useState(3600 * 9)
    const [endTime, setEndTime] = useState(3600 * 9)

    const handleBack = () => {
        setBookingCreate()
        setStep()
    }

    return (
        <div className="px-[13px] relative">
            <div className="flex justify-end" onClick={handleBack}>
                <Icon.Close className="w-[40px] text-v2_purple" />
            </div>
            <div className="grid gap-[24px]">
                <p className="text-[20px] font-semibold leading-[30px] text-center">Ajouter une indisponibilité</p>
                <div className="flex space-x-[5px]">
                    <input type="checkbox" checked={allDay} onChange={() => setAllDay(!allDay)} />
                    <p className="text-[20px] leading-[20px] font-medium">Journée entière</p>
                </div>
                <div className="grid gap-[8px] mt-4">
                    <label className={'text-[20px] leading-[20px] font-medium'}>Choix du jour</label>
                    <Input.Date
                        value={horaireStart || dateStart}
                        setValue={(value) => {
                            setDateStart(dayjs(value).format('YYYY-MM-DD'))
                        }}
                    />
                </div>
                {allDay ? (
                    <div className="grid gap-[8px] mt-4">
                        <label className={'text-[20px] leading-[20px] font-medium'}>Choix du jour de fin</label>
                        <Input.Date
                            value={dateEnd}
                            setValue={(value) => {
                                setDateEnd(dayjs(value).format('YYYY-MM-DD'))
                            }}
                        />
                    </div>
                ) : null}
                <div className="grid gap-[8px] mt-4">
                    <label className={'text-[20px] leading-[20px] font-medium'}>Heure de début</label>
                    <Input.Duration
                        value={formatSecondsToInput(startTime)}
                        setValue={(value) => {
                            setStartTime(formatHHmmToMilliseconds(value) / 1000)
                        }}
                    />
                </div>
                {!allDay ? (
                    <div className="grid gap-[8px] mt-4">
                        <label className={'text-[20px] leading-[20px] font-medium'}>Heure de fin</label>
                        <Input.Duration
                            value={formatSecondsToInput(endTime)}
                            setValue={(value) => {
                                setEndTime(formatHHmmToMilliseconds(value) / 1000)
                            }}
                        />
                    </div>
                ) : null}
                <div className="mt-10">
                    <Button.Primary
                        title={'Confirmer'}
                        disabled={!startTime || !endTime || !dateStart || !dateEnd ? true : false}
                        onClick={async () => {
                            const date = await addIndisponibility(allDay, dateStart, startTime, endTime, dateEnd)
                            onCreate(date)
                            handleBack()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { Client, Indisponibility }
