/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { Address, Request, Auth } from '../..'
import { useLocation, useNavigate } from 'react-router-dom'
import { Favorite } from '..'
const dayjs = require('dayjs')

const ReservationContext = createContext({
    profil: Object,
    prestationsSelected: Array,
    days: Array,
    totalTime: Number,
    totalPrice: Number,
    priceVariable: Boolean,
    loading: Boolean,
    selectedReservation: Object,
    bookingsClient: Array,
    currentBooking: Object,
    backUrl: String,
    step: String,
    travels: Object,
    addPrestation: () => {},
    removePrestation: () => {},
    getDays: async () => {},
    getProfilById: async () => {},
    setTime: () => {},
    getBookings: async () => {
        return Promise.resolve()
    },
    bookSchedule: async () => {},
    leaveComment: async () => {},
    cancelBooking: async () => {},
    calculatingNewTravels: async () => {},
    setCurrentBooking: () => {},
    setStep: () => {},
})

export function ReservationContextProvider({ children }) {
    const location = useLocation()

    const { handleRequest, handleRequestV2 } = Request.useRequest()
    const { token, id, isLogged } = Auth.useAuth()
    const { coordinates, selectedAddress } = Address.useAddress()
    const { favorite, putFavorites } = Favorite.useFavorite()

    const [backUrl, setBackUrl] = useState('/')
    const [comeFromFavorites, setComeFromFavorites] = useState(false)

    const [profil, setProfil] = useState(null)
    const [needValidation, setNeedValidation] = useState(false)
    const [travels, setTravels] = useState({})

    const [prestationsSelected, setPrestationsSelected] = useState([])
    const [totalTime, setTotalTime] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [priceVariable, setPriceVariable] = useState(false)
    const [coiffeurId, setCoiffeurId] = useState(null)

    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState([])
    const [firstDayOfCalcul, setFirstDayOfCalcul] = useState(dayjs().add(2, 'day').format('YYYY-MM-DD'))
    const [selectedReservation, setSelectedReservation] = useState(null)
    const [dateIncremented, setDateIncremented] = useState(false)
    const [bookingsClient, setBookingsClient] = useState([])

    const [currentBooking, setCurrentBooking] = useState()
    const [step, setStep] = useState('main')
    const [prestationDetail, setPrestationDetail] = useState()

    const calculateTotal = () => {
        let duration = 0
        let price = 0
        let variable = false

        prestationsSelected.forEach((prestation) => {
            duration += (prestation.duration * prestation.counter) / 1000

            const splitted = prestation.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') price += parseFloat(split) * prestation.counter
            })

            if (splitted.length > 1) variable = true
        })

        setTotalTime(duration)
        setTotalPrice(price)
        setPriceVariable(variable)
    }

    useEffect(() => {
        calculateTotal()
    }, [prestationsSelected])

    useEffect(() => {
        if (dateIncremented) {
            getDays()
            setDateIncremented(false)
        }
    }, [dateIncremented])

    useEffect(() => {
        if (isLogged()) {
            if (backUrl === '/') setBackUrl('/client')
        }
    }, [token, id])

    useEffect(() => {
        if (location.pathname.indexOf('profil/') !== -1 || location.pathname.indexOf('prestations/') !== -1) {
            let id = location.pathname.split('/').at(-1)

            if (prestationsSelected.length > 0) {
                prestationsSelected.map((prestation) => {
                    if (prestation._owner !== id) {
                        setNeedValidation(true)
                    }
                })
            } else {
                setCoiffeurId(location.pathname.split('/').at(-1))
                getProfilById(id)
            }
        }
    }, [location])

    const addPrestation = (prestation) => {
        setPrestationsSelected((prevPrestations) => {
            const updatedPrestations = [...prevPrestations]
            const existingIndex = updatedPrestations.findIndex((p) => p._id === prestation._id)

            if (existingIndex !== -1) {
                updatedPrestations[existingIndex] = {
                    ...prestation,
                    counter: updatedPrestations[existingIndex].counter + 1,
                }
            } else {
                updatedPrestations.push({ ...prestation, counter: 1 })
            }

            return updatedPrestations
        })
    }

    const removePrestation = (id) => {
        setPrestationsSelected((prevPrestations) => {
            const updatedPrestations = prevPrestations
                .map((prestation) =>
                    prestation._id === id
                        ? { ...prestation, counter: prestation.counter > 0 ? prestation.counter - 1 : 0 }
                        : prestation
                )
                .filter((prestation) => prestation.counter > 0)
            return updatedPrestations
        })
    }

    const setProfilPublic = (value) => {
        sessionStorage.setItem('professionnal', JSON.stringify(value))
        setProfil(value)
    }

    const getProfilById = async (coiffeurId) => {
        try {
            setLoading(true)
            const response = await handleRequest('get', `profil/unlogged/coiffeur/${coiffeurId}`, null, null)
            if (response?.data) {
                setProfilPublic(response.data.coiffeur)
                setLoading(false)
                return response.data.coiffeur
            }
        } catch (error) {
            console.error('Error fetching profile:', error)
            setLoading(false)
        }
    }

    const setTime = (horaire, date) => {
        if (selectedReservation) {
            if (horaire && date && selectedReservation.horaire === horaire && selectedReservation.date === date) {
                setSelectedReservation(null)
            } else {
                setSelectedReservation({ horaire, date })
            }
        } else {
            setSelectedReservation({ horaire, date })
        }
    }

    const getBookings = async () => {
        if (bookingsClient.length === 0) {
            setLoading(true)
        }
        if (isLogged()) {
            const response = await handleRequest('get', 'client/bookings', null, token, null, true)

            if (response?.data) {
                setBookingsClient(response.data.bookings)
                setLoading(false)
            }
        }
    }

    const clearBasket = () => {
        setPrestationsSelected([])
        setTotalTime(0)
        setTotalPrice(0)
        setCoiffeurId(location.pathname.split('/').at(-1))
        setDays([])
        setFirstDayOfCalcul(dayjs().add(2, 'day').format('YYYY-MM-DD'))
        setSelectedReservation(null)
        setDateIncremented(false)
        setBookingsClient([])
        setCurrentBooking()
        setNeedValidation(false)
        setRequestedDates([])
    }

    //** API Call **/
    const calculatingNewTravels = async () => {
        if (profil && coordinates.latitude && coordinates.longitude) {
            const response = await handleRequestV2(
                'get',
                `map/pro/${profil?._id}/clients?lat=${coordinates.latitude}&lon=${coordinates.longitude}`
            )
            setTravels(response.data.result)
        }
        return
    }

    const [requestedDates, setRequestedDates] = useState([])

    const getDays = async (initialDate, onlyOneLaunch) => {
        const fetchUntilAvailabilityFound = async (date, accumulatedDays = []) => {
            // Vérifier si la date a déjà été demandée
            if (requestedDates.includes(date)) {
                return accumulatedDays
            }

            try {
                const response = await handleRequest(
                    'post',
                    'client/algorithme/week/v2',
                    {
                        coiffeurId: coiffeurId,
                        clientLocation: { lat: coordinates.latitude, lon: coordinates.longitude },
                        duration: totalTime,
                        firstDayOfCalcul: date,
                        travels: travels,
                    },
                    null
                )

                if (response?.data) {
                    const nextDateFetch = dayjs(date).add(7, 'day').format('YYYY-MM-DD')
                    setFirstDayOfCalcul(nextDateFetch)
                    setRequestedDates((prevDates) => [...prevDates, date])

                    const newAvailableDays = response.data.bookables
                        .filter((day) => day.bookings.length > 0)
                        .filter((day) => !accumulatedDays.some((accDay) => accDay.date === day.date))
                    accumulatedDays.push(...newAvailableDays)

                    if (accumulatedDays.length >= 7 || onlyOneLaunch) {
                        setLoading(false)
                        return accumulatedDays.slice(0, 7)
                    } else {
                        return fetchUntilAvailabilityFound(nextDateFetch, accumulatedDays)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }

        setLoading(true)
        const initialDays = await fetchUntilAvailabilityFound(initialDate) // Trouver les 7 premiers jours

        setDays((prevDays) => {
            const allDays = [...prevDays, ...initialDays]

            const uniqueDaysMap = new Map()
            allDays.forEach((day) => {
                uniqueDaysMap.set(day.date, day)
            })

            const arraySorted = Array.from(uniqueDaysMap.values()).sort((a, b) => {
                return new Date(a.date) - new Date(b.date) // Conversion des dates pour comparer
            })
            return arraySorted
        })

        setLoading(false)
        return
    }

    const bookSchedule = async (address) => {
        const response = await handleRequest(
            'post',
            `client/bookings/${coiffeurId}`,
            {
                date: selectedReservation.date,
                prestations: prestationsSelected,
                start: selectedReservation.horaire.start,
                end: selectedReservation.horaire.end,
                client: id,
                price: totalPrice,
                duration: totalTime,
                address: address,
                priceVariable: priceVariable,
                place: selectedReservation.horaire.place,
            },
            token
        )

        if (response.data) {
            clearBasket()

            if (favorite.filter((fav) => fav._owner?._id === coiffeurId).length === 0)
                putFavorites([...favorite.map((fav) => fav._owner._id), coiffeurId])

            return response?.data
        } else {
            return { error: true }
        }
    }

    const leaveComment = async (note) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `booking/${coiffeurId}/${id}/${currentBooking._id}/note`,
                {
                    note: {
                        value: note,
                        date: dayjs(currentBooking.date).toISOString(),
                        from: 'client',
                    },
                },
                token
            )

            getBookings()
            return response?.data?.bookings
        }
    }

    const cancelBooking = async (reason) => {
        if (isLogged()) {
            const response = await handleRequest(
                'put',
                `client/bookings/${currentBooking._id}`,
                {
                    state: 'cancel',
                    date: currentBooking.date,
                    reason: reason,
                },
                token
            )

            if (response?.data) {
                setCurrentBooking({ ...currentBooking, canceledBy: 'client', reason: reason, state: 'cancel' })
            }

            getBookings()
            return response?.data?.bookings
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        clearBasket()
        const profilStored = sessionStorage.getItem('professional')
        if (profilStored) setProfil(JSON.parse(profilStored))

        if (location.pathname.indexOf('reservation') !== -1) {
            navigate(-1)
        }

        return () => {
            clearBasket()
        }
    }, [])

    const handleComeFromFavorites = (value) => {
        setComeFromFavorites(value)
        sessionStorage.setItem('comeFromFavorites', JSON.stringify(value))
    }

    return (
        <ReservationContext.Provider
            value={{
                prestationsSelected,
                totalTime,
                totalPrice,
                days,
                loading,
                selectedReservation,
                profil: profil,
                bookingsClient: bookingsClient,
                currentBooking: currentBooking,
                backUrl: backUrl,
                needValidation: needValidation,
                priceVariable: priceVariable,
                step: step,
                prestationDetail: prestationDetail,
                firstDayOfCalcul: firstDayOfCalcul,
                travels: travels,
                comeFromFavorites: comeFromFavorites,
                setDays: setDays,
                setFirstDayOfCalcul: setFirstDayOfCalcul,
                getProfilById: getProfilById,
                setProfil: setProfilPublic,
                addPrestation: addPrestation,
                removePrestation: removePrestation,
                getDays: getDays,
                setTime: setTime,
                setBookingsClient: setBookingsClient,
                getBookings: getBookings,
                setCurrentBooking: setCurrentBooking,
                setBackUrl: setBackUrl,
                setNeedValidation: setNeedValidation,
                clearBasket: clearBasket,
                bookSchedule: bookSchedule,
                leaveComment: leaveComment,
                cancelBooking: cancelBooking,
                setStep: setStep,
                setPrestationDetail: setPrestationDetail,
                calculatingNewTravels: calculatingNewTravels,
                setRequestedDates: setRequestedDates,
                setComeFromFavorites: handleComeFromFavorites,
            }}
        >
            {children}
        </ReservationContext.Provider>
    )
}

export const useReservation = () => useContext(ReservationContext)
