import React, { useEffect, useState } from 'react'
import { Text } from '../../../atoms'
import { Return } from '../../../molecules'
import { Client } from '../../../organisms'
import { ClientContext, Address, Auth } from '../../../../../contexts'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

export default function Main({ goToStep }) {
    const navigate = useNavigate()

    const { isLogged } = Auth.useAuth()
    const {
        profil,
        days,
        prestationsSelected,
        setFirstDayOfCalcul,
        setDays,
        getDays,
        firstDayOfCalcul,
        loading,
        setRequestedDates,
    } = ClientContext.Reservation.useReservation()
    const { selectedAddress, setStep } = Address.useAddress()

    const [fetched, setFetched] = useState(false)
    const [firstSevenDays, setFirstSevenDays] = useState([])

    const [initiate, setInitiate] = useState(false)

    useEffect(() => {
        setDays([])
        setFirstDayOfCalcul(dayjs().add(2, 'day').format('YYYY-MM-DD'))
        setInitiate(true)

        return () => {
            setRequestedDates([])
            setFirstDayOfCalcul(dayjs().add(2, 'day').format('YYYY-MM-DD'))
        }
    }, [])

    useEffect(() => {
        const handleGetFirstWeek = async () => {
            await getDays(dayjs().add(2, 'day').format('YYYY-MM-DD'))
        }
        if (initiate) {
            handleGetFirstWeek()
        }
    }, [initiate])

    useEffect(() => {
        if (days?.length === 0 && !fetched && !loading) {
            setFetched(true)
            getDays(firstDayOfCalcul)
        } else {
            if (days) {
                const today = dayjs().startOf('day')
                const tomorrow = dayjs().add(1, 'day').startOf('day')

                // Filtrer les jours pour exclure aujourd'hui et demain
                const filteredDays = days.filter((day) => {
                    const dayDate = dayjs(day.date).startOf('day')
                    return !dayDate.isSame(today) && !dayDate.isSame(tomorrow)
                })

                if (filteredDays.length > 7) {
                    const localDays = [...filteredDays]
                    setFirstSevenDays(localDays.splice(0, 7))
                } else {
                    setFirstSevenDays(filteredDays)
                }
            }
        }
    }, [days])

    useEffect(() => {
        setFetched(true)
    }, [loading])

    const goBackFunc = () => {
        if (profil?._id)
            if (isLogged()) {
                navigate(`/client/pro/prestations/${profil._id}`, { state: { from: location.pathname } })
            } else {
                navigate(`/prestations/${profil._id}`, { state: { from: location.pathname } })
            }
        else navigate(-1)
    }

    return (
        <div>
            <Return.Primary className="p-4 sticky top-[-20px] bg-white z-[51]" onClick={() => goBackFunc()} />
            <div className="mt-4">
                <Client.Reservation.Address
                    address={selectedAddress}
                    modifyAddress={() => {
                        setStep('address')
                        navigate('/')
                    }}
                />
                <Client.Reservation.Prestations.Prestations prestations={prestationsSelected} />
                <div onClick={() => navigate(`/prestations/${profil._id}`)}>
                    <Text.Small className="font-medium text-center underline text-v2_purple">
                        Ajouter une prestation
                    </Text.Small>
                </div>
            </div>
            {days?.length > 0 ? (
                <div className="flex flex-col mt-4">
                    <Text.Normal className="pb-4 font-semibold text-v2_purple text-[18px] ml-[24px]">
                        Choix de la date et de l'heure
                    </Text.Normal>
                    {firstSevenDays.map(({index, date, bookings }) => (
                        <Client.Reservation.Date goToStep={goToStep} key={index} date={date} horaires={bookings} />
                    ))}
                </div>
            ) : (
                <Text.Normal className="py-10 text-center">Aucun rendez-vous disponible cette semaine</Text.Normal>
            )}
            <div onClick={() => goToStep(2)}>
                <Text.Small className="py-2 font-medium text-center">Afficher plus de disponibilités</Text.Small>
            </div>
        </div>
    )
}
