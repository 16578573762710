import React, { useEffect, useMemo, useState } from 'react'

import { Cards, Text, List, Button } from '../../atoms'
import { Pro } from '../../molecules'
import { ProContext, NavbarShow } from '../../../../contexts'

import { ChevronRight, ArrowLeft, Pencil, MailWarning, PhoneOff, CheckIcon, X, Plus, Trash2 } from 'lucide-react'
import dayjs from 'dayjs'

const ClientDirectory = ({ filterStep, showFilterStep }) => {
    const { clients, settingClientSelected, getDirectory } = ProContext.Directory.useDirectory()

    const [filterBy, setFilterBy] = useState('lastName')

    const sortedClients = useMemo(() => {
        if (filterBy === 'lastName') {
            if (clients) {
                return clients.sort((a, b) => a._user?._profil?.lastName?.localeCompare(b._user?._profil?.lastName))
            }
            return []
        } else {
            if (clients) {
                return clients.sort((a, b) => a._user?._profil?.firstName?.localeCompare(b._user?._profil?.firstName))
            }
            return []
        }
    }, [clients, filterBy])

    const groupedClients = useMemo(() => {
        if (filterBy === 'lastName') {
            if (sortedClients.length > 0) {
                return sortedClients.reduce((acc, client) => {
                    const lastName = client?._user?._profil?.lastName || ''
                    const firstLetter = lastName.charAt(0).toUpperCase()
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []
                    }
                    acc[firstLetter].push(client)
                    return acc
                }, {})
            }
            return {}
        } else {
            if (sortedClients.length > 0) {
                return sortedClients.reduce((acc, client) => {
                    const firstName = client?._user?._profil?.firstName || ''
                    const firstLetter = firstName.charAt(0).toUpperCase()
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []
                    }
                    acc[firstLetter].push(client)
                    return acc
                }, {})
            }
            return {}
        }
    }, [sortedClients, filterBy])

    useEffect(() => {
        getDirectory()
    }, [])

    return (
        <div className="px-2 bg-white">
            {filterStep ? (
                <div className="w-full h-[100%] absolute top-0 left-0 bg-[#fff] z-10">
                    <div className="flex items-center justify-start px-4 mb-2" onClick={() => showFilterStep()}>
                        <ArrowLeft size={20} className="mr-1 text-v2_purple" />
                        <Text.Normal className="text-v2_purple">Retour</Text.Normal>
                    </div>
                    <div className="p-4 shadow-md">
                        <p className="mb-2 font-semibold text-[16px] text-[#000]">Trier par</p>
                        <div className="flex flex-row items-center justify-between">
                            <div className="w-[48%]">
                                {filterBy === 'firstName' ? (
                                    <Button.Primary title={'Prénom'} onClick={() => setFilterBy('firstName')} />
                                ) : (
                                    <Button.Gray title={'Prénom'} onClick={() => setFilterBy('firstName')} />
                                )}
                            </div>
                            <div className="w-[48%]">
                                {filterBy === 'lastName' ? (
                                    <Button.Primary title={'Nom'} onClick={() => setFilterBy('lastName')} />
                                ) : (
                                    <Button.Gray title={'Nom'} onClick={() => setFilterBy('lastName')} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full" onClick={() => showFilterStep()}>
                        <p className="mt-4 text-center underline text-v2_purple font-semibold text-[16px]">
                            Afficher le resultat
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    {Object.keys(groupedClients).length === 0 ? (
                        <p className="text-[16px] text-center" key={'no client'}>
                            Aucun client
                        </p>
                    ) : (
                        Object.keys(groupedClients).map((letter) => (
                            <div key={letter}>
                                <List.ListIndex letter={letter} />
                                {
                                    // filtrer sur les clients qui ont un id non vide
                                    groupedClients[letter]
                                        .filter((client) => client._user?._profil?._id)
                                        .map((client) => (
                                            <div
                                                className="flex flex-row items-center justify-between w-full mb-[18px] gap-4 px-2"
                                                key={client._user?._profil?._id}
                                                onClick={() => settingClientSelected(client)}
                                            >
                                                <Cards.ClientDirectory
                                                    className="flex-shrink-0"
                                                    avatar={client?._user?._profil?.avatar}
                                                    clientSelected={client}
                                                />
                                                <div className="flex-grow">
                                                    <p className="text-[16px] font-bold flex gap-2">
                                                        {client?._user?._profil?.lastName}{' '}
                                                        {client?._user?._profil?.firstName}
                                                        {!client?._user?._profil?.phoneNumber?.phone && (
                                                            <PhoneOff size={20} color="#919191" />
                                                        )}
                                                        {client?._user?.email?.indexOf('@') === -1 && (
                                                            <MailWarning size={20} color="#919191" />
                                                        )}
                                                    </p>
                                                    <p
                                                        className={`text-[12px] font-normal ${
                                                            !client?._user?._profil?.address?.formatted
                                                                ? 'text-v2_grey'
                                                                : ''
                                                        }`}
                                                    >
                                                        {client?._user?._profil?.address?.formatted ||
                                                            'Adresse manquante'}
                                                    </p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <ChevronRight size={25} color="#000" />
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        ))
                    )}
                </>
            )}
        </div>
    )
}

const Notes = () => {
    const { clientSelected, addNote, setAddNote, saveClientNotes, deleteClientNotes } =
        ProContext.Directory.useDirectory()
    const { setNavbarBottom } = NavbarShow.useNavbarShow()

    const [step, setStep] = useState('')

    const [view, setView] = useState('technical')
    const [technical, setTechnical] = useState(clientSelected?.notes?.technical || [])
    const [other, setOther] = useState(clientSelected?.notes?.other || [])
    const [isSaving, setIsSaving] = useState(false)

    const [localNote, setLocalNote] = useState('')
    const [tempNote, setTempNote] = useState('')
    const [dateBookingState, setDateBookingState] = useState('')

    useEffect(() => {
        setTempNote(localNote)
    }, [localNote])

    useEffect(() => {
        if (clientSelected?.notes) {
            setTechnical(clientSelected.notes.technical)
            setOther(clientSelected.notes.other)
        }
    }, [clientSelected])

    useEffect(() => {
        if (addNote) {
            setNavbarBottom(false)
        } else {
            setNavbarBottom(true)
        }
    }, [addNote])

    const handleNoteClick = (note) => {
        setStep('edit')
        setLocalNote(note)
    }

    const saveNotes = async (value) => {
        setIsSaving(true)
        const clientUpdate = await saveClientNotes(value, clientSelected._user?._id)

        console.log('clientUpdate', clientUpdate)
        let newTempNote
        if (view === 'technical') newTempNote = clientUpdate.notes.technical[clientUpdate.notes.technical.length - 1]
        if (view === 'other') newTempNote = clientUpdate.notes.other[clientUpdate.notes.other.length - 1]
        setTempNote(newTempNote)

        setIsSaving(false)
    }

    const handleSave = (updatedTechnical, updatedOther) => {
        if (!isSaving) {
            const notes = {
                technical: updatedTechnical || technical,
                other: updatedOther || other,
            }
            saveNotes(notes)
        }
    }

    const handleChange = () => {
        const currentDate = dayjs().toISOString()

        console.log('tempNote', tempNote)
        if (view === 'technical') {
            const updatedTechnicalPromise = new Promise((resolve) => {
                setTechnical((prevTechnical) => {
                    const validTechnical = Array.isArray(prevTechnical) ? prevTechnical : []
                    const existingItemIndex = validTechnical.findIndex((item) => item._id === tempNote._id)
                    let updatedTechnical

                    if (existingItemIndex !== -1) {
                        updatedTechnical = [...validTechnical]
                        updatedTechnical[existingItemIndex] = {
                            ...updatedTechnical[existingItemIndex],
                            value: tempNote.value,
                            dateUpdate: currentDate,
                            dateBooking: dateBookingState,
                        }
                        setLocalNote((prevState) => ({
                            ...prevState,
                            value: tempNote.value,
                            dateUpdate: currentDate,
                            dateBooking: dateBookingState,
                        }))
                    } else {
                        updatedTechnical = [...validTechnical, { value: tempNote.value, date: currentDate }]
                        setLocalNote((prevState) => ({
                            ...prevState,
                            value: tempNote.value,
                            date: currentDate,
                            dateBooking: dateBookingState,
                        }))
                    }

                    resolve(updatedTechnical)
                    return updatedTechnical
                })
            })

            updatedTechnicalPromise.then((updatedTechnical) => handleSave(updatedTechnical, null))
        } else {
            const updatedOtherPromise = new Promise((resolve) => {
                setOther((prevOther) => {
                    const validOther = Array.isArray(prevOther) ? prevOther : []
                    const existingItemIndex = validOther.findIndex((item) => item._id === tempNote._id)
                    let updatedOther

                    if (existingItemIndex !== -1) {
                        updatedOther = [...validOther]
                        updatedOther[existingItemIndex] = {
                            ...updatedOther[existingItemIndex],
                            value: tempNote.value,
                            dateUpdate: currentDate,
                            dateBooking: dateBookingState,
                        }
                        setLocalNote({ value: tempNote.value, dateUpdate: currentDate, dateBooking: dateBookingState })
                    } else {
                        updatedOther = [...validOther, { value: tempNote.value, date: currentDate }]
                        setLocalNote({ value: tempNote.value, date: currentDate, dateBooking: dateBookingState })
                    }

                    resolve(updatedOther)
                    return updatedOther
                })
            })

            updatedOtherPromise.then((updatedOther) => handleSave(null, updatedOther))
        }
    }

    const handleCancel = () => {
        if (!isSaving) {
            setTempNote(localNote)
        }
    }

    const handleNoteDelete = async (noteId) => {
        if (view === 'technical') {
            const updatedTechnical = technical.filter((note) => note._id !== noteId)
            setTechnical(updatedTechnical)

            await saveNotes({ technical: updatedTechnical, other })
        } else if (view === 'other') {
            const updatedOther = other.filter((note) => note._id !== noteId)
            setOther(updatedOther)

            await saveNotes({ technical, other: updatedOther })
        }
        setStep('')
        setLocalNote('')
        setTempNote('')
        setDateBookingState('')
    }

    if (addNote) {
        return (
            <div className="fixed w-full h-full overflow-y-auto bg-white top-safeAreaTop">
                <div className="pt-[80px] flex flex-col space-y-2">
                    {step === 'add' || (step === 'edit' && tempNote) ? (
                        <>
                            <div className="flex justify-between item h-[32px] bg-white mx-4">
                                <div
                                    onClick={() => {
                                        setStep('')
                                        setLocalNote('')
                                        setTempNote('')
                                        setDateBookingState('')
                                    }}
                                >
                                    <Text.Normal className="flex items-center font-medium underline gap-x-2 text-v2_purple px-[4%]">
                                        <ArrowLeft color="#72517E" size={24} />
                                        Retour
                                    </Text.Normal>
                                </div>
                                <div>
                                    <Trash2 onClick={() => handleNoteDelete(localNote._id)} size={24} />
                                </div>
                            </div>
                            <Pro.Directory.DirectoryAddAndEditNote
                                view={view}
                                tempNote={tempNote}
                                setTempNote={setTempNote}
                                setDateBooking={(value) => setDateBookingState(value)}
                                handleSaveDate={() => handleChange()}
                            />
                            {localNote.value !== tempNote.value && (
                                <div className="flex bg-keyboard justify-end space-x-[34px] px-[18px] py-[5px]">
                                    {isSaving ? (
                                        <div className="w-6 h-6 border-4 border-black rounded-full border-t-transparent animate-spin"></div>
                                    ) : (
                                        <>
                                            <div className="" onClick={handleChange}>
                                                <CheckIcon size={24} />
                                            </div>
                                            <div className="" onClick={handleCancel}>
                                                <X size={24} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="flex h-[32px] bg-white">
                                <div onClick={() => setAddNote(false)}>
                                    <Text.Normal className="flex items-center font-medium underline gap-x-2 text-v2_purple px-[4%]">
                                        <ArrowLeft color="#72517E" size={24} />
                                        Retour
                                    </Text.Normal>
                                </div>
                            </div>
                            <div className="text-center ">
                                <p className="text-[20px] font-semibold leading-[32px] tracking-[-0.4px]">
                                    Note sur le client
                                </p>
                                <p className="text-[15px] text-[#979797] leading-[32px] tracking-[-0.3px]">
                                    Visible uniquement par vous
                                </p>
                            </div>
                            <div>
                                <div className="grid grid-cols-2 drop-shadow-[0_4px_30px_#5F5F5F1A]">
                                    <div
                                        className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                            view === 'technical'
                                                ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                                : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                        }`}
                                        onClick={() => setView('technical')}
                                    >
                                        Note technique
                                    </div>
                                    <div
                                        className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                            view === 'other'
                                                ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                                : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                        }`}
                                        onClick={() => setView('other')}
                                    >
                                        Autre note
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-[4px]">
                                    {view === 'technical' &&
                                        technical &&
                                        (technical.length > 0 ? (
                                            technical.map((note) => (
                                                <div key={note._id}>
                                                    <Pro.Directory.DirectoryNote
                                                        note={note}
                                                        onClick={() => handleNoteClick(note)}
                                                        onDelete={() =>
                                                            deleteClientNotes(note._id, clientSelected._user?._id)
                                                        }
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <p className="flex w-full mt-6 ml-4 text-center">Aucune note</p>
                                        ))}
                                    {view === 'other' &&
                                        other &&
                                        (other.length > 0 ? (
                                            other.map((note) => (
                                                <div key={note._id}>
                                                    <Pro.Directory.DirectoryNote
                                                        note={note}
                                                        onClick={() => handleNoteClick(note)}
                                                        onDelete={() =>
                                                            deleteClientNotes(note._id, clientSelected._user?._id)
                                                        }
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <p className="flex w-full mt-6 ml-4 text-center">Aucune note</p>
                                        ))}
                                    <div className="flex justify-end w-full p-4">
                                        <div
                                            onClick={() => setStep('add')}
                                            className="flex items-center justify-center w-12 h-12 rounded-full bg-v2_purple"
                                        >
                                            <Plus color="#ffffff" size={24} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div
                className="fixed flex w-full text-white bg-v2_purple justify-between items-center bottom-[80px] pl-[19px] pr-[29px] py-[10px]"
                onClick={() => setAddNote(true)}
            >
                <p className="text-[16px] font-semibold leading-[20px]">Note sur le client</p>
                <Pencil color="#ffffff" size={24} />
            </div>
        )
    }
}

export { ClientDirectory, Notes }
