import React, { useState } from 'react'
import { Button } from '../../atoms'

import { Auth } from '../../../../contexts'

import Step1 from '../../../../assets/steps/step1.svg'
import Step2 from '../../../../assets/steps/step2.svg'
import Step3 from '../../../../assets/steps/step3.svg'

import Phone1 from '../../../../assets/steps/client-1.png'
import Phone2 from '../../../../assets/steps/client-2.png'
import Phone3 from '../../../../assets/steps/client-3.jpg'

import { ArrowLeft } from 'lucide-react'

export const OnBoarding = () => {
    const { handleFirstVisit } = Auth.useAuth()

    const texts = [
        'Trouvez votre professionnel de la beauté à domicile selon votre adresse',
        'Choisissez les prestations que vous souhaitez',
        'Accédez aux disponibilités du pro en quelques clics',
    ]
    const background = [Step1, Step3, Step2]
    const phone = [Phone1, Phone3, Phone2]

    const [step, setStep] = useState(0)

    const addStep = () => {
        if (step === 0) {
            setStep(1)
        }
        if (step === 1) {
            setStep(2)
        }
        if (step === 2) {
            handleFirstVisit()
        }
    }

    const removeStep = () => {
        if (step === 1) {
            setStep(0)
        }
        if (step === 2) {
            setStep(1)
        }
    }

    return (
        <div className="fixed h-absolute flex flex-col justify-between items-center p-5">
            <div className="flex flex-row items-center justify-between w-full px-5">
                {step >= 1 ? (
                    <div onClick={() => removeStep()}>
                        <ArrowLeft size={24} className="text-v2_purple mr-1" />
                    </div>
                ) : (
                    <div></div>
                )}
                <div onClick={() => handleFirstVisit()}>
                    <p className="text-[14px] font-medium text-v2_purple">Ignorer</p>
                </div>
            </div>
            <div className="flex flex-row items-center justify-center">
                <div className="relative w-fit mx-auto">
                    <img src={phone[step]} alt="Screenshot de l'application Iléa" className="h-[50vh] z-10 relative" />
                </div>
                <img
                    src={background[step]}
                    alt="Tâche rose en forme de flaque"
                    className="absolute top-[6.5%] z-0 left-[-20px]right-[20px] w-[76vw]"
                />
            </div>
            <div>
                <p
                    className={
                        'text-[20px] leading-[30px] tracking-[-0.8px] font-semibold text-[#72517E] text-center px-5'
                    }
                >
                    {texts[step]}
                </p>
                <div className="flex flex-row items-center justify-between w-full mt-10 px-5">
                    <div className="flex flex-row items-center">
                        <div
                            className={`transition-all ${
                                step === 0 ? 'w-8 h-4 bg-[#72517E]' : 'size-4 bg-[#F5F0F7]'
                            } rounded-full mr-2`}
                            onClick={() => setStep(0)}
                        ></div>
                        <div
                            className={`transition-all ${
                                step === 1 ? 'w-8 h-4 bg-[#72517E]' : 'size-4 bg-[#F5F0F7]'
                            } rounded-full mr-2`}
                            onClick={() => setStep(1)}
                        ></div>
                        <div
                            className={`transition-all ${
                                step === 2 ? 'w-8 h-4 bg-[#72517E]' : 'size-4 bg-[#F5F0F7]'
                            } rounded-full mr-2`}
                            onClick={() => setStep(2)}
                        ></div>
                    </div>
                    <div>
                        <Button.Next title={'Suivant'} onClick={() => addStep()} />
                    </div>
                </div>
            </div>
        </div>
    )
}
