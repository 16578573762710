import React from 'react'
import { LuHome } from 'react-icons/lu'
import { Icon, Text } from '../../../atoms'

export const AtHome = () => {
    return (
        <div className="flex flex-col items-center w-fit py-2 px-4 bg-background_light rounded-[30px] space-y-1">
            <LuHome className="w-4 h-4 text-v2_purple" />
            <Text.ExtraSmall>Chez vous</Text.ExtraSmall>
        </div>
    )
}

export const AtPlace = ({ address }) => {
    return (
        <div className="flex flex-col items-center w-fit py-2 px-4 bg-white rounded-[30px] space-y-1 border-v2_purple border-[1px]">
            <Icon.LocationPin className="w-4 h-4 text-v2_purple" />
            <Text.ExtraSmall>
                {address ? `${address.substring(0, 5)}${address.length > 5 ? '...' : ''}` : 'Chez pro'}
            </Text.ExtraSmall>
        </div>
    )
}
