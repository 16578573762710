import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Auth, Request } from '..'
import dayjs from 'dayjs'

/* eslint no-unused-vars: 0 */
const HistoricContext = createContext({})

export function HistoricContextProvider({ children }) {
    const location = useLocation()
    const { handleRequest } = Request.useRequest()
    const { token, type, isLogged } = Auth.useAuth()

    const [previousPage, setPreviousPage] = useState(null)

    const createNewPage = async () => {
        const response = await handleRequest(
            'post',
            `historic`,
            {
                currentPage: location.pathname,
                previousPage: location.state?.from,
                type: type,
            },
            token
        )

        if (response?.data) {
            setPreviousPage(response.data.historic)
        }
    }

    const handleExitPage = async () => {
        await handleRequest(
            'put',
            `historic/${previousPage._id}`,
            {
                exitAt: dayjs().toISOString(),
                nextPage: location.pathname,
            },
            token
        )
    }

    const handlePage = async () => {
        if (isLogged()) {
            if (previousPage) {
                await handleExitPage()
            }
            await createNewPage()
        }
    }

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            handlePage()
        }
    }, [location])

    return <HistoricContext.Provider value={{}}>{children}</HistoricContext.Provider>
}

export const useHistoric = () => useContext(HistoricContext)
