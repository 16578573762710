import React, { useEffect, useState } from 'react'

import { Text, Input, Button, Modal } from '../../atoms'
import { Pro } from '../../templates'
import { ProContext, Address } from '../../../../contexts'

import { Pencil, Trash2 } from 'lucide-react'
import dayjs from 'dayjs'
import { useSwipeable } from 'react-swipeable'

const ClientForm = () => {
    const { createClient, isLoading } = ProContext.Directory.useDirectory()
    const { setStep } = Address.useAddress()
    const place = ProContext.Place.usePlace()

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const handleCreateClient = async () => {
        if (firstName && lastName && address) {
            setIsButtonDisabled(true)
            await createClient(firstName, lastName, address, phoneNumber, email)
            setIsButtonDisabled(false)
        }
    }

    useEffect(() => {
        const isFormIncomplete = !firstName || !lastName || !address

        setIsButtonDisabled(isFormIncomplete)
    }, [firstName, lastName, address])

    useEffect(() => {
        setAddress({ formatted: '' })
    }, [])

    return (
        <div className="mx-4">
            <div className="my-8">
                <Text.H5 color={'black'} align={'center'} className={'font-medium'}>
                    Création d'un client
                </Text.H5>
            </div>
            <div className="flex flex-col space-y-4">
                <div>
                    <Text.Normal className="text-[18px]">Email</Text.Normal>
                    <Input.Text value={email} onChange={(value) => setEmail(value)} />
                </div>
                <div>
                    <Text.Normal className="text-[18px]">Prénom</Text.Normal>
                    <Input.Text value={firstName} onChange={(value) => setFirstName(value)} />
                </div>
                <div>
                    <Text.Normal className="text-[18px]">Nom</Text.Normal>
                    <Input.Text value={lastName} onChange={(value) => setLastName(value)} />
                </div>
                <div>
                    <Pro.Address.default noAddressDefault={true} setAddress={setAddress}>
                        <Text.Normal className="text-[18px]">Adresse du domicile</Text.Normal>
                        <div
                            onClick={() => {
                                place.setStep('hidden')
                                setStep('address')
                            }}
                            className="flex flex-row items-center justify-between p-4 border rounded-full bg-v2_white border-v2_purple"
                        >
                            {address.formatted ? (
                                <p className="w-full overflow-hidden truncate text-nowrap">{address.formatted}</p>
                            ) : (
                                <p className="text-[#9C9C9C]">Adresse du domicile de votre client</p>
                            )}
                        </div>
                    </Pro.Address.default>
                </div>
                <div>
                    <Text.Normal className="text-[18px]">Téléphone</Text.Normal>
                    <Input.Text
                        value={phoneNumber}
                        onChange={(value) => {
                            const sanitizedValue = value.replace(/[a-zA-Z\s]|\+33/g, '')
                            setPhoneNumber(sanitizedValue)
                        }}
                    />
                </div>
            </div>
            <div className="py-8">
                <Button.PrimaryLoader
                    title={'Créer un client'}
                    disabled={isButtonDisabled}
                    onClick={handleCreateClient}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}

const DirectoryNote = ({ note, onClick, onDelete }) => {
    const [isSwiped, setIsSwiped] = useState(false)

    const renderTextWithLineBreaks = (text) => {
        const truncatedText = text.length > 50 ? `${text.substring(0, 50)}...` : text
        return truncatedText.split('\n').map((str, index) => (
            <span key={index}>
                {str}
                {index < truncatedText.split('\n').length - 1 && <br />}
            </span>
        ))
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => setIsSwiped(true),
        onSwipedRight: () => setIsSwiped(false),
        preventScrollOnSwipe: true,
        trackMouse: true,
    })

    return (
        <div className={`relative flex items-center bg-white shadow-modal h-[116px] overflow-hidden`} {...handlers}>
            <div
                className={`absolute right-0 top-0 bottom-0 w-[100px] bg-[#FF5757] flex items-center justify-center transition-transform duration-300 ${
                    isSwiped ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <Trash2 onClick={() => onDelete()} color="#fff" size={40} />
            </div>
            <div
                className={`bg-white px-[8px] py-[19px] flex-1 flex flex-col justify-between transition-transform duration-300 ${
                    isSwiped ? '-translate-x-[100px]' : 'translate-x-0'
                }`}
            >
                <div className="flex">
                    <div className="flex w-full">
                        {note?.value ? (
                            <p>{renderTextWithLineBreaks(note.value)}</p>
                        ) : (
                            <p className="opacity-50">Note vide</p>
                        )}
                    </div>
                    <div className="flex">
                        <div className="flex pb-2 pl-2 h-fit">
                            <Pencil onClick={onClick} color="#000000" size={24} />
                        </div>
                    </div>
                </div>
                <div className="flex justify-between w-full">
                    <div className="flex w-1/2">
                        {note.dateBooking && (
                            <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                RDV {note.dateBooking ? dayjs(note.dateBooking).format('DD/MM/YYYY') : ''}
                            </p>
                        )}
                    </div>
                    <div className="flex w-1/2">
                        {note.dateUpdate && (
                            <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                modif{' '}
                                {note.dateUpdate
                                    ? `${dayjs(note.dateUpdate).format('DD/MM/YYYY')} à ${dayjs(note.dateUpdate).format(
                                          'HH[h]mm'
                                      )}`
                                    : ''}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const DirectoryAddAndEditNote = ({ view, tempNote, setTempNote, setDateBooking, handleSaveDate }) => {
    const [rows, setRows] = useState(1)
    const [showDateModal, setShowDateModal] = useState(false)

    const calculateRows = (value) => {
        if (value) {
            const lines = value.split('\n')
            const maxLineLength = Math.max(...lines.map((line) => line.length))
            const charsPerRow = 50
            const calculatedRows = Math.max(lines.length, Math.ceil(maxLineLength / charsPerRow))
            setRows(calculatedRows + 1)
        } else {
            setRows(1)
        }
    }

    useEffect(() => {
        calculateRows(tempNote.value)
    }, [tempNote])

    return (
        <div>
            <div
                className="flex flex-row items-center justify-between w-full px-4 cursor-pointer"
                onClick={() => setShowDateModal(!showDateModal)}
            >
                <p className="text-[16px] text-black font-medium underline">Choisissez la date du rendez-vous</p>
                <Pencil color="#000000" size={24} />
            </div>
            <textarea
                className="resize-none w-full px-[8px] py-[23px] bg-white"
                value={tempNote.value}
                onChange={(e) =>
                    setTempNote((prevNote) => ({
                        ...prevNote,
                        value: e.target.value,
                    }))
                }
                placeholder="Entrez le texte"
                id={view}
                rows={rows}
            />
            <div className="flex flex-row items-center justify-between px-4">
                {tempNote.dateBooking && (
                    <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                        RDV {tempNote.dateBooking ? dayjs(tempNote.dateBooking).format('DD/MM/YYYY') : ''}
                    </p>
                )}
                {tempNote.dateUpdate && (
                    <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                        modif{' '}
                        {tempNote.dateUpdate
                            ? `${dayjs(tempNote.dateUpdate).format('DD/MM/YYYY')} à ${dayjs(tempNote.dateUpdate).format(
                                  'HH[h]mm'
                              )}`
                            : ''}
                    </p>
                )}
            </div>
            <Modal.ModalDefault
                isOpen={showDateModal}
                setIsOpen={() => {
                    setShowDateModal(!showDateModal)
                }}
                confirmButton={<Button.Primary title="Enregistrer" onClick={() => handleSaveDate()} />}
            >
                <div className="py-10">
                    <Input.Date
                        value={dayjs().locale('fr-FR').format('YYYY-MM-DD')}
                        setValue={(value) => setDateBooking(dayjs(value).format('YYYY-MM-DD'))}
                    />
                </div>
            </Modal.ModalDefault>
        </div>
    )
}

export { ClientForm, DirectoryNote, DirectoryAddAndEditNote }
