import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Mail, Phone, ArrowLeft } from 'lucide-react'

import { Text, Carrousel, Cards, Icon } from '../atoms'

import { Request } from '../../../contexts'
import { formatSecondsToHHhmm } from '../../../utils'

import { LuHome } from 'react-icons/lu'

export const Category = ({ categories }) => {
    return (
        <div className="flex max-w-full mx-4 my-10 space-x-2 overflow-scroll flex-nowrap">
            {categories.map((category, index) => (
                <Cards.ProCategorie key={index} title={category} />
            ))}
        </div>
    )
}

export const Specialities = ({ specialities }) => {
    return (
        <div className="flex flex-col mx-4 mt-10 mb-10 space-y-4">
            <Text.Normal className="font-bold text-v2_purple">Ses spécialités</Text.Normal>
            <div className="flex flex-wrap gap-4">
                {specialities.map((speciality, index) => (
                    <div className="flex" key={index}>
                        <Cards.ProSpecialities title={speciality.name} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Biography = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div className="flex flex-col m-4 space-y-4">
            <Text.Normal className="font-bold text-v2_purple">Biographie</Text.Normal>
            <div>
                <Text.Normal className={`${isExpanded ? '' : 'line-clamp-3'} leading-8`}>{children}</Text.Normal>
                {!isExpanded && (
                    <a
                        className="flex items-center font-semibold underline cursor-pointer text-v2_purple"
                        onClick={handleToggle}
                    >
                        <Text.Normal className="mr-2 font-semibold underline">Voir plus</Text.Normal>
                        <ChevronDown size={20} color="#72517E" />
                    </a>
                )}
                {isExpanded && (
                    <a className="flex items-center cursor-pointer text-v2_purple" onClick={handleToggle}>
                        <Text.Normal className="mr-2 font-semibold underline">Voir moins</Text.Normal>
                        <ChevronUp size={20} color="#72517E" />
                    </a>
                )}
            </div>
        </div>
    )
}

export const Gallery = ({ images, onImageClick }) => {
    return (
        <div className="flex flex-col p-4 space-y-4">
            <Text.Normal className="font-bold text-v2_purple">Galerie photos</Text.Normal>
            <Carrousel.Gallery images={images} onImageClick={onImageClick} />
        </div>
    )
}

export const Contact = ({ email, phone }) => {
    const formattedPhone = phone.phone.length < 10 ? `0${phone.phone}` : phone.phone
    const formattedPhoneWithSpaces = formattedPhone?.replace(/(\d{2})(?=\d)/g, '$1 ')

    return (
        <div className="flex flex-col m-4 space-y-4">
            <Text.Normal className="font-bold text-v2_purple">Coordonnées</Text.Normal>
            <div className="flex items-center">
                <Mail size={32} color="#72517E" className="mr-2" />
                <Text.Normal>{email}</Text.Normal>
            </div>
            <div className="flex items-center">
                <Phone size={32} color="#72517E" className="mr-2" />
                <Text.Normal>{formattedPhoneWithSpaces}</Text.Normal>
            </div>
        </div>
    )
}

export const Schedule = ({ proId }) => {
    const { handleRequest } = Request.useRequest()
    const [loading, setLoading] = useState(true)

    const [defaultDays, setDefaultDays] = useState()

    const [daySelected, setDaySelected] = useState()
    const [dayModal, setDayModal] = useState(false)

    const getDefaultDays = async () => {
        const response = await handleRequest('get', `coiffeur/days/default/${proId}`)

        if (response?.data) {
            setLoading(false)
            setDefaultDays(response.data.days)
        }
    }

    useEffect(() => {
        getDefaultDays()
    }, [])

    const RenderTiming = ({ day }) => {
        if (!day?.isWorkingDay) {
            return <p className="capitalize text-v2_grey text-[16px]">Fermé</p>
        } else {
            return (
                <p className="text-[16px] text-end">
                    {formatSecondsToHHhmm(day.startOfDay)} - {formatSecondsToHHhmm(day.endOfDay)}
                </p>
            )
        }
    }

    const RenderHalfTiming = ({ day }) => {
        if (!day?.isWorkingDay) {
            return <div>Repos</div>
        } else {
            if (day?.blocs) {
                return (
                    <div>
                        {day?.blocs?.map((bloc, index) => {
                            return (
                                <div key={index} className="flex flex-row items-center gap-2 mb-6">
                                    <p>
                                        {formatSecondsToHHhmm(bloc.start)}-{formatSecondsToHHhmm(bloc.end)}
                                    </p>
                                    <div key={index}>
                                        {bloc.place?.address?.formatted ? (
                                            <div className="flex flex-row items-center justify-between gap-2">
                                                <div className="flex flex-col items-center justify-center px-4 bg-white rounded-full border-v2_purple border-[1px] py-4 min-w-[75px]">
                                                    <Icon.LocationPin className="size-4 text-v2_purple" />
                                                    <Text.ExtraSmall>Chez Pro</Text.ExtraSmall>
                                                </div>
                                                <p className="text-[10px] text-center">
                                                    {bloc.place?.address?.formatted}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col items-center justify-center px-4 bg-background_light rounded-full py-4 min-w-[75px]">
                                                <LuHome className="size-4 text-v2_purple" />
                                                <Text.ExtraSmall>Chez vous</Text.ExtraSmall>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }
        }
    }

    return (
        <>
            {!dayModal ? (
                <div className="flex flex-col m-2 pb-4 space-y-4 pt-12">
                    <Text.Normal className="font-bold text-v2_purple">Horaires de travail</Text.Normal>
                    <div className="grid gap-2">
                        {!loading ? (
                            defaultDays ? (
                                defaultDays.map((day) => (
                                    <div
                                        className="flex justify-between text-[16px] font-regular leading-[30px] tracking-[-0.32px] font-[Montserrat] items-center m-[2px]"
                                        key={day._id}
                                        onClick={() => {
                                            setDaySelected(day)
                                            setDayModal(!dayModal)
                                        }}
                                    >
                                        <div className="flex flex-row items-center gap-2">
                                            {day.isWorkingDay ? (
                                                <p className="capitalize font-medium text-[16px]">{day.name}</p>
                                            ) : (
                                                <p className="capitalize text-v2_grey font-medium text-[16px]">
                                                    {day.name}
                                                </p>
                                            )}
                                            {day.isWorkingDay && (
                                                <div className="flex flex-row items-center gap-2">
                                                    {day.blocs.map((i, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {i.place?.address?.formatted ? (
                                                                    <div className="flex flex-col items-center justify-center px-2 pb-2 bg-white rounded-full border-v2_purple border-[1px] pt-2">
                                                                        <Icon.LocationPin className="size-4 text-v2_purple" />
                                                                        {/* <Text.ExtraSmall>
                                                                            {i.place?.address?.formatted?.slice(0, 7)}
                                                                            {i.place?.address?.formatted?.length > 7
                                                                                ? '...'
                                                                                : ''}
                                                                        </Text.ExtraSmall> */}
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex flex-col items-center justify-center px-2 pb-2 bg-background_light rounded-full pt-2">
                                                                        <LuHome
                                                                            className="size-4 text-v2_purple"
                                                                            strokeWidth={3}
                                                                        />
                                                                        {/* <Text.ExtraSmall>Chez vous</Text.ExtraSmall> */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                        <RenderTiming day={day} />
                                    </div>
                                ))
                            ) : (
                                <div>Aucun horaires défini</div>
                            )
                        ) : (
                            Array.from({ length: 7 }, (_, index) => index + 1).map((day) => (
                                <div className="w-[270px] animate-pulse h-[32px] bg-v2_pink rounded-sm" key={day}></div>
                            ))
                        )}
                    </div>
                </div>
            ) : (
                <div className="w-screen h-screen fixed bottom-0 left-0 z-99 bg-white px-4">
                    <div
                        className="flex flex-row items-center space-x-1"
                        onClick={() => {
                            setDayModal(!dayModal)
                            setDaySelected()
                        }}
                    >
                        <ArrowLeft size={20} color="#72517e" />
                        <p className="text-[16px] underline font-medium text-v2_purple">Retour</p>
                    </div>
                    <div className="my-10 flex flex-col gap-4">
                        <p className="text-[18px] font-semibold text-v2_purple uppercase">{daySelected?.name}</p>
                        <RenderHalfTiming day={daySelected} />
                    </div>
                </div>
            )}
        </>
    )
}
