import React, { useEffect } from 'react'

import { Text } from '../../../components/atoms'
import { Client } from '../../../components/organisms'
import { Auth, ClientContext } from '../../../../contexts'

const Favorites = () => {
    const { id, token, isLogged } = Auth.useAuth()
    const { favorite } = ClientContext.Favorite.useFavorite()
    const { getProfil } = ClientContext.Profil.useProfil()

    useEffect(() => {
        if (isLogged()) {
            getProfil()
        }
    }, [id, token])

    if (!favorite) {
        return (
            <Text.Small className={'mt-10'} align={'center'}>
                Chargement des favoris...
            </Text.Small>
        )
    }

    return <Client.Favorites.default favoris={favorite} />
}

export default Favorites
