import React, { createContext, useContext, useState, useEffect } from 'react'

import * as AgendaDB from './AgendaDB'
import { Auth, Request } from '../..'
import dayjs from 'dayjs'

/* eslint no-unused-vars: 0 */
const ListContext = createContext({
    database: Object || null,
    list: Object || null,
    tempList: Object || null,
    getList: (key) => {
        key = 'YYYY-MM-DD'
    },
})

export function ListContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token, id } = Auth.useAuth()

    const [agendaDatabase, setAgendaDatabase] = useState(null)

    useEffect(() => {
        AgendaDB.openIndexedDB().then((database) => setAgendaDatabase(database))
    }, [])

    const [list, setList] = useState([])
    const [tempList, setTempList] = useState([])
    const [loading, setLoading] = useState(false)

    const getMonthFromCache = async (key) => {
        if (!('indexedDB' in window)) return null
        return await AgendaDB.getOneMonth(agendaDatabase, key)
    }

    const getMonth = async (key) => {
        let data = await getMonthFromCache(key)

        if (!data) {
            const response = await handleRequest('get', `coiffeur/days/${id}/${key}-01/month`, null, token)

            data = response.data.days.reduce((days, value) => {
                const { date, ...data } = value
                days[date] = data
                return days
            }, {})

            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: data,
                refreshAt: dayjs().toISOString(),
            })

            data = { monthKey: key, days: data }
        }

        return data
    }

    const getListForMonth = async (date, previousList) => {
        const newDays = previousList

        const firstDay = dayjs(date)

        let daysInMonth = firstDay.daysInMonth()
        let currentMonth = await getMonth(firstDay.format('YYYY-MM'))

        for (let i = 0; i < daysInMonth; i++) {
            const date = firstDay.add(i, 'day').format('YYYY-MM-DD')
            const bookings = currentMonth.days[date]?.bookings

            if (bookings && bookings.length > 0) {
                newDays.push({ date: date, bookings: bookings })
            }
        }
    
        setTempList(newDays)

        if (Math.abs(firstDay.diff(dayjs(), 'day')) < 355) {
            await getListForMonth(firstDay.set('date', 1).add(1, 'month'), newDays)
        } else {
            setList(newDays)
            return
        }
    }

    const getList = async () => {
        if (!loading) {
            setLoading(true)
            await getListForMonth(dayjs().format('YYYY-MM-DD'), [])
            setLoading(false)
        }
    }

    return (
        <ListContext.Provider
            value={{
                database: agendaDatabase,
                list: list,
                tempList: tempList,
                getList: getList,
            }}
        >
            {children}
        </ListContext.Provider>
    )
}

export const useList = () => useContext(ListContext)
