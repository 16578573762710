import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { X } from 'lucide-react'
import Lottie from 'react-lottie'

import { Button, Text, Input, Message } from '../../components/atoms'
import { Return } from '../../components/molecules'

import { Auth } from '../../../contexts'

import emailSend from '../../../assets/lottie/email-send.json'

const Login = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { handleLoginClassic, getNewPassword, setRedirectUrl, loginLoading } = Auth.useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        const source = location.state?.source ? `${location.state.source}` : '/client'
        setRedirectUrl(source)
    }, [])

    const handleLogin = async () => {
        await handleLoginClassic(email, password)
    }

    const handlePasswordReset = async () => {
        if (email) {
            const result = await getNewPassword(email)
            if (result.data?.success) {
                setError(false)
                setSuccess(true)
            } else {
                setError(true)
                setMessage('Les données sont invalides')
                setTimeout(() => setMessage(''), 5000)
            }
        }
    }

    return (
        <div className="relative">
            {loginLoading && (
                <div className="absolute top-0 left-0 w-full h-full bg-white flex flex-row items-center justify-center z-99">
                    <p className="text-[16px] font-medium text-center underline text-v2_purple">
                        Chargement du profil ...
                    </p>
                </div>
            )}
            {!forgotPassword ? (
                <div className="grid gap-2 px-[35px] py-[36px] shadow-basic rounded-[50px]">
                    <Text.H1 className="font-bold" align="center" color="#000">
                        Connexion
                    </Text.H1>
                    <div className="grid gap-[23px]">
                        <div className="grid gap-[4px]">
                            <Text.H5 align="center" color="#000">
                                Vous n'avez pas encore de compte ?
                            </Text.H5>
                            <div onClick={() => navigate('/signup')}>
                                <Text.H5
                                    align="center"
                                    color="v2_purple"
                                    className="font-semibold underline tracking-p"
                                >
                                    Inscription
                                </Text.H5>
                            </div>
                        </div>
                        <div className="grid gap-8">
                            <div className="grid gap-[10px]">
                                <Input.Email
                                    value={email}
                                    placeholder="Adresse email"
                                    label="Votre adresse mail"
                                    onChange={(value) => setEmail(value)}
                                />
                                <Input.Password
                                    value={password}
                                    placeholder="Mot de passe"
                                    label="Mot de passe"
                                    onChange={(pwd) => setPassword(pwd)}
                                />
                                <div
                                    onClick={() => setForgotPassword(true)}
                                    className="flex flex-col items-end w-full px-6"
                                >
                                    <Text.Small color="#000" className="underline">
                                        Mot de passe oublié ?
                                    </Text.Small>
                                </div>
                            </div>
                            <Button.Primary title="Se connecter" onClick={handleLogin} />
                        </div>
                    </div>
                </div>
            ) : !success ? (
                <div className="grid gap-[40px]">
                    <Return.Primary onClick={() => setForgotPassword(false)} />
                    {message && <Message.Error message={message} relative />}
                    <div className="grid gap-[28px] shadow-basic rounded-[50px] px-[35px] py-[36px]">
                        <Text.H1 className="font-bold" align="center" color="#000">
                            Mot de passe oublié ?
                        </Text.H1>
                        <div className="grid gap-8">
                            <Input.Text
                                label="Votre adresse email"
                                placeholder="Adresse email"
                                value={email}
                                onChange={(value) => setEmail(value)}
                                error={error}
                            />
                        </div>
                        <Button.Primary title="Continuer" onClick={handlePasswordReset} />
                    </div>
                </div>
            ) : (
                <div className="grid gap-[88px]">
                    <div
                        className="ml-auto w-fit"
                        onClick={() => {
                            setSuccess(false)
                            setForgotPassword(false)
                            setPassword('')
                        }}
                    >
                        <X color="#72517E" size={40} />
                    </div>
                    <div className="grid gap-[34px] px-[19px]">
                        <p className="font-semibold text-[20px] leading-[30px] tracking-[-0.8px] text-center">
                            Votre demande de réinitialisation de mot de passe a été envoyée
                        </p>
                        <div className="grid gap-[24px]">
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: emailSend,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice',
                                    },
                                }}
                                height={214}
                                width={214}
                            />
                            <p className="font-medium text-[16px] leading-[32px] tracking-[-0.32px] text-center">
                                Pour vous connecter, veuillez ouvrir l'email que vous avez reçu
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Login
