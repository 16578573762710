import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Notifications as Atoms } from '../../components/atoms'
import { Profesionnal } from '../../components/organisms'
import { NotificationContext, ProContext } from '../../../contexts'

import avatar from '../../../assets/avatar/Boy.svg'

const Notifications = () => {
    const { notifications, readNotification } = NotificationContext.useNotifications()
    const { bookingSelected, selectBooking } = ProContext.Bookings.useBooking()

    const [todayNotifications, setTodayNotifications] = useState([])
    const [previousNotifications, setPreviousNotifications] = useState([])

    const isToday = (date) => {
        return dayjs(date).isSame(dayjs(), 'day')
    }

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            const today = []
            const previous = []

            notifications.forEach((notif) => {
                if (notif.type !== 'cancel') {
                    if (isToday(notif.date)) {
                        today.push(notif)
                    } else {
                        previous.push(notif)
                    }
                }
            })

            setTodayNotifications(today)
            setPreviousNotifications(previous)
        }
    }, [notifications])

    const getNotificationTitle = (type) => {
        switch (type) {
            case 'cancelBooking':
                return 'Rendez-vous Annulé'
            case 'newBooking':
                return 'Rendez-vous en Attente'
            case 'booked':
                return 'Rendez-vous Confirmé'
            default:
                return type
        }
    }

    useEffect(() => {
        return () => {
            selectBooking()
        }
    }, [])

    console.log(notifications)

    return (
        <>
            {!bookingSelected ? (
                <div className="grid w-full">
                    {notifications && notifications.length >= 0 ? (
                        <>
                            {todayNotifications.length > 0 && (
                                <>
                                    <Atoms.Title title="Aujourd'hui" />
                                    <Atoms.Container>
                                        {todayNotifications.map((notif) => (
                                            <div key={notif._id}>
                                                {notif.read ? (
                                                    <Atoms.Card.Read
                                                        title={getNotificationTitle(notif.type)}
                                                        name={`${notif.data._profil.firstName} ${notif.data._profil.lastName} `}
                                                        avatar={
                                                            notif.data._profil.avatar.url
                                                                ? notif.data._profil.avatar.url
                                                                : avatar
                                                        }
                                                        onClick={() => {
                                                            readNotification({ id: notif._id, read: true })
                                                            selectBooking(notif.data)
                                                        }}
                                                    />
                                                ) : (
                                                    <Atoms.Card.Unread
                                                        title={getNotificationTitle(notif.type)}
                                                        name={`${notif.data._profil.firstName} ${notif.data._profil.lastName} `}
                                                        avatar={
                                                            notif.data._profil.avatar
                                                                ? notif.data._profil.avatar.url
                                                                : avatar
                                                        }
                                                        onClick={() => {
                                                            readNotification({ id: notif._id, read: true })
                                                            selectBooking(notif.data)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </Atoms.Container>
                                </>
                            )}

                            {previousNotifications.length > 0 && (
                                <>
                                    <Atoms.Title title="Précédent" />
                                    <Atoms.Container>
                                        {previousNotifications.map((notif) => (
                                            <div key={notif._id}>
                                                {notif.read ? (
                                                    <Atoms.Card.Read
                                                        title={getNotificationTitle(notif.type)}
                                                        name={`${notif.data._profil?.firstName} ${notif.data._profil?.lastName} `}
                                                        avatar={
                                                            notif.data._profil?.avatar
                                                                ? notif.data._profil?.avatar.url
                                                                : avatar
                                                        }
                                                        onClick={() => {
                                                            readNotification({ id: notif._id, read: true })
                                                            selectBooking(notif.data)
                                                        }}
                                                    />
                                                ) : (
                                                    <Atoms.Card.Unread
                                                        title={getNotificationTitle(notif.type)}
                                                        name={`${notif.data._profil?.firstName} ${notif.data._profil?.lastName} `}
                                                        avatar={
                                                            notif.data._profil?.avatar
                                                                ? notif.data._profil?.avatar.url
                                                                : avatar
                                                        }
                                                        onClick={() => {
                                                            readNotification({ id: notif._id, read: true })
                                                            selectBooking(notif.data)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </Atoms.Container>
                                </>
                            )}
                        </>
                    ) : (
                        <p className="text-[16px] text-v2_grey text-center mt-16">Pas de notifications</p>
                    )}
                </div>
            ) : (
                <div className={`${bookingSelected ? 'block' : 'hidden'}`}>
                    {bookingSelected && <Profesionnal.Booking.Screen />}
                </div>
            )}
        </>
    )
}

export default Notifications
