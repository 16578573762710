import bcrypt from 'bcryptjs-react'
import { setLocalStorageValue, getLocalStorageValue } from '../storage'
import { jwtDecode } from 'jwt-decode'
import dayjs from 'dayjs'

export const isGoodPassword = async (password, passwordHash) => {
    const comparePassword = await bcrypt.compare(password, passwordHash)

    return comparePassword
}

export const hashPassword = async (password) => {
    return await bcrypt.hash(password, 10)
}

export const persistStorage = (id, email, type, token, needNewPassword, password) => {
    const jsonify = {
        id: id,
        email: email,
        type: type,
        token: token,
        needNewPassword: needNewPassword,
        password: password,
    }

    setLocalStorageValue('auth', JSON.stringify(jsonify))
}

export const isTokenExpired = () => {
    try {
        const stored = getLocalStorageValue('auth')

        const jsonify = JSON.parse(stored)

        const token = jsonify?.token
        const { iat } = jwtDecode(token)
        if (iat) {
            const issueDate = dayjs(iat * 1000)
            const minimumDateOfGeneration = dayjs('2024-12-05')

            return issueDate.isBefore(minimumDateOfGeneration)
        }
    } catch (err) {
        console.log(err)
        return true
    }

    return false
}

export const getAuthStored = () => {
    const stored = getLocalStorageValue('auth')

    const jsonify = JSON.parse(stored)

    return jsonify
        ? {
              id: jsonify.id,
              profil: jsonify.profil,
              email: jsonify.email,
              type: jsonify.type,
              firstName: jsonify.firstName,
              lastName: jsonify.lastName,
              customer: jsonify.customer,
              token: jsonify.token,
              needNewPassword: jsonify.needNewPassword,
              password: jsonify.password,
          }
        : null
}
