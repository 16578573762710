import React, { useState } from 'react'
import dayjs from 'dayjs'
import { FR } from 'country-flag-icons/react/3x2'

import { Button, Cards, Input, Text, Icon, Loader } from '../../atoms'
import { Modify, Return, Search } from '../../molecules'
import { Address, Auth, ClientContext } from '../../../../contexts'

import emailSend from '../../../../assets/lottie/email-send.json'

import { useNavigate } from 'react-router-dom'
import { HiOutlineMail, HiOutlinePhone, HiOutlineLocationMarker } from 'react-icons/hi'
import Lottie from 'react-lottie'

export const RDV = () => {
    const navigate = useNavigate()

    const { loading, setCurrentBooking, bookingsClient, setStep } = ClientContext.Reservation.useReservation()

    if (loading) {
        return (
            <div className="text-center">
                <Loader.Primary />
                <Text.Normal className="text-[18px] text-center font-medium" color={'v2_purple'}>
                    Récupération de vos rendez-vous
                </Text.Normal>
            </div>
        )
    } else {
        return (
            <div>
                {bookingsClient.length > 0 ? (
                    <>
                        <div className="py-4">
                            {bookingsClient
                                .filter((booking) => {
                                    if (
                                        booking.state === 'booked' ||
                                        booking.state === 'waiting' ||
                                        booking.state === 'cancel'
                                    ) {
                                        if (
                                            dayjs(booking.date)
                                                .add(parseInt(booking.from / 60), 'hour')
                                                .add(parseInt(booking.to % 60), 'minutes')
                                                .isAfter(dayjs())
                                        ) {
                                            return true
                                        }
                                    }
                                })
                                .map((booking, index) => (
                                    <Cards.ClientRDV
                                        key={index}
                                        price={booking.price}
                                        from={booking.from}
                                        prestation={booking._prestation}
                                        state={booking.state}
                                        date={booking.date}
                                        canceledBy={booking.canceledBy}
                                        onClick={() => {
                                            setStep('main')
                                            setCurrentBooking(booking)
                                        }}
                                        onComment={() => {
                                            setStep('comment')
                                            setCurrentBooking(booking)
                                        }}
                                        priceVariable={booking.priceVariable}
                                    />
                                ))}
                        </div>
                        <div>
                            {bookingsClient.filter((booking) => booking.state === 'done').length > 0 && (
                                <div className="py-2">
                                    <div className="flex items-center justify-center w-full pb-6">
                                        <Icon.Book className="text-black w-9 h-9" />
                                        <Text.Small color={'black'} className="font-bold">
                                            Historique des réservations
                                        </Text.Small>
                                    </div>
                                    {bookingsClient
                                        .filter((booking) => booking.state === 'done')
                                        .reverse()
                                        .map((booking, index) => (
                                            <Cards.ClientRDVDone
                                                key={index}
                                                price={booking.price}
                                                from={booking.from}
                                                prestation={booking._prestation}
                                                date={booking.date}
                                                onClick={() => {
                                                    setStep('main')
                                                    setCurrentBooking(booking)
                                                }}
                                                onComment={() => navigate(`comment/${booking._id}`)}
                                                priceVariable={booking.priceVariable}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <Text.Normal className={'mt-10'} align={'center'}>
                        Vous n'avez aucun rendez-vous
                    </Text.Normal>
                )}
            </div>
        )
    }
}

export const Coordonnees = () => {
    const { email, handleDelete, handleLogout, handleChangePassword, getNewPassword } = Auth.useAuth()
    const { firstName, lastName, phoneNumber, address, handleSetAttribute, handleSaveAttribute } =
        ClientContext.Profil.useProfil()
    const { setHasToResearch } = Address.useAddress()

    const [step, setStep] = useState('main')

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [comfirmPassword, setComfirmPassword] = useState('')

    const updateProfile = async () => {
        if (step === 'password') {
            await handleChangePassword(oldPassword, newPassword, comfirmPassword)
            setOldPassword('')
            setNewPassword('')
            setComfirmPassword('')
            setStep('main')
        }
    }

    const deleteProfile = async () => {
        await handleDelete()
    }

    const [logOutLoading, setLogOutLoading] = useState(false)

    const logOut = async () => {
        setLogOutLoading(true)
        await handleLogout()
        setLogOutLoading(false)
    }

    const handlePasswordReset = async () => {
        if (email) {
            const result = await getNewPassword(email)
            if (result.data?.success) {
                setStep('forgotPassword')
                setTimeout(() => {
                    setStep('main')
                }, 4000)
            }
        }
    }

    return (
        <>
            {logOutLoading && (
                <div className="absolute z-99 w-full h-full bg-white top-0 left-0 flex flex-col justify-center items-center">
                    <p className="text-[16px] underline text-v2_purple font-medium">Déconnexion ...</p>
                </div>
            )}
            <div className="flex flex-col w-full px-6">
                {step === 'main' && (
                    <>
                        <Modify.Primary onClick={() => setStep('edit')} />
                        <div className="flex flex-col py-6 space-y-4">
                            <div className="flex items-center">
                                <HiOutlineMail className="mr-2 text-v2_purple" size={30} />
                                <Text.Normal>{email}</Text.Normal>
                            </div>
                            <div className="flex items-center">
                                <HiOutlinePhone className="mr-2 text-v2_purple" size={30} />
                                <div className="flex items-center mx-2">
                                    <FR className="w-6 mr-2" />
                                    (+{phoneNumber.code})
                                </div>
                                <Text.Normal>{phoneNumber.phone}</Text.Normal>
                            </div>
                            <div className="flex items-center">
                                <HiOutlineLocationMarker className="mr-2 text-v2_purple" size={30} />
                                <Text.Normal>{address.formatted}</Text.Normal>
                            </div>
                            <div className="fixed left-0 self-center w-full px-5 bottom-24 space-y-4">
                                <Button.Secondary title={'Déconnexion'} onClick={() => logOut()} />
                                <Button.Primary title={'Changer de mot de passe'} onClick={() => setStep('password')} />
                                <div onClick={() => deleteProfile()}>
                                    <Text.Normal className="text-center underline">Supprimer mon compte</Text.Normal>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {step === 'edit' && (
                    <>
                        <Return.Primary customText={'Enregistrer'} onClick={() => setStep('main')} />
                        <div className="flex flex-col py-6 space-y-4">
                            <div className="flex justify-between">
                                <Input.Text
                                    name="firstName"
                                    value={firstName}
                                    placeholder="Prénom"
                                    onChange={(newValue) => handleSetAttribute('firstName', newValue)}
                                    onBlur={(value) => {
                                        handleSaveAttribute('firstName', value)
                                    }}
                                />
                                <Input.Text
                                    name="lastName"
                                    value={lastName}
                                    placeholder="Nom"
                                    onChange={(newValue) => handleSetAttribute('lastName', newValue)}
                                    onBlur={(value) => {
                                        handleSaveAttribute('lastName', value)
                                    }}
                                />
                            </div>
                            <Input.Email name="email" value={email} placeholder="Email" onChange={() => {}} disabled />
                            <Input.PhoneNumber
                                name="phoneNumber"
                                value={phoneNumber.phone}
                                placeholder="Numéro de téléphone"
                                onChange={(newValue) =>
                                    handleSetAttribute('phoneNumber', { ...phoneNumber, phone: newValue })
                                }
                                handleSave={(newValue) => {
                                    handleSaveAttribute('phoneNumber', {
                                        ...phoneNumber,
                                        phone: newValue,
                                    })
                                }}
                            />
                            <Input.Text
                                name="address"
                                value={address.formatted}
                                placeholder="Adresse"
                                onFocus={() => setStep('address')}
                            />
                        </div>
                    </>
                )}
                {step === 'address' && (
                    <Search.Address
                        launchResearch={async (formatted, lat, lon) => {
                            await handleSaveAttribute('address', {
                                formatted: formatted,
                                lat: lat,
                                lon: lon,
                            })
                            setStep('loading')
                            await setHasToResearch(true)
                            setStep('edit')
                        }}
                    />
                )}
                {step === 'password' && (
                    <>
                        <Return.Primary onClick={() => setStep('main')} />
                        <div className="flex flex-col py-6 space-y-4">
                            <Input.Password
                                placeholder="Actuel mot de passe"
                                name="oldPassword"
                                value={oldPassword}
                                onChange={(value) => setOldPassword(value)}
                            />
                            <Input.Password
                                placeholder="Nouveau mot de passe"
                                name="newPassword"
                                value={newPassword}
                                onChange={(value) => setNewPassword(value)}
                            />
                            <Input.Password
                                placeholder="Comfirmer nouveau mot de passe"
                                name="comfirmPassword"
                                value={comfirmPassword}
                                onChange={(value) => setComfirmPassword(value)}
                            />
                            <div onClick={() => handlePasswordReset()}>
                                <Text.Normal className="text-center underline">Mot de passe oublié</Text.Normal>
                            </div>
                            <Button.Primary title={'Enregistrer'} onClick={() => updateProfile()} />
                        </div>
                    </>
                )}
                {step === 'forgotPassword' && (
                    <div className="grid gap-[88px]">
                        <div className="grid gap-[34px] px-[19px]">
                            <p className="font-semibold text-[20px] leading-[30px] tracking-[-0.8px] text-center">
                                Votre demande de réinitialisation de mot de passe a été envoyée
                            </p>
                            <div className="grid gap-[24px]">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: emailSend,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice',
                                        },
                                    }}
                                    height={214}
                                    width={214}
                                />
                                <p className="font-medium text-[16px] leading-[32px] tracking-[-0.32px] text-center">
                                    Pour vous connecter, veuillez ouvrir l'email que vous avez reçu
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
