import React, { useState } from 'react'

import { Icon, Modal, Text, Button, Loader } from '../../atoms'
import { Request, Auth, Images } from '../../../../contexts'

import imageCompression from 'browser-image-compression'

const Upload = ({ isOpen, setIsOpen, title, afterUpload, loading, setLoading }) => {
    const { handleRequest, setMessage } = Request.useRequest()
    const { token } = Auth.useAuth()
    const { images } = Images.useImages()

    const [step, setStep] = useState('general')
    const [selectedPhoto, setSelectedPhoto] = useState()

    const compressFile = async (file) => {
        try {
            const options = {
                maxSizeMB: 1, // (la taille maximale en MB, par exemple 1MB)
                maxWidthOrHeight: 1920, // (la largeur ou la hauteur maximale en pixels)
                useWebWorker: true, // (active le multithreading si possible)
            }

            const compressedFile = await imageCompression(file, options)
            return compressedFile
            // Vous pouvez maintenant envoyer `compressedFile` à votre serveur
        } catch (error) {
            console.error(error)
        }
    }

    const handleUpload = async (e) => {
        if (isOpen) {
            setLoading(true)

            const createFormData = (file) => {
                const data = new FormData()

                data.append('file', file, file.name)

                return data
            }

            const compressed = await compressFile(e.target.files[0])
            if (compressed) {
                const data = createFormData(compressed)

                const response = await handleRequest('post', `image`, data, token, 'multipart/form-data')

                if (response) {
                    afterUpload(response?.data?.image)
                }
            } else {
                setMessage({ type: 'info', message: 'Fichier corrompu' })
            }
        }
    }

    return (
        <Modal.ChoosePicture
            title={step === 'general' ? title : 'Médiathèque'}
            isOpen={isOpen}
            setIsOpen={() => setIsOpen()}
            backButton={
                <Button.Secondary
                    onClick={() => {
                        if (step === 'general') setIsOpen(false)
                        else setStep('general')
                    }}
                    title={'Retour'}
                />
            }
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-6" />}
                    disabled={step === 'general'}
                    onClick={() => afterUpload(selectedPhoto)}
                    title={'Ajouter'}
                />
            }
        >
            {step === 'general' ? (
                <>
                    <div className="flex justify-between w-full space-x-5">
                        {!loading ? (
                            <>
                                <label htmlFor="file-upload">
                                    <div className="flex items-center space-x-3 ">
                                        <div className="p-4 rounded-full bg-light-gray">
                                            <Icon.Upload className="w-5" />
                                        </div>
                                        <Text.Paragraph.Normal>Parcourir le téléphone</Text.Paragraph.Normal>
                                    </div>
                                </label>
                                <Icon.ArrowNext className="w-9" />
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleUpload}
                                />
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center mx-auto">
                                <Loader.Primary />
                                <Text.H5 color="black">Image en cours d'importation</Text.H5>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="grid justify-center grid-cols-3 gap-2">
                    {images?.map((image) => {
                        return (
                            <div
                                key={image._id}
                                className={`rounded-lg w-[28vw] h-[28vw] mx-auto bg-light-gray ${
                                    selectedPhoto?._id === image._id
                                        ? 'border-favorite border-[4px] border-outside'
                                        : 'border border-opacity-10 border-black'
                                }`}
                                style={{
                                    backgroundImage: `url(${image.thumbnailUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundSize: selectedPhoto?._id === image._id ? '110%' : 'cover',
                                }}
                                onClick={() => setSelectedPhoto(image)}
                            ></div>
                        )
                    })}
                </div>
            )}
        </Modal.ChoosePicture>
    )
}

export default Upload
