import React, { useEffect, useState } from 'react'

import { Header, Loader, Text } from '../../components/atoms'
import { Return, Pro as ProMolecule } from '../../components/molecules'
import { Profesionnal } from '../../components/organisms'
import { Pro } from '../../components/templates'

import { ProContext } from '../../../contexts'

function ClientDirectory() {
    const {
        clientSelected,
        settingClientSelected,
        isImportingContact,
        setIsImportingContact,
        importPending,
        isLoading,
    } = ProContext.Directory.useDirectory()

    const { selectBooking, bookingSelected } = ProContext.Bookings.useBooking()

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) container.scrollTo(0, 0)
    }, [clientSelected])

    const handleBackClick = () => {
        if (bookingSelected) {
            selectBooking()
        } else {
            settingClientSelected()
        }
    }

    useEffect(() => {
        return () => {
            settingClientSelected()
        }
    }, [])

    const [filterStep, setFilterStep] = useState(false)

    return (
        <div className="relative">
            {/* Afficher la progressbar si l'importation est en cours */}
            {importPending && isImportingContact && <Profesionnal.ImportContacts.ImportContactPending />}

            {/* Condition 1: Importing Contacts */}
            {isImportingContact && !importPending && (
                <>
                    <Return.Primary onClick={() => setIsImportingContact(false)} />
                    <Profesionnal.ImportContacts.ImportContactList />
                </>
            )}

            {/* Condition 2: No client selected */}
            {!clientSelected && !isImportingContact && !importPending && (
                <>
                    <Header.ClientDirectory showFilterStep={() => setFilterStep(!filterStep)} />
                    <Profesionnal.Directory.ClientDirectory
                        filterStep={filterStep}
                        showFilterStep={() => setFilterStep(!filterStep)}
                    />
                </>
            )}

            {/* Condition 3: Client selected but no id */}
            {clientSelected && !clientSelected._id && !isImportingContact && !importPending && (
                <>
                    <Return.Primary onClick={settingClientSelected} />
                    <ProMolecule.Directory.ClientForm />
                </>
            )}

            {/* Condition 4: Client selected with id */}
            {clientSelected?._id && !isImportingContact && !importPending && (
                <>
                    <Return.Primary onClick={handleBackClick} />
                    {!isLoading ? (
                        <>
                            <Pro.ClientProfile />
                            <Profesionnal.Directory.Notes />
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center w-full h-screen px-4">
                            <Loader.Primary />
                            <Text.H4 className="text-center" color="v2_purple">
                                Client en cours de suppression
                            </Text.H4>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ClientDirectory
